import { instanceOf, func, string } from 'prop-types';
import React from 'react';
import {
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  Tooltip,
  Avatar,
  Icon,
  ListItemText,
  Box,
  Divider,
} from '@mui/material';
import moment from 'moment';

import CancelReservationDialog from './CancelReservationDialog';

export default function ReservationsList({
  reservations,
  title,
  handleUpdate,
  emptyMessage,
  variant,
}) {
  return (
    <List subheader={title ? <ListSubheader>{title}</ListSubheader> : null} dense>
      {reservations.map((reservation, idx) => (
        <>
          {idx !== 0 && <Divider />}

          <ListItem
            key={reservation.id}
            secondaryAction={
              <CancelReservationDialog
                reservationId={reservation.id}
                handleUpdate={handleUpdate}
                disabled={reservation.status !== 'upcoming'}
              />
            }
          >
            <ListItemAvatar>
              {reservation.status === 'upcoming' &&
                (moment(reservation.session.startsAt)
                  .add(reservation.session.duration, 'minutes')
                  .isAfter(moment()) ? (
                  // eslint-disable-next-line react/jsx-indent
                  <Tooltip title="Reservación activa">
                    <Avatar sx={(theme) => ({ bgcolor: theme.palette.primary.main })}>
                      <Icon>schedule</Icon>
                    </Avatar>
                  </Tooltip>
                ) : (
                  <Tooltip title="No asistió">
                    <Avatar sx={(theme) => ({ bgcolor: theme.palette.warning.main })}>
                      <Icon>event_busy</Icon>
                    </Avatar>
                  </Tooltip>
                ))}

              {['successful', 'successful-last-minute'].includes(reservation.status) && (
                <Tooltip title="Asistió">
                  <Avatar sx={(theme) => ({ bgcolor: theme.palette.success.main })}>
                    <Icon>event_available</Icon>
                  </Avatar>
                </Tooltip>
              )}

              {['canceled', 'canceled-last-minute', 'canceled-by-business'].includes(
                reservation.status
              ) && (
                <Tooltip title="Cancelada">
                  <Avatar sx={(theme) => ({ bgcolor: theme.palette.error.main })}>
                    <Icon>event_busy</Icon>
                  </Avatar>
                </Tooltip>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box>
                    {variant === 'customer'
                      ? reservation.user.name
                      : `${reservation.session.discipline.name} el ${moment(
                          reservation.session.startsAt
                        )
                          .tz('America/Monterrey')
                          .format('YYYY-MM-DD')} a las ${moment(reservation.session.startsAt)
                          .tz('America/Monterrey')
                          .format('hh:mm a')} en ${reservation.session.room.studio.name}`}
                  </Box>
                  {reservation.seat ? (
                    <Box sx={{ fontWeight: 'bold', ml: 1 }}>#{reservation.seat}</Box>
                  ) : null}
                </Box>
              }
              secondary={`${
                reservation.status.includes('canceled')
                  ? {
                      canceled: 'Cancelado a tiempo.',
                      'canceled-last-minute': 'Cancelado de último momento.',
                      'canceled-by-business': 'Cancelado por el negocio.',
                    }[reservation.status]
                  : ''
              } Reservado el ${moment(reservation.createdAt).format('YYYY-MM-DD')} a las ${moment(
                reservation.createdAt
              ).format('hh:mm a')}.`}
            />
          </ListItem>
        </>
      ))}

      {reservations.length === 0 && (
        <ListItem>
          <ListItemText secondary={emptyMessage} />
        </ListItem>
      )}
    </List>
  );
}

ReservationsList.propTypes = {
  reservations: instanceOf(Array).isRequired,
  title: string,
  handleUpdate: func.isRequired,
  emptyMessage: string.isRequired,
  variant: string,
};

ReservationsList.defaultProps = {
  title: null,
  variant: 'customer',
};
