import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  Button,
  IconButton,
  Paper,
  useMediaQuery,
  Card,
  CardActions,
  Popover,
  Tooltip,
} from '@mui/material';
import { StaticDatePicker, PickersDay } from '@mui/lab';
import { instanceOf } from 'prop-types';
import moment from 'moment-timezone';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import Layout from '../Common/Layout';
import SessionDialog from './SessionDialog';
import '../Common/Calendar.css';
import SessionsCalendar from './SessionsCalendar';
import Confirm from '../Common/Confirm';

export default function Sessions({ authenticatedUser }) {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [view, setView] = useState('week');
  const [date, setDate] = useState(moment());
  const [dateAnchorEl, setDateAnchorEl] = useState(null);
  const [selectedStudio, setSelectedStudio] = useState(null);
  const [openSession, setOpenSession] = useState(null);

  useEffect(() => {
    setView(isDesktop ? 'week' : 'day');
  }, [isDesktop]);

  const { loading, data, refetch } = useQuery(
    gql`
      query GetSessions($businessId: ID!, $since: String!, $until: String!) {
        studiosByBusiness(businessId: $businessId) {
          id
          name
          rooms {
            id
            name
            defaultSeats
            studio {
              id
              name
            }
          }
          opensAt
          closesAt
        }
        adminsAndInstructors {
          id
          name
          isInstructor
        }
        disciplinesByBusiness(businessId: $businessId) {
          id
          name
        }
        sessionsByBusiness(businessId: $businessId, since: $since, until: $until) {
          id
          room {
            id
            name
            studio {
              id
              name
            }
          }
          instructor {
            id
            name
          }
          discipline {
            id
            name
          }
          label
          seats
          startsAt
          duration
          status
          startedAt
          endedAt
          reservationsCount
          attendanceCount
        }
      }
    `,
    {
      variables: {
        businessId: authenticatedUser.business.id,
        since: date.startOf(view).toDate().toISOString(),
        until: date.endOf(view).toDate().toISOString(),
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const [createSessionsFromTemplates, { loading: creatingSessionsFromTemplates }] = useMutation(
    gql`
      mutation CreateSessionsFromTemplates($studioId: ID!, $since: String!, $until: String!) {
        createSessionsFromTemplates(studioId: $studioId, since: $since, until: $until)
      }
    `,
    {
      update: () => {
        refetch();
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [date, view, refetch]);

  useEffect(() => {
    if (!loading && !selectedStudio && data) {
      setSelectedStudio(data.studiosByBusiness[0]);
    }
  }, [selectedStudio, data, loading]);

  const sessionDefaults = useMemo(() => {
    if (!data || !data.disciplinesByBusiness || !selectedStudio) {
      return {};
    }

    const room =
      selectedStudio && selectedStudio.rooms.length === 1 ? selectedStudio.rooms[0] : null;

    const seats = room ? room.defaultSeats : null;

    const discipline =
      data.disciplinesByBusiness.length === 1 ? data.disciplinesByBusiness[0] : null;

    return {
      room,
      discipline,
      seats,
    };
  }, [data, selectedStudio]);

  const sessions = (
    data && data.sessionsByBusiness && selectedStudio ? data.sessionsByBusiness : []
  ).filter(
    (session) => session.room.studio.id === selectedStudio.id && session.status !== 'deleted'
  );

  return (
    <Layout
      authenticatedUser={authenticatedUser}
      title="Clases"
      code="sessions"
      tabs={
        data ? data.studiosByBusiness.map((studio) => ({ code: studio.id, name: studio.name })) : []
      }
      openTab={selectedStudio ? selectedStudio.id : ''}
      handleChangeOpenTab={(value) =>
        setSelectedStudio(data.studiosByBusiness.find((studio) => studio.id === value))
      }
    >
      <>
        <Card sx={{ mb: 2 }}>
          <CardActions>
            <Button
              onClick={() =>
                setOpenSession({
                  id: null,
                  room: null,
                  instructor: null,
                  discipline: null,
                  label: '',
                  seats: null,
                  startsAt: moment().add(1, 'hour').startOf('hour').toDate().toISOString(),
                  duration: 60,
                  status: 'upcoming',
                  startedAt: null,
                  endedAt: null,
                  ...sessionDefaults,
                })
              }
              variant="contained"
              disabled={!data}
            >
              <Icon>add</Icon>
              <Box sx={{ ml: 1 }}>Clase</Box>
            </Button>

            {view === 'week' && (
              <Tooltip title="Crear clases predeterminadas">
                <div>
                  <Confirm
                    component={IconButton}
                    title="Crear clases predeterminadas"
                    description="¿Estás seguro de que quieres crear las clases predeterminadas en esta semana?"
                    icon="playlist_add"
                    verb="Crear"
                    onConfirm={() =>
                      createSessionsFromTemplates({
                        variables: {
                          studioId: selectedStudio.id,
                          since: date.startOf(view).toDate().toISOString(),
                          until: date.endOf(view).toDate().toISOString(),
                        },
                      })
                    }
                    disabled={!data || creatingSessionsFromTemplates || sessions.length > 0}
                  >
                    {creatingSessionsFromTemplates ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Icon>playlist_add</Icon>
                    )}
                  </Confirm>
                </div>
              </Tooltip>
            )}

            <Box sx={{ flex: 1 }} />

            <IconButton onClick={() => refetch()} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : <Icon>refresh</Icon>}
            </IconButton>

            {/* TODO */}
            <IconButton disabled>
              <Icon>help</Icon>
            </IconButton>
          </CardActions>

          <CardActions sx={{ justifyContent: 'space-between' }}>
            <IconButton onClick={() => setDate(moment(date).subtract(1, view))}>
              <Icon>chevron_left</Icon>
            </IconButton>

            <Button onClick={(e) => setDateAnchorEl(e.currentTarget)} color="inherit">
              <Icon>today</Icon>
              <Box sx={{ ml: 0.5 }}>
                {view === 'week'
                  ? `Del ${moment(date).startOf('week').format('DD MMM YYYY')} al ${moment(date)
                      .endOf('week')
                      .format('DD MMM YYYY')}`
                  : moment(date).format('YYYY-MM-DD')}
              </Box>
            </Button>

            <Popover
              open={Boolean(dateAnchorEl)}
              anchorEl={dateAnchorEl}
              onClose={() => setDateAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                value={date}
                onChange={(value) => setDate(value)}
                renderInput={() => null}
                renderDay={(day, selectedDates, pickersDayProps) => {
                  if (!date) {
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    return <PickersDay {...pickersDayProps} />;
                  }

                  const start = moment(date).startOf('week');
                  const end = moment(date).endOf('week');
                  const isBetween =
                    start.isSameOrBefore(day, 'day') && end.isSameOrAfter(day, 'day');
                  const isFirstDay = start.isSame(day, 'day');
                  const isLastDay = end.isSame(day, 'day');

                  return (
                    <PickersDay
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...pickersDayProps}
                      disableMargin
                      sx={(theme) => ({
                        ...(isBetween && {
                          borderRadius: 0,
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.common.white,
                          '&:hover, &:focus': {
                            backgroundColor: theme.palette.primary.dark,
                          },
                        }),
                        ...(isFirstDay && {
                          borderTopLeftRadius: '50%',
                          borderBottomLeftRadius: '50%',
                        }),
                        ...(isLastDay && {
                          borderTopRightRadius: '50%',
                          borderBottomRightRadius: '50%',
                        }),
                      })}
                    />
                  );
                }}
              />
            </Popover>

            <IconButton onClick={() => setDate(moment(date).add(1, view))}>
              <Icon>chevron_right</Icon>
            </IconButton>
          </CardActions>
        </Card>

        {!data && (
          <Paper
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 400 }}
          >
            <CircularProgress size={60} />
          </Paper>
        )}

        {data && selectedStudio && (
          <SessionsCalendar
            authenticatedUser={authenticatedUser}
            studio={selectedStudio}
            disciplines={data.disciplinesByBusiness || []}
            view={view}
            date={date}
            sessions={sessions}
            instructors={data.adminsAndInstructors || []}
            handleUpdate={() => refetch()}
          />
        )}

        {openSession && (
          <SessionDialog
            session={openSession}
            rooms={(selectedStudio ? [selectedStudio] : data.studiosByBusiness).reduce(
              (rooms, studio) => [...rooms, ...studio.rooms],
              []
            )}
            instructors={data.adminsAndInstructors.filter((user) => user.isInstructor)}
            disciplines={data.disciplinesByBusiness}
            handleClose={() => setOpenSession(null)}
            handleUpdate={() => refetch()}
          />
        )}
      </>
    </Layout>
  );
}

Sessions.propTypes = {
  authenticatedUser: instanceOf(Object).isRequired,
};
