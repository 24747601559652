import React from 'react';
import { Card, Box, CardHeader, Avatar, Icon, CardActions, Divider, Button } from '@mui/material';
import { instanceOf, func } from 'prop-types';

export default function User({ user, handleOpenUser }) {
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar src={user.picture} sx={{ backgroundColor: 'secondary.main' }}>
            {user.name.slice(0, 1).toUpperCase()}
          </Avatar>
        }
        title={user.name}
        subheader={[
          user.isOwner && 'Dueño',
          user.isAdmin && 'Administrador',
          user.isInstructor && 'Instructor',
        ]
          .filter((el) => el)
          .join(', ')}
      />
      <Divider />
      <CardActions>
        <Button size="small" onClick={handleOpenUser} variant="outlined">
          <Icon>edit</Icon>
          <Box sx={{ ml: 1 }}>Editar</Box>
        </Button>

        {/* TODO */}
        <Button size="small" onClick={handleOpenUser} variant="outlined" color="error" disabled>
          <Icon>delete</Icon>
          <Box sx={{ ml: 1 }}>Eliminar</Box>
        </Button>
      </CardActions>
    </Card>
  );
}

User.propTypes = {
  user: instanceOf(Object).isRequired,
  handleOpenUser: func.isRequired,
};
