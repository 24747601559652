import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import moment from 'moment';
import {
  CircularProgress,
  Box,
  Icon,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Autocomplete,
  Divider,
  Grid,
  Tabs,
  Tab,
  Typography,
} from '@mui/material';
import { DateTimePicker, TimePicker } from '@mui/lab';
import { instanceOf, func, string } from 'prop-types';
import Reservations from './Reservations';
import Confirm from '../Common/Confirm';

export default function SessionDialog({
  session: sessionInput,
  handleClose,
  handleUpdate,
  rooms,
  instructors,
  disciplines,
  variant,
}) {
  const [session, setSession] = useState({ ...sessionInput });
  const [tab, setTab] = useState('details');
  const [openCancelationDialog, setOpenCancelationDialog] = useState(false);

  const [updateSession, { loading: updatingSession }] = useMutation(
    gql`
      mutation UpdateSession($sessionId: ID!, $input: SessionInput!) {
        updateSession(sessionId: $sessionId, input: $input)
      }
    `,
    {
      update: () => {
        handleUpdate();
        handleClose();
      },
    }
  );

  const [createSession, { loading: creatingSession }] = useMutation(
    gql`
      mutation CreateSession($input: SessionInput!) {
        createSession(input: $input)
      }
    `,
    {
      update: () => {
        handleUpdate();
        handleClose();
      },
    }
  );

  const saving = updatingSession || creatingSession;

  const PickerComponent = variant === 'templates' ? TimePicker : DateTimePicker;

  return (
    <>
      <Dialog open fullWidth maxWidth="md" onClose={handleClose}>
        <Tabs value={tab} onChange={(e, newTab) => setTab(newTab)}>
          <Tab label="Detalles" value="details" />
          {variant === 'sessions' && (
            <Tab label="Reservaciones" value="reservations" disabled={!session.id} />
          )}
        </Tabs>
        <Divider />
        {tab === 'details' && (
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={4}>
                <PickerComponent
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(props) => <TextField margin="none" fullWidth {...props} />}
                  label="Inicia"
                  value={session.startsAt ? moment(session.startsAt) : null}
                  onChange={(newValue) => {
                    setSession({
                      ...session,
                      startsAt:
                        newValue && newValue.isValid() ? newValue.toDate().toISOString() : null,
                    });
                  }}
                  required
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  margin="none"
                  required
                  fullWidth
                  label="Duración (mins)"
                  value={session.duration || ''}
                  onChangeCapture={(e) =>
                    setSession({
                      ...session,
                      duration: e.target.value ? Number(e.target.value) : null,
                    })
                  }
                  type="number"
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  margin="none"
                  required
                  fullWidth
                  label="Capacidad"
                  value={session.seats || ''}
                  onChangeCapture={(e) =>
                    setSession({
                      ...session,
                      seats: e.target.value ? Number(e.target.value) : null,
                    })
                  }
                  type="number"
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Autocomplete
                  options={rooms}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(params) => <TextField {...params} margin="none" label="Sala" />}
                  getOptionLabel={(option) => `${option.name} en ${option.studio.name}`}
                  isOptionEqualToValue={(o, v) => o.id === v.id}
                  required
                  fullWidth
                  value={session.room}
                  onChange={(e, newValue) => {
                    setSession({
                      ...session,
                      room: newValue,
                      ...(newValue && newValue.defaultSeats
                        ? { seats: newValue.defaultSeats }
                        : {}),
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Autocomplete
                  options={disciplines}
                  renderInput={(params) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <TextField {...params} margin="none" label="Discipline" />
                  )}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(o, v) => o.id === v.id}
                  required
                  fullWidth
                  value={session.discipline}
                  onChange={(e, newValue) => {
                    setSession({ ...session, discipline: newValue });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Autocomplete
                  options={instructors}
                  renderInput={(params) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <TextField {...params} margin="none" label="Instructor" />
                  )}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(o, v) => o.id === v.id}
                  fullWidth
                  value={session.instructor}
                  onChange={(e, newValue) => {
                    setSession({ ...session, instructor: newValue });
                  }}
                />
              </Grid>
            </Grid>

            <TextField
              margin="normal"
              fullWidth
              label="Etiqueta"
              value={session.label || ''}
              onChangeCapture={(e) => setSession({ ...session, label: e.target.value })}
            />
          </DialogContent>
        )}

        {tab === 'reservations' && (
          <Reservations sessionId={session.id} handleUpdate={handleUpdate} />
        )}

        <Divider />
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" size="small">
            <Icon>cancel</Icon>
            <Box sx={{ ml: 1 }}>Cerrar</Box>
          </Button>
          {tab === 'details' && (
            <>
              {session.id &&
                !saving &&
                ['upcoming', 'delayed', 'successful'].includes(session.status) && (
                  <Confirm
                    component={Button}
                    title={
                      variant !== 'templates'
                        ? '¿Estás seguro de que quieres cancelar esta clase?'
                        : '¿Estás seguro de que quieres eliminar esta clase?'
                    }
                    description={
                      variant !== 'templates'
                        ? 'Se le devolverán los créditos a todos los usuarios que tengan una reservación activa para esta clase.'
                        : null
                    }
                    verb={variant !== 'templates' ? 'Cancelar' : 'Eliminar'}
                    color="error"
                    buttonColor="error"
                    icon="event_busy"
                    onConfirm={() => {
                      updateSession({
                        variables: {
                          sessionId: session.id,
                          input: {
                            room: session.room ? session.room.id : null,
                            instructor: session.instructor ? session.instructor.id : null,
                            discipline: session.discipline ? session.discipline.id : null,
                            status: variant !== 'templates' ? 'canceled' : 'deleted',
                          },
                        },
                      });
                    }}
                    disabled={saving}
                    variant="contained"
                    size="small"
                  >
                    <>
                      <Icon>event_busy</Icon>
                      <Box sx={{ ml: 1 }}>{variant !== 'templates' ? 'Cancelar' : 'Eliminar'}</Box>
                    </>
                  </Confirm>
                )}

              <Button
                onClick={() => {
                  if (session.id) {
                    updateSession({
                      variables: {
                        sessionId: session.id,
                        input: {
                          room: session.room ? session.room.id : null,
                          instructor: session.instructor ? session.instructor.id : null,
                          discipline: session.discipline ? session.discipline.id : null,
                          label: session.label,
                          seats: session.seats,
                          startsAt: session.startsAt,
                          duration: session.duration,
                        },
                      },
                    });
                  } else {
                    createSession({
                      variables: {
                        input: {
                          room: session.room ? session.room.id : null,
                          instructor: session.instructor ? session.instructor.id : null,
                          discipline: session.discipline ? session.discipline.id : null,
                          label: session.label,
                          seats: session.seats,
                          startsAt: session.startsAt,
                          duration: session.duration,
                          status: session.status,
                        },
                      },
                    });
                  }
                }}
                disabled={
                  saving ||
                  !session.room ||
                  !session.discipline ||
                  !session.seats ||
                  !session.startsAt ||
                  !session.duration
                }
                variant="contained"
                size="small"
              >
                {saving ? <CircularProgress size={24} /> : <Icon>save</Icon>}
                <Box sx={{ ml: 1 }}>Guardar</Box>
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={openCancelationDialog}>
        <DialogContent>
          <Typography>¿Estás seguro que quieres cancelar esta clase?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpenCancelationDialog(false)}>
            <Icon>cancel</Icon>
            <Box sx={{ ml: 1 }}>Cerrar</Box>
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setOpenCancelationDialog(false);
              updateSession({
                variables: {
                  sessionId: session.id,
                  input: {
                    room: session.room ? session.room.id : null,
                    instructor: session.instructor ? session.instructor.id : null,
                    discipline: session.discipline ? session.discipline.id : null,
                    label: session.label,
                    seats: session.seats,
                    startsAt: session.startsAt,
                    duration: session.duration,
                    status: 'canceled',
                  },
                },
              });
            }}
          >
            <Icon>cancel</Icon>
            <Box sx={{ ml: 1 }}>Cancelar</Box>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

SessionDialog.propTypes = {
  session: instanceOf(Object),
  handleClose: func.isRequired,
  handleUpdate: func.isRequired,
  rooms: instanceOf(Array).isRequired,
  instructors: instanceOf(Array).isRequired,
  disciplines: instanceOf(Array).isRequired,
  variant: string,
};

SessionDialog.defaultProps = {
  session: null,
  variant: 'sessions',
};
