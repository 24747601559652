import React from 'react';
import { Icon } from '@mui/material';
import { string } from 'prop-types';

export default function MessageStatusIndicator({ status, position }) {
  const sx = {
    position: 'relative',
    top: '2px',
    fontSize: '14px',
    mr: position === 'left' ? '2px' : 0,
    ml: position === 'right' ? '2px' : 0,
  };

  return (
    <>
      {status === 'error' && (
        <Icon color="error" sx={sx}>
          error
        </Icon>
      )}

      {status === 'pending' && (
        <Icon color="action" sx={sx}>
          schedule
        </Icon>
      )}

      {status === 'sent' && (
        <Icon color="action" sx={sx}>
          done
        </Icon>
      )}

      {status === 'received' && (
        <Icon color="action" sx={sx}>
          done_all
        </Icon>
      )}

      {status === 'read' && (
        <Icon color="primary" sx={sx}>
          done_all
        </Icon>
      )}
    </>
  );
}

MessageStatusIndicator.propTypes = {
  status: string.isRequired,
  position: string,
};

MessageStatusIndicator.defaultProps = {
  position: null,
};
