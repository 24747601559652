import React from 'react';

import { instanceOf } from 'prop-types';

export default function CalendarEvent({ children, event }) {
  const { resource: session } = event;
  return (
    <div className={`stuiodm-rbc-event-wrapper-${session.status || 'pending'}`}>{children}</div>
  );
}

CalendarEvent.propTypes = {
  children: instanceOf(Object).isRequired,
  event: instanceOf(Object).isRequired,
};
