import React, { Fragment, useRef, useState, useEffect } from 'react';
import { instanceOf, element, string, func, bool } from 'prop-types';
import {
  AppBar,
  Drawer,
  Box,
  Toolbar,
  IconButton,
  Icon,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Badge,
  Hidden,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import { MainContainerHeightContext } from './MainContainerHeightContext';
import { useLocalStorage } from '../../../utils/useLocalStorage';

export default function Layout({
  authenticatedUser,
  children,
  code,
  title,
  tabs,
  openTab,
  handleChangeOpenTab,
  maxWidth,
  fullWidth,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useLocalStorage('openSidenav', false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mainContainerHeight, setMainContainerHeight] = useState(0);
  const toolbarRef = useRef(null);
  const titleContainerRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const handleHeightChange = () => {
      if (toolbarRef && titleContainerRef) {
        setMainContainerHeight(
          window.innerHeight -
            toolbarRef.current.clientHeight -
            titleContainerRef.current.clientHeight -
            (isMobile ? 16 : 56) - // main container padding
            (isMobile ? 56 : 0) // bottom nav
        );
      }
    };

    handleHeightChange();
    window.addEventListener('resize', handleHeightChange);
    return () => window.removeEventListener('resize', handleHeightChange);
  }, [toolbarRef, titleContainerRef, isMobile]);

  const handleSignOut = () => {
    window.localStorage.setItem('authToken', '');
    navigate('/sign-in');
  };

  const menuItems = [
    {
      code: 'reports',
      route: '/reports',
      icon: 'analytics',
      name: 'Reportes',
      show: authenticatedUser.isOwner,
    },
    {
      code: 'sessions',
      route: '/sessions',
      icon: 'calendar_month',
      name: 'Clases',
      show: authenticatedUser.isOwner || authenticatedUser.isAdmin,
    },
    {
      code: 'customers',
      route: '/customers',
      icon: 'people',
      name: 'Clientes',
      show: authenticatedUser.isOwner || authenticatedUser.isAdmin,
    },
    {
      code: 'check-in',
      route: '/check-in',
      icon: 'checklist',
      name: 'Check-in',
      show:
        authenticatedUser.isOwner || authenticatedUser.isAdmin || authenticatedUser.isInstructor,
    },
    {
      code: 'bills',
      route: '/register',
      icon: 'point_of_sale',
      name: 'Caja',
      show:
        authenticatedUser.isOwner || authenticatedUser.isAdmin || authenticatedUser.isInstructor,
    },
    {
      code: 'crm',
      route: '/crm/inbox',
      icon: 'mark_chat_unread',
      name: 'CRM',
      show: authenticatedUser.isOwner || authenticatedUser.isAdmin,
    },
    {
      code: 'settings',
      route: '/settings',
      icon: 'settings',
      name: 'Configuración',
      show: authenticatedUser.isOwner || authenticatedUser.isAdmin,
    },
  ].filter((item) => item.show);

  return (
    <Box>
      <AppBar
        position="fixed"
        color="default"
        sx={() => ({
          top: 0,
          right: 0,
          width: { xs: '100%', md: open ? 'calc(100% - 240px)' : 'calc(100% - 84px)' },
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(6px)',
          transition: 'all 0.24s',
        })}
        elevation={0}
      >
        <Toolbar>
          <Typography variant="button" color="textSecondary">
            {isMobile ? title : authenticatedUser.business.name}
          </Typography>

          <Box sx={{ flex: 1 }} />

          {/* TODO */}
          <IconButton disabled>
            <Badge badgeContent={0} color="error">
              <Icon>notifications</Icon>
            </Badge>
          </IconButton>

          <Tooltip title="Tu cuenta">
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} size="small" sx={{ ml: 2 }}>
              <Avatar src={authenticatedUser.picture} sx={{ backgroundColor: 'secondary.main' }}>
                {authenticatedUser.name.slice(0, 1).toUpperCase()}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            onClick={() => setAnchorEl(null)}
            PaperProps={{
              sx: {
                overflow: 'visible',
                mt: 1.5,
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 20,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                  borderTop: '1px solid rgba(145, 158, 171, 0.12)',
                  borderLeft: '1px solid rgba(145, 158, 171, 0.12)',
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {menuItems.some((item) => item.code === 'settings') && (
              <MenuItem component={RouterLink} to="/settings">
                <ListItemIcon>
                  <Icon>settings</Icon>
                </ListItemIcon>
                Configuración
              </MenuItem>
            )}

            <Divider />

            <MenuItem onClick={handleSignOut}>
              <ListItemIcon>
                <Icon>logout</Icon>
              </ListItemIcon>
              Cerrar sesión
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Hidden mdDown>
        <Drawer
          elevation={0}
          variant="permanent"
          sx={{
            '& > .MuiDrawer-paper': {
              position: 'fixed',
              width: open ? 240 : 84,
              height: '100%',
              overflowX: 'hidden',
              overflowY: 'auto',
              transition: 'width 0.24s',
              whiteSpace: 'nowrap',
              borderRight: '1px dashed rgba(145, 158, 171, 0.24)',
              '&:hover': {
                width: 240,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                backdropFilter: 'blur(6px)',
              },
            },
          }}
        >
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
              minWidth: 240,
            }}
          >
            <IconButton
              onClick={() => setOpen(!open)}
              sx={{
                transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: 'all 0.54s',
              }}
            >
              <Icon>chevron_left</Icon>
            </IconButton>
          </Toolbar>
          <Divider />

          <List sx={{ py: 2, px: 2 }}>
            {menuItems.map((item) => (
              <Fragment key={item.code}>
                {item.divider === 'before' && <Divider />}
                <ListItem
                  key={item.code}
                  button
                  selected={code === item.code}
                  component={RouterLink}
                  to={item.route}
                  sx={{ my: 0.5 }}
                >
                  <ListItemIcon>
                    <Icon color={code === item.code ? 'primary' : 'action'}>{item.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItem>
                {item.divider === 'after' && <Divider />}
              </Fragment>
            ))}
          </List>
        </Drawer>
      </Hidden>

      <Hidden mdUp>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 5 }}>
          <BottomNavigation showLabels value={code} onChange={() => {}}>
            {menuItems
              .filter((item) => item.code !== 'settings')
              .map((item) => (
                <BottomNavigationAction
                  key={item.code}
                  value={item.code}
                  label={item.name}
                  icon={<Icon>{item.icon}</Icon>}
                  component={RouterLink}
                  to={item.route}
                  sx={{ minWidth: 0, px: 0 }}
                />
              ))}
          </BottomNavigation>
        </Paper>
      </Hidden>

      <Box
        component="main"
        sx={{
          backgroundColor: (t) => t.palette.background.default,
          width: { xs: '100%', md: open ? 'calc(100% - 240px)' : 'calc(100% - 84px)' },
          marginLeft: { xs: 0, md: open ? '240px' : '84px' },
          minHeight: '100vh',
          paddingBottom: { xs: '56px', md: 0 },
          transition: 'all 0.24s',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Toolbar ref={toolbarRef} />
        <Container
          ref={titleContainerRef}
          sx={{ maxWidth: 'none !important', pt: { xs: 0, md: 2 } }}
        >
          <Typography
            variant="h4"
            color="textPrimary"
            sx={{ fontWeight: 'bold', mb: 2, display: { xs: 'none', md: 'block' } }}
          >
            {title}
          </Typography>
          <Box sx={{ borderBottom: '1px dashed', borderColor: 'divider' }}>
            {tabs.length > 0 && openTab && (
              <Tabs
                value={openTab}
                onChange={(e, value) => handleChangeOpenTab(value)}
                variant="scrollable"
                scrollButtons="auto"
              >
                {tabs.map((tab, idx) => (
                  <Tab
                    key={tab.code}
                    value={tab.code}
                    label={tab.name}
                    sx={idx === 0 ? { pl: 0 } : {}}
                  />
                ))}
              </Tabs>
            )}
          </Box>
        </Container>
        <Container maxWidth={!fullWidth && maxWidth} sx={{ my: { xs: 1, md: 4 }, flex: 1 }}>
          <MainContainerHeightContext.Provider value={mainContainerHeight}>
            {children}
          </MainContainerHeightContext.Provider>
        </Container>
      </Box>
    </Box>
  );
}

Layout.propTypes = {
  authenticatedUser: instanceOf(Object).isRequired,
  children: element.isRequired,
  code: string.isRequired,
  title: string.isRequired,
  tabs: instanceOf(Array),
  openTab: string,
  handleChangeOpenTab: func,
  maxWidth: string,
  fullWidth: bool,
};

Layout.defaultProps = {
  tabs: [],
  openTab: null,
  handleChangeOpenTab: () => {},
  maxWidth: 'lg',
  fullWidth: false,
};
