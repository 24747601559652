import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  Grid,
  Button,
  IconButton,
  Card,
  CardActions,
  Paper,
} from '@mui/material';
import { instanceOf } from 'prop-types';

import User from './User';
import UserDialog from './UserDialog';

export default function Users({ authenticatedUser }) {
  const [openUser, setOpenUser] = useState(null);

  const { loading, data, refetch } = useQuery(
    gql`
      query GetAdminsAndInstructors {
        adminsAndInstructors {
          id
          email
          phone
          name
          picture
          address {
            street
            neighborhood
            city
            state
            zipcode
            country
          }
          isOwner
          isAdmin
          isInstructor
          birthday
        }
      }
    `,
    { notifyOnNetworkStatusChange: true }
  );

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardActions>
          <Button
            onClick={() =>
              setOpenUser({
                id: null,
                email: '',
                phone: '',
                name: '',
                picture: '',
                address: {
                  street: '',
                  neighborhood: '',
                  city: '',
                  state: '',
                  zipcode: '',
                  country: '',
                },
                isOwner: false,
                isAdmin: false,
                isInstructor: true,
                birthday: '',
              })
            }
            variant="contained"
            size="small"
            disabled={!data}
          >
            <Icon>add</Icon>
            <Box sx={{ ml: 1 }}>Usuario</Box>
          </Button>
          <Box sx={{ flex: 1 }} />
          <IconButton onClick={() => refetch()} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : <Icon>refresh</Icon>}
          </IconButton>

          {/* TODO */}
          <IconButton size="small" disabled>
            <Icon>help</Icon>
          </IconButton>
        </CardActions>
      </Card>

      {!data && (
        <Paper
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 400 }}
        >
          <CircularProgress size={60} />
        </Paper>
      )}

      {data && (
        <Grid container spacing={2}>
          {data.adminsAndInstructors.map((user) => (
            <Grid key={user.id} item xs={12} md={6} lg={4}>
              <User user={user} handleOpenUser={() => setOpenUser(user)} />
            </Grid>
          ))}
        </Grid>
      )}

      {openUser && (
        <UserDialog
          user={openUser}
          handleClose={() => setOpenUser(null)}
          handleUpdate={() => {
            setOpenUser(null);
            refetch();
          }}
          authenticatedUser={authenticatedUser}
        />
      )}
    </>
  );
}

Users.propTypes = {
  authenticatedUser: instanceOf(Object).isRequired,
};
