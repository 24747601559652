import React, { useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  Button,
  IconButton,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  CardActions,
  Card,
  Avatar,
  Chip,
  Grid,
  CardHeader,
  CardContent,
  Tooltip,
} from '@mui/material';
import { instanceOf } from 'prop-types';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';

import Layout from '../Common/Layout';
import Pack from './Pack';
import PackDialog from './PackDialog';
import BillsTable from '../Bills/BillsTable';
import ReservationsList from '../Sessions/ReservationsList';
import CustomerDialog from './CustomerDialog';

export default function CustomerDetails({ authenticatedUser }) {
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useParams();
  const [open, setOpen] = useState(false);
  const [openPack, setOpenPack] = useState(null);

  const { loading, data, refetch } = useQuery(
    gql`
      query GetCustomer($customerId: ID!) {
        user(userId: $customerId) {
          id
          email
          phone
          name
          birthday
          picture
          address {
            street
            neighborhood
            city
            state
            zipcode
            country
          }
          isOwner
          isAdmin
          isInstructor
          isRegistered
          membership {
            isActive
            isExpired
          }
          packs {
            id
            user {
              id
            }
            credits
            startAt
            endAt
            usedCredits
            availableCredits
            borrowedCredits
          }
          bills {
            id
            createdBy {
              id
              name
            }
            items {
              id
              name
              quantity
              price
              currency
              exchangeRate
              subtotal
              total
            }
            createdAt
            total
            totalPaid
            balance
            paymentStatus
            payments {
              id
              amount
              type
              ref
              proof
            }
            studio {
              id
              name
            }
            isCanceled
            canceledBy {
              id
              name
            }
            canceledAt
          }
          reservations {
            id
            status
            seat
            createdAt
            session {
              startsAt
              duration
              discipline {
                name
              }
              room {
                studio {
                  name
                }
              }
            }
          }
        }
      }
    `,
    { variables: { customerId }, notifyOnNetworkStatusChange: true }
  );

  const [sendSelfRegistrationEmail, { loading: sendingSelfRegistrationEmail }] = useMutation(
    gql`
      mutation SelfRegistrationEmail($businessId: ID!, $email: String!) {
        selfRegistrationEmail(businessId: $businessId, email: $email)
      }
    `,
    {
      update: () => {
        enqueueSnackbar('Se envió un correo electrónico con las instrucciones de registro.', {
          variant: 'success',
        });
      },
    }
  );

  const [sendSelfRegistrationPhone, { loading: sendingSelfRegistrationPhone }] = useMutation(
    gql`
      mutation SelfRegistrationPhone($businessId: ID!, $phone: String!) {
        selfRegistrationPhone(businessId: $businessId, phone: $phone)
      }
    `,
    {
      update: () => {
        enqueueSnackbar('Se envió un SMS con las instrucciones de registro.', {
          variant: 'success',
        });
      },
    }
  );

  const borrowedCredits =
    data && data.user && data.user.packs
      ? data.user.packs.reduce((sum, pack) => sum + (pack.borrowedCredits || 0), 0)
      : 0;

  return (
    <Layout
      authenticatedUser={authenticatedUser}
      title={data ? data.user.name : 'Cargando...'}
      code="customers"
    >
      <>
        <Card sx={{ mb: 2 }}>
          <CardActions>
            <Tooltip title="Enviar correo de registro">
              <span>
                <IconButton
                  onClick={() =>
                    sendSelfRegistrationEmail({
                      variables: {
                        businessId: authenticatedUser.business.id,
                        email: data.user.email,
                      },
                    })
                  }
                  disabled={sendingSelfRegistrationEmail || !data || !data.user || !data.user.email}
                >
                  {sendingSelfRegistrationEmail ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Icon>email</Icon>
                  )}
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Enviar SMS de registro">
              <span>
                <IconButton
                  onClick={() =>
                    sendSelfRegistrationPhone({
                      variables: {
                        businessId: authenticatedUser.business.id,
                        phone: data.user.phone,
                      },
                    })
                  }
                  disabled={sendingSelfRegistrationPhone || !data || !data.user || !data.user.phone}
                >
                  {sendingSelfRegistrationPhone ? <CircularProgress size={24} /> : <Icon>sms</Icon>}
                </IconButton>
              </span>
            </Tooltip>

            <Box sx={{ flex: 1 }} />

            <IconButton onClick={() => refetch()} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : <Icon>refresh</Icon>}
            </IconButton>

            {/* TODO */}
            <IconButton disabled>
              <Icon>help</Icon>
            </IconButton>
          </CardActions>
        </Card>

        {!data && (
          <Paper
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 400 }}
          >
            <CircularProgress size={60} />
          </Paper>
        )}

        {data && (
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <Card
                sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row', lg: 'column' } }}
              >
                <Avatar
                  src={data.user.picture}
                  sx={(theme) => ({
                    width: { xs: '100%', sm: 180, lg: '100%' },
                    height: 240,
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.dark,
                  })}
                  variant="square"
                >
                  <Icon fontSize="large">person</Icon>
                </Avatar>
                <Box>
                  <CardHeader
                    title={data.user.name}
                    sx={{ paddingBottom: 0 }}
                    action={
                      <IconButton
                        onClick={() => setOpen(true)}
                        disabled={
                          data &&
                          data.user &&
                          (data.user.isOwner || data.user.isAdmin || data.user.isInstructor)
                        }
                      >
                        <Icon>edit</Icon>
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                      <Icon color="action" sx={{ fontSize: 16 }}>
                        email
                      </Icon>
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {data.user.email}
                      </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                      <Icon color="action" sx={{ fontSize: 16 }}>
                        phone
                      </Icon>
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {data.user.phone}
                      </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                      <Icon color="action" sx={{ fontSize: 16 }}>
                        home
                      </Icon>
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {[
                          data.user.address.street,
                          data.user.address.neighborhood,
                          data.user.address.city,
                          data.user.address.state,
                          data.user.address.zipcode,
                          data.user.address.country,
                        ]
                          .filter((item) => item)
                          .join(', ')}
                      </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                      <Icon color="action" sx={{ fontSize: 16 }}>
                        cake
                      </Icon>
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {data.user.birthday
                          ? moment(data.user.birthday).locale('es').format('DD MMMM')
                          : ''}
                      </Typography>
                    </Box>

                    <Chip
                      variant="filled"
                      color={data.user.isRegistered ? 'success' : 'error'}
                      size="small"
                      label={data.user.isRegistered ? 'Registrado' : 'No registrado'}
                      sx={{ mr: 1, mt: 1 }}
                    />

                    {data.user.membership.isActive && (
                      <Chip
                        variant="filled"
                        color="success"
                        size="small"
                        label="Membresía activa"
                        sx={{ mr: 1, mt: 1 }}
                      />
                    )}

                    {!data.user.membership.isActive && data.user.membership.isExpired && (
                      <Chip
                        variant="filled"
                        color="warning"
                        size="small"
                        label="Membresía expirada"
                        sx={{ mr: 1, mt: 1 }}
                      />
                    )}

                    {!data.user.membership.isActive && !data.user.membership.isExpired && (
                      <Chip
                        variant="filled"
                        color="error"
                        size="small"
                        label="Sin membresía"
                        sx={{ mr: 1, mt: 1 }}
                      />
                    )}

                    {Boolean(borrowedCredits) && (
                      <Chip
                        variant="filled"
                        color="warning"
                        size="small"
                        label={
                          borrowedCredits === 1
                            ? '1 crédito adelantado'
                            : `${borrowedCredits} créditos adelantados`
                        }
                        sx={{ mr: 1, mt: 1 }}
                      />
                    )}
                  </CardContent>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} lg={8}>
              <Box
                sx={{
                  mt: 1,
                  mb: 1,
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="subtitle2" color="primary">
                  Paquetes de créditos
                </Typography>

                <Button
                  onClick={() =>
                    setOpenPack({
                      id: null,
                      user: {
                        id: data.user.id,
                        name: data.user.name,
                      },
                      credits: 1,
                      startAt: moment().startOf('day').toDate().toISOString(),
                      endAt: moment().add(1, 'week').endOf('day').toDate().toISOString(),
                    })
                  }
                  size="small"
                  variant="contained"
                >
                  <Icon sx={{ mr: 1 }}>add</Icon>
                  Adelantar
                </Button>
              </Box>

              <Paper sx={{ overflow: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Inicia</TableCell>
                      <TableCell>Termina</TableCell>
                      <TableCell align="right">Totales</TableCell>
                      <TableCell align="right">Usados</TableCell>
                      <TableCell align="right">Disponibles</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.user.packs.map((pack) => (
                      <Pack key={pack.id} pack={pack} handleOpenPack={() => setOpenPack(pack)} />
                    ))}
                  </TableBody>
                </Table>
              </Paper>

              <Typography variant="subtitle2" color="primary" sx={{ mt: 4, mb: 1 }}>
                Compras
              </Typography>

              <Paper sx={{ overflow: 'auto' }}>
                <BillsTable
                  bills={data.user.bills}
                  showDate
                  size="small"
                  showStudio
                  handleUpdate={() => {
                    refetch();
                  }}
                />
              </Paper>

              <Typography variant="subtitle2" color="primary" sx={{ mt: 4, mb: 1 }}>
                Reservaciones
              </Typography>

              <Paper>
                <ReservationsList
                  reservations={data.user.reservations}
                  emptyMessage="No hay reservaciones registradas."
                  handleUpdate={() => refetch()}
                  variant="session"
                />
              </Paper>
            </Grid>
          </Grid>
        )}

        {openPack && (
          <PackDialog
            pack={openPack}
            handleClose={() => setOpenPack(null)}
            handleUpdate={() => {
              setOpenPack(null);
              refetch();
            }}
          />
        )}

        {open && (
          <CustomerDialog
            customer={data.user}
            plans={[]}
            handleClose={() => setOpen(false)}
            handleUpdate={() => {
              setOpen(false);
              refetch();
            }}
          />
        )}
      </>
    </Layout>
  );
}

CustomerDetails.propTypes = {
  authenticatedUser: instanceOf(Object).isRequired,
};
