import React, { useMemo, useState } from 'react';
import { Paper } from '@mui/material';
import { func, instanceOf, string } from 'prop-types';
import moment from 'moment-timezone';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import SessionDialog from './SessionDialog';
import '../Common/Calendar.css';
import CalendarEvent from '../Common/CalendarEvent';
import CalendarEventWrapper from '../Common/CalendarEventWrapper';

const localizer = momentLocalizer(moment);

export default function SessionsCalendar({
  authenticatedUser,
  studio,
  disciplines,
  view,
  date,
  sessions,
  instructors,
  handleUpdate,
  variant,
}) {
  const [openSession, setOpenSession] = useState(null);

  const sessionDefaults = useMemo(() => {
    const room = studio.rooms.length === 1 ? studio.rooms[0] : null;

    const seats = room ? room.defaultSeats : null;

    const discipline = disciplines.length === 1 ? disciplines[0] : null;

    return {
      room,
      discipline,
      seats,
    };
  }, [studio, disciplines]);

  return (
    <>
      <Paper sx={{ overflow: 'hidden' }}>
        <Calendar
          localizer={localizer}
          view={view}
          onView={() => {}}
          views={['week', 'day']}
          culture="es-MX"
          step={15}
          timeslots={4}
          toolbar={false}
          date={date.toDate()}
          onNavigate={() => {}}
          events={sessions
            .filter((session) => session.status !== 'deleted')
            .map((session) => ({
              title: '',
              start: moment(session.startsAt).toDate(),
              end: moment(session.startsAt).add(session.duration, 'minutes').toDate(),
              resource: {
                ...session,
                status:
                  session.status === 'upcoming' &&
                  moment(session.startsAt).add(session.duration, 'minutes').toDate() < new Date()
                    ? 'delayed'
                    : session.status,
              },
            }))}
          formats={{
            eventTimeRangeFormat: ({ start, end }) =>
              `${moment(start).format('HH:mm')} · ${moment(end).diff(start, 'minutes')} min.`,
            timeGutterFormat: (d) => moment(d).format('h a'),
            dayFormat: (d) =>
              moment(d)
                .locale('es')
                .format(variant === 'sessions' ? 'ddd DD' : 'ddd')
                .replace('.', '')
                .toUpperCase(),
          }}
          components={{
            event: CalendarEvent,
            eventWrapper: CalendarEventWrapper,
          }}
          onSelectEvent={({ resource: session }) => setOpenSession(session)}
          min={new Date(studio.opensAt || '')}
          max={new Date(studio.closesAt || '')}
          onSelectSlot={({ start, end }) =>
            setOpenSession({
              id: null,
              room: null,
              instructor: null,
              discipline: null,
              label: '',
              seats: '',
              startsAt: moment(start).toDate().toISOString(),
              duration: moment(end).diff(start, 'minutes'),
              status: 'upcoming',
              startedAt: null,
              endedAt: null,
              ...sessionDefaults,
            })
          }
          selectable
          dayPropGetter={(day) => ({
            style: !authenticatedUser.business.workdays.includes(moment(day).day())
              ? { backgroundColor: 'rgba(145, 158, 171, 0.06)' }
              : {},
          })}
        />
      </Paper>

      {openSession && (
        <SessionDialog
          session={openSession}
          rooms={studio.rooms}
          instructors={instructors.filter((user) => user.isInstructor)}
          disciplines={disciplines}
          handleClose={() => setOpenSession(null)}
          handleUpdate={handleUpdate}
          variant={variant}
        />
      )}
    </>
  );
}

SessionsCalendar.propTypes = {
  authenticatedUser: instanceOf(Object).isRequired,
  studio: instanceOf(Object).isRequired,
  disciplines: instanceOf(Array).isRequired,
  sessions: instanceOf(Array).isRequired,
  instructors: instanceOf(Array).isRequired,
  view: string.isRequired,
  date: instanceOf(Object).isRequired,
  handleUpdate: func.isRequired,
  variant: string,
};

SessionsCalendar.defaultProps = {
  variant: 'sessions',
};
