import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Divider,
} from '@mui/material';
import { instanceOf, func } from 'prop-types';
import { DatePicker } from '@mui/lab';
import moment from 'moment';

export default function PackDialog({ pack: packInput, handleClose, handleUpdate }) {
  const [pack, setPack] = useState({ ...packInput });

  const [updatePack, { loading: updatingPack }] = useMutation(
    gql`
      mutation UpdatePack($packId: ID!, $input: PackInput!) {
        updatePack(packId: $packId, input: $input)
      }
    `,
    { update: handleUpdate }
  );

  const [createPack, { loading: creatingPack }] = useMutation(
    gql`
      mutation CreatePack($input: PackInput!) {
        createBorrowedPack(input: $input)
      }
    `,
    { update: handleUpdate }
  );

  const saving = updatingPack || creatingPack;

  return (
    <Dialog open fullWidth maxWidth="md" onClose={handleClose}>
      <DialogContent>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Créditos"
          value={pack.credits || ''}
          type="number"
          onChangeCapture={(e) =>
            setPack({ ...pack, credits: e.target.value ? Number(e.target.value) : null })
          }
        />

        <DatePicker
          label="Inicia"
          value={pack.startAt ? moment(pack.startAt) : null}
          onChange={(newValue) => {
            setPack({
              ...pack,
              startAt:
                newValue && newValue.isValid()
                  ? newValue.startOf('day').toDate().toISOString()
                  : null,
            });
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={(params) => <TextField margin="normal" fullWidth {...params} />}
        />

        <DatePicker
          label="Expira"
          value={pack.endAt ? moment(pack.endAt) : null}
          onChange={(newValue) => {
            setPack({
              ...pack,
              endAt:
                newValue && newValue.isValid()
                  ? newValue.startOf('day').toDate().toISOString()
                  : null,
            });
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={(params) => <TextField margin="normal" fullWidth {...params} />}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" size="small">
          <Icon>cancel</Icon>
          <Box sx={{ ml: 1 }}>Cerrar</Box>
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            const input = {
              user: pack.user.id,
              credits: pack.credits,
              startAt: pack.startAt,
              endAt: pack.endAt,
            };

            if (pack.id) {
              updatePack({
                variables: {
                  packId: pack.id,
                  input,
                },
              });
            } else {
              createPack({
                variables: {
                  input,
                },
              });
            }
          }}
          disabled={saving || !pack.credits || !pack.startAt || !pack.endAt}
        >
          {saving ? <CircularProgress size={24} /> : <Icon>save</Icon>}
          <Box sx={{ ml: 1 }}>Guardar</Box>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PackDialog.propTypes = {
  pack: instanceOf(Object),
  handleClose: func.isRequired,
  handleUpdate: func.isRequired,
};

PackDialog.defaultProps = {
  pack: null,
};
