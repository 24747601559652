import React from 'react';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { instanceOf, func } from 'prop-types';
import { DatePicker } from '@mui/lab';
import moment from 'moment';

import UploadInput from '../Common/UploadInput';
import { PhoneNumberInput } from '../Common/PhoneNumberInput';

export default function CustomerForm({ customer, setCustomer }) {
  return (
    <>
      <TextField
        margin="normal"
        required
        fullWidth
        label="Nombre"
        value={customer.name || ''}
        onChangeCapture={(e) => setCustomer({ ...customer, name: e.target.value })}
      />

      <TextField
        margin="normal"
        required
        fullWidth
        label="Correo electrónico"
        value={customer.email || ''}
        onChangeCapture={(e) => setCustomer({ ...customer, email: e.target.value })}
      />

      <TextField
        margin="normal"
        required
        fullWidth
        label="Teléfono"
        value={customer.phone || ''}
        onChange={(e) => setCustomer({ ...customer, phone: e.target.value })}
        InputProps={{
          inputComponent: PhoneNumberInput,
          startAdornment: <InputAdornment position="start">+52</InputAdornment>,
        }}
      />

      <DatePicker
        label="Fecha de nacimiento"
        value={customer.birthday ? moment(customer.birthday) : null}
        onChange={(newValue) => {
          setCustomer({
            ...customer,
            birthday:
              newValue && newValue.isValid()
                ? newValue.startOf('day').toDate().toISOString()
                : null,
          });
        }}
        renderInput={(params) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <TextField margin="normal" fullWidth {...params} />
        )}
        fullWidth
      />

      <UploadInput
        label="Foto de perfil"
        uniqueName={`customer-picture-${customer.id}`}
        value={customer.picture || ''}
        onChange={(url) => setCustomer({ ...customer, picture: url })}
      />

      <Typography variant="subtitle2" color="primary" sx={{ mt: 4, mb: 1 }}>
        Dirección
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            margin="dense"
            fullWidth
            label="Calle y número"
            value={customer.address.street || ''}
            onChangeCapture={(e) =>
              setCustomer({
                ...customer,
                address: { ...customer.address, street: e.target.value },
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            margin="dense"
            fullWidth
            label="Colonia"
            value={customer.address.neighborhood || ''}
            onChangeCapture={(e) =>
              setCustomer({
                ...customer,
                address: { ...customer.address, neighborhood: e.target.value },
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            margin="dense"
            fullWidth
            label="Municipio"
            value={customer.address.city || ''}
            onChangeCapture={(e) =>
              setCustomer({
                ...customer,
                address: { ...customer.address, city: e.target.value },
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            margin="dense"
            fullWidth
            label="Estado"
            value={customer.address.state || ''}
            onChangeCapture={(e) =>
              setCustomer({
                ...customer,
                address: { ...customer.address, state: e.target.value },
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            margin="dense"
            fullWidth
            label="Código postal"
            value={customer.address.zipcode || ''}
            onChangeCapture={(e) =>
              setCustomer({
                ...customer,
                address: { ...customer.address, zipcode: e.target.value },
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            margin="dense"
            fullWidth
            label="País"
            value={customer.address.country || ''}
            onChangeCapture={(e) =>
              setCustomer({
                ...customer,
                address: { ...customer.address, country: e.target.value },
              })
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

CustomerForm.propTypes = {
  customer: instanceOf(Object),
  setCustomer: func.isRequired,
};

CustomerForm.defaultProps = {
  customer: null,
};
