/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import AdapterDateMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { SnackbarProvider } from 'notistack';

import SignIn from './Auth/SignIn';
import SignUp from './Auth/SignUp';
import AuthRoute from './Common/AuthRoute';
import Redirector from './Common/Redirector';
import Reports from './Reports/Reports';
import Settings from './Settings/Settings';
import Sessions from './Sessions/Sessions';
import Customers from './Customers/Customers';
import CustomerDetails from './Customers/CustomerDetails';
import TodaySessions from './CheckIn/TodaySessions';
import CheckIn from './CheckIn/CheckIn';
import Bills from './Bills/Bills';
import Crm from './Crm/Crm';
import RecoverPassword from './Auth/RecoverPassword';
import ResetPassword from './Auth/ResetPassword';

const theme = createTheme({
  palette: {
    text: { primary: 'rgb(33, 43, 54)' },
    primary: {
      main: '#0091ea',
    },
    secondary: {
      main: '#fc0',
    },
    success: {
      main: 'rgb(0, 171, 85)',
    },
    error: {
      main: 'rgb(255, 72, 66)',
    },
    background: {
      default: '#ffffff',
    },
    divider: 'rgba(145, 158, 171, 0.24)',
  },
  components: {
    MuiPaper: {
      defaultProps: { variant: 'elevation' },
      styleOverrides: {
        rounded: { borderRadius: 8 },
        elevation1: {
          boxShadow:
            'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
        },
        elevation8: {
          boxShadow:
            'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) -20px 20px 40px -4px',
        },
      },
    },
    MuiButton: {
      defaultProps: { size: 'small' },
      styleOverrides: { root: { textTransform: 'initial', borderRadius: 8, padding: '6px 16px' } },
    },
    MuiDivider: { styleOverrides: { root: { borderStyle: 'dashed' } } },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontWeight: 'bold',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        button: { padding: '8px 12px 8px 16px', borderRadius: 8 },
      },
    },
  },
});

const httpLink = createHttpLink({
  uri: `${process.env.API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('authToken');

  if (!token) {
    return { headers };
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default function App() {
  return (
    <ApolloProvider client={client}>
      <LocalizationProvider dateAdapter={AdapterDateMoment}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <CssBaseline />
            <Routes>
              <Route path="/sign-in" element={<SignIn />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/recover-password" element={<RecoverPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/reports" element={<AuthRoute Component={Reports} />} />
              <Route path="/settings" element={<AuthRoute Component={Settings} />} />
              <Route path="/sessions" element={<AuthRoute Component={Sessions} />} />
              <Route
                path="/customers/:customerId"
                element={<AuthRoute Component={CustomerDetails} />}
              />
              <Route path="/customers" element={<AuthRoute Component={Customers} />} />
              <Route path="/check-in/:sessionId" element={<AuthRoute Component={CheckIn} />} />
              <Route path="/check-in" element={<AuthRoute Component={TodaySessions} />} />
              <Route path="/register" element={<AuthRoute Component={Bills} />} />
              <Route path="/crm/:openTab/:id" exact element={<AuthRoute Component={Crm} />} />
              <Route path="/crm/:openTab" exact element={<AuthRoute Component={Crm} />} />
              <Route path="/" exact element={<AuthRoute Component={Redirector} />} />
            </Routes>
          </SnackbarProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </ApolloProvider>
  );
}
