import React, { useEffect } from 'react';
import { instanceOf } from 'prop-types';
import { useNavigate } from 'react-router-dom';

export default function Redirector({ authenticatedUser }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticatedUser.isOwner) {
      navigate('/reports');
      return;
    }

    if (authenticatedUser.isAdmin) {
      navigate('/sessions');
      return;
    }

    navigate('/check-in');
  }, [authenticatedUser, navigate]);

  return <div />;
}

Redirector.propTypes = {
  authenticatedUser: instanceOf(Object).isRequired,
};
