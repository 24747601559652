import React, { Fragment, useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  IconButton,
  Paper,
  Typography,
  Button,
  Card,
  CardActions,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  ListItemButton,
} from '@mui/material';
import { instanceOf } from 'prop-types';
import { useParams } from 'react-router-dom';

import Layout from '../Common/Layout';
import NewReservation from '../Sessions/NewReservation';
import ConfirmCustomer from '../Common/ConfirmCustomer';
import PaidBillDialog from '../Bills/PaidBillDialog';
import Confirm from '../Common/Confirm';

export default function CheckIn({ authenticatedUser }) {
  const [newReservation, setNewReservation] = useState(false);
  const [openPaidBillDialog, setOpenPaidBillDialog] = useState(null);
  const { sessionId } = useParams();

  const { loading, data, refetch } = useQuery(
    gql`
      query GetSession($sessionId: ID!, $businessId: ID!) {
        plansByBusiness(businessId: $businessId) {
          id
          type
          name
          price
          currency
          requiresMembership
          duration
          durationUnit
        }
        session(sessionId: $sessionId) {
          id
          reservations {
            id
            user {
              id
              name
              email
              phone
              picture
              lastValidationPictures
              packs {
                borrowedCredits
              }
            }
            status
            seat
          }
          availableSeats
          room {
            studio {
              id
            }
          }
          endedAt
        }
      }
    `,
    {
      variables: {
        sessionId,
        businessId: authenticatedUser.business.id,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const [recordAttendance, { loading: recordingAttendance }] = useMutation(
    gql`
      mutation RecordAttendance($reservationId: ID!, $input: ReservationInput!) {
        updateReservation(reservationId: $reservationId, input: $input)
      }
    `,
    {
      update: () => {
        refetch();
      },
    }
  );

  const [endSession] = useMutation(
    gql`
      mutation EndSession($sessionId: ID!) {
        endSession(sessionId: $sessionId)
      }
    `,
    {
      update: () => {
        refetch();
      },
    }
  );

  const upcomingReservations = data
    ? data.session.reservations.filter((reservation) => reservation.status === 'upcoming')
    : [];

  const successfulReservations = data
    ? data.session.reservations.filter(
        (reservation) =>
          reservation.status === 'successful' || reservation.status === 'successful-last-minute'
      )
    : [];

  return (
    <Layout authenticatedUser={authenticatedUser} title="Check-in" code="check-in">
      <>
        <Card sx={{ mb: 2 }}>
          <CardActions>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                '& > *': {
                  mb: { xs: '8px !important', md: '0 !important' },
                  mr: { xs: '0 !important', md: '8px !important' },
                },
              }}
            >
              <Button variant="contained" size="small" onClick={() => setNewReservation(true)}>
                <Icon>add</Icon>
                <Box sx={{ ml: 1 }}>Asistencia</Box>
              </Button>

              <Button onClick={() => setOpenPaidBillDialog(true)} variant="outlined">
                <Icon>point_of_sale</Icon>
                <Box sx={{ ml: 1 }}>Venta</Box>
              </Button>

              <Confirm
                component={Button}
                variant="outlined"
                color="success"
                size="small"
                icon="event_available"
                title="¿Estás seguro de que quieres finalizar esta clase?"
                verb="Finalizar"
                buttonColor="success"
                onConfirm={() => {
                  endSession({ variables: { sessionId } });
                }}
                disabled={Boolean(!data || !data.session || data.session.endedAt)}
              >
                <Icon>event_available</Icon>
                <Box sx={{ ml: 1 }}>
                  {data && data.session && data.session.endedAt ? 'Finalizada' : 'Finalizar'}
                </Box>
              </Confirm>
            </Box>

            <Box sx={{ flex: 1 }} />

            <IconButton onClick={() => refetch()} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : <Icon>refresh</Icon>}
            </IconButton>

            {/* TODO */}
            <IconButton disabled>
              <Icon>help</Icon>
            </IconButton>
          </CardActions>
          {newReservation && (
            <Box sx={{ pt: 1 }}>
              <NewReservation
                sessionId={sessionId}
                status="successful-last-minute"
                availableSeats={data.session.availableSeats}
                handleClose={() => setNewReservation(false)}
                handleUpdate={() => {
                  setNewReservation(false);
                  refetch();
                }}
              />
            </Box>
          )}
        </Card>

        <Typography variant="subtitle2" color="primary" sx={{ mt: 4, mb: 1 }}>
          Reservaciones
        </Typography>

        <Paper sx={{ overflow: 'auto' }}>
          <List dense>
            {upcomingReservations.length === 0 && (
              <ListItem>
                <ListItemText secondary="No hay reservaciones para esta clase." />
              </ListItem>
            )}
            {upcomingReservations.map((reservation, idx) => (
              <Fragment key={reservation.id}>
                {idx !== 0 && <Divider />}
                <ListItem disablePadding>
                  <ConfirmCustomer
                    component={ListItemButton}
                    customer={reservation.user}
                    onConfirm={(newCustomerPicture, customerValidationPicture) => {
                      recordAttendance({
                        variables: {
                          reservationId: reservation.id,
                          input: {
                            status: 'successful',
                            newCustomerPicture,
                            customerValidationPicture,
                          },
                        },
                      });
                    }}
                    confirming={recordingAttendance}
                  >
                    <>
                      <ListItemAvatar>
                        <Avatar
                          src={reservation.user.picture}
                          sx={{ backgroundColor: 'secondary.main' }}
                        >
                          {reservation.user.name.slice(0, 1).toUpperCase()}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Box>
                            {reservation.user.name}
                            <Box
                              sx={{ display: 'inline-block', ml: 1, fontWeight: 'bold' }}
                            >{`#${reservation.seat}`}</Box>
                          </Box>
                        }
                        secondary={[reservation.user.email, reservation.user.phone]
                          .filter((item) => item)
                          .join(' · ')}
                      />
                    </>
                  </ConfirmCustomer>
                </ListItem>
              </Fragment>
            ))}
          </List>
        </Paper>

        <Typography variant="subtitle2" color="primary" sx={{ mt: 4, mb: 1 }}>
          Asistencia
        </Typography>

        <Paper sx={{ overflow: 'auto' }}>
          <List dense>
            {successfulReservations.length === 0 && (
              <ListItem>
                <ListItemText secondary="No hay asistencias en esta clase." />
              </ListItem>
            )}
            {successfulReservations.map((reservation, idx) => (
              <Fragment key={reservation.id}>
                {idx !== 0 && <Divider />}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      src={reservation.user.picture}
                      sx={{ backgroundColor: 'secondary.main' }}
                    >
                      {reservation.user.name.slice(0, 1).toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Box>
                        {reservation.user.name}
                        <Box
                          sx={{ display: 'inline-block', ml: 1, fontWeight: 'bold' }}
                        >{`#${reservation.seat}`}</Box>
                      </Box>
                    }
                    secondary={[reservation.user.email, reservation.user.phone]
                      .filter((item) => item)
                      .join(' · ')}
                  />
                </ListItem>
              </Fragment>
            ))}
          </List>
        </Paper>

        {openPaidBillDialog && (
          <PaidBillDialog
            handleClose={() => setOpenPaidBillDialog(false)}
            handleUpdate={() => refetch()}
            plans={data.plansByBusiness}
            requireNewCurrentCreditsPlan
            disablePastDates
            sessionId={sessionId}
            availableSeats={data.session.availableSeats}
            studioId={data.session.room.studio.id}
          />
        )}
      </>
    </Layout>
  );
}

CheckIn.propTypes = {
  authenticatedUser: instanceOf(Object).isRequired,
};
