/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-props-no-spreading */

import React, { Fragment, useEffect, useState, useRef, useMemo } from 'react';
import {
  Dialog,
  Button,
  DialogContent,
  Box,
  IconButton,
  Icon,
  DialogTitle,
  CircularProgress,
  LinearProgress,
  Typography,
  Alert,
} from '@mui/material';
import { func, instanceOf, element, elementType, bool } from 'prop-types';
import {
  createCanvasFromMedia,
  matchDimensions,
  TinyFaceDetectorOptions,
  resizeResults,
  draw,
  nets,
  fetchImage,
  LabeledFaceDescriptors,
  FaceMatcher,
  detectSingleFace,
} from 'face-api.js';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import UploadInput from './UploadInput';

export default function ConfirmCustomer({
  customer,
  onConfirm,
  component: Component,
  children,
  confirming,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const [tmpPicture, setTmpPicture] = useState(null);
  const [attendancePicture, setAttendancePicture] = useState(null);
  const [attendancePictureBlob, setAttendancePictureBlob] = useState(null);
  const [uploadingAttendancePicture, setUploadingAttendancePicture] = useState(false);
  const [loadedModels, setLoadedModels] = useState(false);
  const [invalidPicture, setInvalidPicture] = useState(false);
  const [blockedCamera, setBlockedCamera] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const videoRef = useRef();
  const canvasRef = useRef();

  const width = 300;
  const height = 360;

  const borrowedCredits = useMemo(
    () =>
      customer && customer.packs
        ? customer.packs.reduce((sum, pack) => sum + (pack.borrowedCredits || 0), 0)
        : 0,
    [customer]
  );

  useEffect(() => {
    let interval = null;
    let videoEl = null;
    let faceMatcher = null;

    if (open && customer.picture) {
      // reset
      setBlockedCamera(false);
      setAttendancePicture(null);
      setAttendancePictureBlob(null);
      setUploadingAttendancePicture(false);
      setInvalidPicture(false);

      // get webcam stream
      navigator.mediaDevices.getUserMedia({ video: { width, height }, audio: false }).then(
        (stream) => {
          // set video source (stream)
          videoEl = videoRef.current;
          videoEl.srcObject = stream;

          // start detecting on play every X miliseconds
          videoEl.onplay = () => {
            interval = setInterval(async () => {
              const canvasEl = canvasRef.current;

              if (canvasEl) {
                canvasEl.innerHTML = createCanvasFromMedia(videoEl);

                matchDimensions(canvasEl, { width, height });

                // detect face
                const detection = await detectSingleFace(videoEl, new TinyFaceDetectorOptions())
                  .withFaceLandmarks()
                  .withFaceDescriptor();

                // resize detected face
                const resizedDetections = resizeResults(detection ? [detection] : [], {
                  width,
                  height,
                });

                if (canvasEl) {
                  // clear
                  canvasEl.getContext('2d').clearRect(0, 0, width, height);

                  resizedDetections.forEach((resizedDetection) => {
                    let isMatch = false;

                    if (faceMatcher) {
                      const result = faceMatcher.findBestMatch(resizedDetection.descriptor);

                      // eslint-disable-next-line no-console
                      console.log(result);

                      if (result.label === customer.id) {
                        isMatch = true;

                        // get picture blob
                        canvasEl.getContext('2d').drawImage(videoEl, 0, 0);
                        canvasEl.toBlob((blob) => setAttendancePictureBlob(blob));
                      }
                    }

                    // draw detection
                    new draw.DrawBox(resizedDetection.detection.box, {
                      boxColor: isMatch ? 'rgba(38, 209, 83, 1)' : 'rgba(255, 204, 0, 1)',
                      lineWidth: 2,
                    }).draw(canvasEl);

                    // draw detected face landmarks
                    new draw.DrawFaceLandmarks(
                      resizedDetection.landmarks,
                      new draw.DrawFaceLandmarksOptions({
                        drawLines: true,
                        drawPoints: true,
                        lineColor: isMatch ? 'rgba(38, 209, 83, 0.4)' : 'rgba(255, 204, 0, 0.4)',
                        pointColor: isMatch ? 'rgba(38, 209, 83, 0.95)' : 'rgba(255, 204, 0, 0.95)',
                        lineWidth: 3,
                        pointSize: 3,
                      })
                    ).draw(canvasEl);
                  });
                }
              }
            }, 1000);
          };
          videoEl.play();
        },
        () => setBlockedCamera(true)
      );

      // load models
      Promise.all([
        nets.tinyFaceDetector.loadFromUri(process.env.FACEAPI_MODELS),
        nets.faceLandmark68Net.loadFromUri(process.env.FACEAPI_MODELS),
        nets.faceRecognitionNet.loadFromUri(process.env.FACEAPI_MODELS),
      ]).then(async () => {
        setLoadedModels(true);

        const descriptors = [];
        await Promise.all(
          [customer.picture, ...(customer.lastValidationPictures || [])].map(async (picture) => {
            // detect face (sample)
            const img = await fetchImage(picture);
            const faceDescription = await detectSingleFace(img, new TinyFaceDetectorOptions())
              .withFaceLandmarks()
              .withFaceDescriptor();

            // invalid picture
            if (!faceDescription && picture === customer.picture) {
              setInvalidPicture(true);
              enqueueSnackbar(
                'No detectamos la cara del cliente en su foto registrada, agrega una nueva.',
                { variant: 'info' }
              );
              return;
            }

            if (faceDescription) {
              descriptors.push(faceDescription.descriptor);
            }
          })
        );

        // create label
        const labeledFaceDescriptors = new LabeledFaceDescriptors(customer.id, descriptors);

        // create face matcher
        faceMatcher = new FaceMatcher(labeledFaceDescriptors, 0.5);
      });
    }

    return () => {
      // clear interval and stop video
      try {
        clearInterval(interval);
        videoEl.pause();
        videoEl.srcObject.getTracks()[0].stop();
      } catch {
        //
      }
    };
  }, [enqueueSnackbar, customer, open]);

  useEffect(() => {
    if (attendancePictureBlob && !attendancePicture && !uploadingAttendancePicture) {
      setUploadingAttendancePicture(true);
      const formData = new FormData();
      formData.append('file', attendancePictureBlob);

      axios
        .post(`${process.env.API_URL}/upload`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((res) => setAttendancePicture(res.data.url));
    }
  }, [attendancePictureBlob, attendancePicture, uploadingAttendancePicture]);

  return (
    <>
      <Component {...props} onClick={() => setOpen(true)}>
        {children}
      </Component>

      {customer && (
        <Dialog open={open} onClose={() => setOpen(false)}>
          <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
            <IconButton onClick={() => setOpen(false)}>
              <Icon>close</Icon>
            </IconButton>
          </Box>

          <DialogTitle sx={{ textAlign: 'center' }}>{customer.name}</DialogTitle>

          {customer.picture && !invalidPicture && (
            <Box sx={{ position: 'relative' }}>
              {!loadedModels && <LinearProgress variant="indeterminate" />}
              {!blockedCamera && <video ref={videoRef} width={width} height={height} />}
              {blockedCamera && (
                <Box
                  sx={(theme) => ({
                    width,
                    height,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: theme.palette.action.disabledBackground,
                    flexDirection: 'column',
                  })}
                >
                  <Icon color="disabled" sx={{ fontSize: 60 }}>
                    no_photography
                  </Icon>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ mt: 2, maxWidth: 200, textAlign: 'center' }}
                  >
                    Bloqueaste el permiso de acceso a la cámara.
                  </Typography>
                </Box>
              )}
              <Box
                component="canvas"
                ref={canvasRef}
                sx={{ position: 'absolute', top: 0, left: 0 }}
              />
              {attendancePicture && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width,
                    height,
                    backgroundColor: 'rgb(46, 125, 50, 0.9)',
                  }}
                />
              )}
              <Box
                sx={{
                  width: attendancePicture ? 240 : 120,
                  height: attendancePicture ? 240 : 120,
                  objectFit: 'cover',
                  position: 'absolute',
                  left: attendancePicture ? width / 2 - 120 : 8,
                  top: attendancePicture ? height / 2 - 120 : height - 120 - 8,
                  transition: 'all 0.4s',
                  borderRadius: '100%',
                }}
                component="img"
                src={customer.picture}
              />
            </Box>
          )}

          {(!customer.picture || invalidPicture) && (
            <Box sx={{ width }}>
              <UploadInput
                variant="preview"
                uniqueName="confirm-customer-picture"
                value={tmpPicture || ''}
                onChange={(url) => setTmpPicture(url)}
              />
            </Box>
          )}

          {borrowedCredits > 0 && (
            <Box sx={{ maxWidth: width, px: 1, pt: 1 }}>
              <Alert
                color="warning"
                icon={<Icon>warning</Icon>}
              >{`Este cliente tiene ${borrowedCredits} créditos adelantados que debe cubrir.`}</Alert>
            </Box>
          )}

          <DialogContent>
            <Button
              onClick={() => onConfirm(tmpPicture, attendancePicture)}
              size="large"
              variant="contained"
              fullWidth
              disabled={Boolean(confirming || (!attendancePicture && attendancePictureBlob))}
              color={attendancePicture ? 'success' : 'primary'}
            >
              {confirming || (!attendancePicture && attendancePictureBlob) ? (
                <CircularProgress size={24} sx={{ mr: 2 }} />
              ) : (
                'Confirmar'
              )}
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

ConfirmCustomer.propTypes = {
  customer: instanceOf(Object),
  onConfirm: func.isRequired,
  component: elementType.isRequired,
  children: element,
  confirming: bool,
};

ConfirmCustomer.defaultProps = {
  children: null,
  confirming: false,
  customer: null,
};
