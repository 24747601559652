import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Grid,
  Icon,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Link,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

export default function SignUp() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState(null);
  const [payload, setPayload] = useState({ email: '', phone: '' });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenInput = params.get('token');
    setToken(tokenInput);
    setPayload(JSON.parse(atob(tokenInput.split('.')[1])));
  }, [location.search]);

  const [register, { loading, error, data, client }] = useMutation(
    gql`
      mutation Register($invitationToken: String!, $input: UserInput!) {
        register(invitationToken: $invitationToken, input: $input)
      }
    `
  );

  useEffect(() => {
    if (data && data.register) {
      window.localStorage.setItem('authToken', data.register);
      client.resetStore();
      navigate('/');
    }
  }, [data, navigate, client]);

  useEffect(() => {
    if (error && error.message === 'already-exists') {
      enqueueSnackbar('Ya tienes una cuenta registrada, inicia tu sesión.', { variant: 'info' });
      navigate('/sign-in');
    }
  }, [enqueueSnackbar, navigate, error]);

  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            'url(https://images.unsplash.com/photo-1571902943202-507ec2618e8f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1975&q=80)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
          }}
        >
          <Avatar sx={{ mb: 2, bgcolor: 'secondary.main', width: 80, height: 80 }}>
            <Icon fontSize="large">account_box</Icon>
          </Avatar>
          <Typography component="h1" variant="h5">
            Regístrate
          </Typography>

          {error && (
            <Typography variant="body2" color="error" mt={4}>
              Algo salió mal
            </Typography>
          )}

          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Correo electrónico"
              value={payload.email || ''}
              disabled
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Teléfono"
              value={payload.phone || ''}
              disabledInputProps={{
                startAdornment: <InputAdornment position="start">+52</InputAdornment>,
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Nombre"
              autoFocus
              value={name || ''}
              onChangeCapture={(e) => setName(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Contraseña"
              type="password"
              autoComplete="new-password"
              value={password || ''}
              onChangeCapture={(e) => setPassword(e.target.value)}
            />
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() =>
                register({
                  variables: {
                    invitationToken: token,
                    input: {
                      name,
                      password,
                    },
                  },
                })
              }
              disabled={loading || !name || !password}
            >
              {loading ? <CircularProgress size={24} /> : 'Regístrate'}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/sign-in" variant="body2">
                  ¿Ya tienes cuenta? Inicia sesión
                </Link>
              </Grid>
            </Grid>
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 5 }}>
              Copyright © StudioM 2022.
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
