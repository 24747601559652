import React, { useState } from 'react';
import { instanceOf, bool, func } from 'prop-types';
import { Table, TableHead, TableBody, TableFooter, TableRow, TableCell } from '@mui/material';
import moment from 'moment';

import BillDialog from './BillDialog';

import { formatCurrency } from '../../../utils/formatCurrency';

export default function BillsTable({ bills, showCustomers, showDate, showStudio, handleUpdate }) {
  const [openBill, setOpenBill] = useState(null);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {showDate && <TableCell>Fecha</TableCell>}
            <TableCell>Hora</TableCell>
            {showStudio && <TableCell>Estudio</TableCell>}
            {showCustomers && <TableCell>Cliente</TableCell>}
            <TableCell>Registrado por</TableCell>
            <TableCell align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bills.length === 0 && (
            <TableRow>
              <TableCell colSpan={3 + !!showDate + !!showStudio + !!showCustomers}>
                No hay ventas registradas.
              </TableCell>
            </TableRow>
          )}
          {bills.map((bill) => (
            <TableRow
              hover
              key={bill.id}
              onClick={() => setOpenBill(bill)}
              sx={{
                cursor: 'pointer',
                ...(bill.isCanceled ? { textDecoration: 'line-through' } : {}),
              }}
            >
              {showDate && <TableCell>{moment(bill.createdAt).format('YYYY-MM-DD')}</TableCell>}
              <TableCell>{moment(bill.createdAt).format('hh:mm a')}</TableCell>
              {showStudio && <TableCell>{bill.studio ? bill.studio.name : '-'}</TableCell>}
              {showCustomers && <TableCell>{bill.customer.name}</TableCell>}
              <TableCell>{bill.createdBy.name}</TableCell>
              <TableCell align="right">{formatCurrency(bill.total)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            {showDate && <TableCell />}
            <TableCell />
            {showStudio && <TableCell />}
            {showCustomers && <TableCell />}
            <TableCell />
            <TableCell align="right">
              {formatCurrency(
                bills
                  .filter((bill) => !bill.isCanceled)
                  .reduce((total, bill) => total + bill.total, 0)
              )}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>

      {openBill && (
        <BillDialog
          bill={openBill}
          handleClose={() => setOpenBill(null)}
          handleUpdate={handleUpdate}
        />
      )}
    </>
  );
}

BillsTable.propTypes = {
  bills: instanceOf(Array).isRequired,
  showCustomers: bool,
  showDate: bool,
  showStudio: bool,
  handleUpdate: func.isRequired,
};

BillsTable.defaultProps = {
  showCustomers: false,
  showDate: false,
  showStudio: false,
};
