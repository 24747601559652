import React, { useState } from 'react';
import { func, string, bool } from 'prop-types';
import {
  Button,
  Box,
  CircularProgress,
  IconButton,
  Icon,
  Typography,
  Link,
  ButtonBase,
} from '@mui/material';
import axios from 'axios';

export default function UploadInput({
  label,
  uniqueName,
  value,
  onChange,
  accept,
  variant,
  noMargin,
}) {
  const [uploading, setUploading] = useState(false);

  const handleUpload = (selectedFile) => {
    if (selectedFile) {
      setUploading(true);
      onChange(null);

      const formData = new FormData();
      formData.append('file', selectedFile);

      axios
        .post(`${process.env.API_URL}/upload`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((res) => {
          setUploading(false);
          onChange(res.data.url);
        });
    }
  };

  if (variant === 'preview') {
    return (
      <ButtonBase component="label" htmlFor={uniqueName} sx={{ width: '100%' }}>
        <Box
          sx={(theme) => ({
            width: '100%',
            height: 360,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            backgroundColor: theme.palette.action.disabledBackground,
            backgroundImage: `url(${value})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          })}
        >
          {!value && !uploading && (
            <Icon sx={{ fontSize: 80 }} color="action">
              add_a_photo
            </Icon>
          )}
          {uploading && <CircularProgress size={80} />}

          <Box
            component="input"
            type="file"
            id={uniqueName}
            sx={{ display: 'none' }}
            onChange={(e) => {
              handleUpload(e.target.files[0]);
              e.target.value = null;
            }}
            accept={accept}
          />
        </Box>
      </ButtonBase>
    );
  }

  return (
    <Box
      sx={{
        borderRadius: 1,
        border: 1,
        p: 1,
        borderColor: 'rgba(0,0,0,0.25)',
        '&:hover': {
          borderColor: 'inherit',
        },
        position: 'relative',
        mt: 3,
        mb: noMargin ? 0 : 4,
      }}
    >
      <Typography
        variant="caption"
        sx={{
          position: 'absolute',
          top: -12,
          left: 14,
          padding: '0 5px',
          backgroundColor: '#fff',
          color: 'rgba(0, 0, 0, 0.6)',
        }}
      >
        {label}
      </Typography>

      {!value && (
        <Button component="label" htmlFor={uniqueName} disabled={uploading}>
          {uploading && <CircularProgress size={24} sx={{ mr: 1 }} />}
          {uploading ? 'Subiendo archivo' : 'Seleccionar archivo'}
          <Box
            component="input"
            type="file"
            id={uniqueName}
            sx={{ display: 'none' }}
            onChange={(e) => {
              handleUpload(e.target.files[0]);
              e.target.value = null;
            }}
            accept={accept}
          />
        </Button>
      )}

      {value && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => onChange(null)}>
            <Icon>delete</Icon>
          </IconButton>
          {accept.includes('image') ? (
            <Box
              component="img"
              src={value}
              sx={{
                width: 120,
                height: 120,
                objectFit: 'contain',
                backgroundColor: 'rgba(0, 0, 0, .05)',
                border: '1px solid rgba(0, 0, 0, .1)',
                borderRadius: '3px',
                padding: '4px',
              }}
            />
          ) : (
            <Link variant="caption" href={value} target="_blank">
              {value}
            </Link>
          )}
        </Box>
      )}
    </Box>
  );
}

UploadInput.propTypes = {
  label: string,
  uniqueName: string.isRequired,
  value: string,
  onChange: func,
  accept: string,
  variant: string,
  noMargin: bool,
};

UploadInput.defaultProps = {
  value: '',
  onChange: () => {},
  accept: 'image/*',
  variant: 'outlined',
  noMargin: false,
  label: null,
};
