import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  Grid,
  Button,
  IconButton,
  Card,
  CardActions,
  Paper,
} from '@mui/material';
import { instanceOf } from 'prop-types';
import moment from 'moment-timezone';

import Studio from './Studio';
import StudioDialog from './StudioDialog';

export default function StudiosAndRooms({ authenticatedUser }) {
  const [openStudio, setOpenStudio] = useState(null);

  const { loading, data, refetch } = useQuery(
    gql`
      query GetStudiosAndRooms($businessId: ID!) {
        studiosByBusiness(businessId: $businessId) {
          id
          name
          logo
          picture
          address {
            street
            neighborhood
            city
            state
            zipcode
            country
          }
          opensAt
          closesAt
          rooms {
            id
            name
            defaultSeats
            studio {
              id
            }
          }
        }
      }
    `,
    { variables: { businessId: authenticatedUser.business.id }, notifyOnNetworkStatusChange: true }
  );

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardActions>
          <Button
            onClick={() =>
              setOpenStudio({
                id: null,
                name: '',
                logo: '',
                picture: '',
                address: {
                  street: '',
                  neighborhood: '',
                  city: '',
                  state: '',
                  zipcode: '',
                  country: '',
                },
                opensAt: moment().startOf('day').add(6, 'hours').toDate().toISOString(),
                closesAt: moment().startOf('day').add(22, 'hours').toDate().toISOString(),
              })
            }
            variant="contained"
            size="small"
            disabled={!data}
          >
            <Icon>add</Icon>
            <Box sx={{ ml: 1 }}>Estudio</Box>
          </Button>
          <Box sx={{ flex: 1 }} />
          <IconButton onClick={() => refetch()} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : <Icon>refresh</Icon>}
          </IconButton>

          {/* TODO */}
          <IconButton size="small" disabled>
            <Icon>help</Icon>
          </IconButton>
        </CardActions>
      </Card>

      {!data && (
        <Paper
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 400 }}
        >
          <CircularProgress size={60} />
        </Paper>
      )}

      {data && (
        <Grid container spacing={2}>
          {data.studiosByBusiness.map((studio) => (
            <Grid key={studio.id} item xs={12} md={6} lg={4}>
              <Studio
                studio={studio}
                handleOpenStudio={() => setOpenStudio(studio)}
                handleUpdate={() => refetch()}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {openStudio && (
        <StudioDialog
          studio={openStudio}
          handleClose={() => setOpenStudio(null)}
          handleUpdate={() => {
            setOpenStudio(null);
            refetch();
          }}
        />
      )}
    </>
  );
}

StudiosAndRooms.propTypes = {
  authenticatedUser: instanceOf(Object).isRequired,
};
