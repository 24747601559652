import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from '@mui/material';
import { instanceOf, func } from 'prop-types';

export default function PlanDialog({ plan: planInput, handleClose, handleUpdate }) {
  const [plan, setPlan] = useState({ ...planInput });

  const [updatePlan, { loading: updatingPlan }] = useMutation(
    gql`
      mutation UpdatePlan($planId: ID!, $input: PlanInput!) {
        updatePlan(planId: $planId, input: $input)
      }
    `,
    { update: handleUpdate }
  );

  const [createPlan, { loading: creatingPlan }] = useMutation(
    gql`
      mutation CreatePlan($input: PlanInput!) {
        createPlan(input: $input)
      }
    `,
    { update: handleUpdate }
  );

  const saving = updatingPlan || creatingPlan;

  return (
    <Dialog open fullWidth maxWidth="md" onClose={handleClose}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Nombre"
              value={plan.name || ''}
              onChangeCapture={(e) => setPlan({ ...plan, name: e.target.value })}
              autoFocus
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="none">
              <InputLabel>Tipo</InputLabel>
              <Select
                value={plan.type || ''}
                label="Tipo"
                onChange={(e) => setPlan({ ...plan, type: e.target.value })}
              >
                <MenuItem value="pack">Créditos</MenuItem>
                <MenuItem value="membership">Membresía</MenuItem>
                <MenuItem value="product">Producto</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              margin="none"
              required
              fullWidth
              label="Precio"
              value={plan.price || plan.price === 0 ? plan.price : ''}
              onChangeCapture={(e) =>
                setPlan({ ...plan, price: e.target.value ? Number(e.target.value) : null })
              }
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="none">
              <InputLabel>Moneda</InputLabel>
              <Select
                value={plan.currency || ''}
                label="Moneda"
                onChange={(e) => setPlan({ ...plan, currency: e.target.value })}
              >
                <MenuItem value="mxn">MXN</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {plan.type === 'pack' && (
            <>
              {!plan.dynamicCredits && (
                <Grid item xs={12} md={4}>
                  <TextField
                    margin="none"
                    required
                    fullWidth
                    label="Créditos"
                    value={plan.credits || ''}
                    onChangeCapture={(e) =>
                      setPlan({ ...plan, credits: e.target.value ? Number(e.target.value) : null })
                    }
                    type="number"
                  />
                </Grid>
              )}

              <Grid item xs={12} md={4}>
                <TextField
                  margin="none"
                  required
                  fullWidth
                  label="Duración"
                  value={plan.duration || ''}
                  onChangeCapture={(e) =>
                    setPlan({ ...plan, duration: e.target.value ? Number(e.target.value) : null })
                  }
                  type="number"
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth margin="none">
                  <InputLabel>Unidad de duración</InputLabel>
                  <Select
                    value={plan.durationUnit || ''}
                    label="Unidad de duración"
                    onChange={(e) => setPlan({ ...plan, durationUnit: e.target.value })}
                  >
                    <MenuItem value="day">Día</MenuItem>
                    <MenuItem value="week">Semana</MenuItem>
                    <MenuItem value="month">Mes</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={plan.dynamicCredits}
                        onChange={() => setPlan({ ...plan, dynamicCredits: !plan.dynamicCredits })}
                      />
                    }
                    label="Usar créditos dinámicos (uno por cada día laboral del periodo)."
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={plan.isSubscribable}
                        onChange={() => setPlan({ ...plan, isSubscribable: !plan.isSubscribable })}
                      />
                    }
                    label="Los clientes se pueden suscribir a este plan."
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={plan.requiresMembership}
                        onChange={() =>
                          setPlan({ ...plan, requiresMembership: !plan.requiresMembership })
                        }
                      />
                    }
                    label="Requiere membresía."
                  />
                </FormGroup>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" size="small">
          <Icon>cancel</Icon>
          <Box sx={{ ml: 1 }}>Cerrar</Box>
        </Button>
        <Button
          onClick={() => {
            const input = {
              type: plan.type,
              name: plan.name,
              price: plan.price,
              currency: plan.currency,
              isSubscribable: plan.isSubscribable,
              requiresMembership: plan.requiresMembership,

              // packs only
              credits: plan.credits,
              dynamicCredits: plan.dynamicCredits,
              duration: plan.duration,
              durationUnit: plan.durationUnit,
            };

            if (plan.id) {
              updatePlan({
                variables: {
                  planId: plan.id,
                  input,
                },
              });
            } else {
              createPlan({
                variables: {
                  input,
                },
              });
            }
          }}
          disabled={
            saving ||
            !plan.name ||
            !plan.type ||
            (!plan.price && plan.price !== 0) ||
            !plan.currency ||
            (plan.type === 'pack' &&
              ((!plan.credits && !plan.dynamicCredits) || !plan.duration || !plan.durationUnit))
          }
          variant="contained"
          size="small"
        >
          {saving ? <CircularProgress size={24} /> : <Icon>save</Icon>}
          <Box sx={{ ml: 1 }}>Guardar</Box>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PlanDialog.propTypes = {
  plan: instanceOf(Object),
  handleClose: func.isRequired,
  handleUpdate: func.isRequired,
};

PlanDialog.defaultProps = {
  plan: null,
};
