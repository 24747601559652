import React, { useRef, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Box, CircularProgress, Divider, Paper } from '@mui/material';
import { string } from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';

import Message from './Message';
import NewMessage from './NewMessage';
import ContactDetails from './ContactDetails';

export default function Contact({ id }) {
  const scrollbarRef = useRef(null);
  const [autoScroll, setAutoScroll] = useState(true);

  const { data } = useQuery(
    gql`
      query GetContact($contactId: ID!) {
        contact(contactId: $contactId) {
          id
          user {
            id
            picture
            isRegistered
            membership {
              isActive
              isExpired
            }
            lastBill {
              studio {
                name
              }
              createdAt
            }
            lastBillWithPackPlan {
              studio {
                name
              }
              createdAt
            }
            lastSuccessfulReservation {
              session {
                room {
                  studio {
                    name
                  }
                }
                startsAt
              }
            }
            sort {
              lastBillWithPackPlan {
                plan
                expiresAt
              }
            }
          }
          contactInfo {
            type
            value
            disabled
          }
          unreadIncomingMessagesCount
          lastMessage {
            id
            direction
            text
            attachments {
              url
              mimeType
              originalFilename
            }
            status
            createdAt
          }
          messages {
            id
            direction
            text
            attachments {
              url
              mimeType
              originalFilename
            }
            createdAt
            status
            channel {
              name
            }
            to
            from
            sentBy {
              name
            }
          }
        }
      }
    `,
    { variables: { contactId: id }, notifyOnNetworkStatusChange: true, pollInterval: 5000 }
  );

  const scroll = () => {
    if (autoScroll && scrollbarRef && scrollbarRef.current) {
      scrollbarRef.current.scrollToBottom();
    }
  };

  scroll();

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <ContactDetails contact={(data && data.contact) || null} />
      <Divider />
      <Paper
        sx={{
          flex: 1,
          backgroundImage:
            'linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% )',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        <Scrollbars
          ref={(e) => {
            scrollbarRef.current = e;
            scroll();
          }}
          onScroll={() => setAutoScroll(scrollbarRef.current.getValues().top === 1)}
        >
          <Box sx={{ px: 2, py: 1, pt: 4 }}>
            {data && data.contact ? (
              data.contact.messages.map((message) => <Message key={message.id} message={message} />)
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '200px',
                }}
              >
                <CircularProgress size={64} />
              </Box>
            )}
          </Box>
        </Scrollbars>
      </Paper>

      <Divider />

      {data && data.contact && (
        <NewMessage
          contactId={data.contact.id}
          contactInfo={data.contact.contactInfo}
          onSend={() => setAutoScroll(true)}
        />
      )}
    </Box>
  );
}

Contact.propTypes = {
  id: string,
};

Contact.defaultProps = {
  id: null,
};
