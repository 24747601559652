/* eslint-disable react/prop-types */

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import { useQuery, gql } from '@apollo/client';

export default function AuthRoute({ Component }) {
  const navigate = useNavigate();
  const { loading, data } = useQuery(gql`
    query {
      me {
        id
        email
        phone
        name
        picture
        address {
          street
          neighborhood
          city
          state
          zipcode
          country
        }
        isOwner
        isAdmin
        isInstructor
        business {
          id
          name
          workdays
        }
      }
    }
  `);

  useEffect(() => {
    if (
      !loading &&
      (!data || !data.me || (!data.me.isOwner && !data.me.isAdmin && !data.me.isInstructor))
    ) {
      window.localStorage.setItem('authToken', '');
      navigate('/sign-in');
    }
  }, [loading, data, navigate]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
          height: '100vh',
          backgroundColor: '#fff',
        }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  if (data && data.me) {
    return <Component authenticatedUser={data.me} />;
  }

  return <div />;
}
