import React, { useState } from 'react';
import { instanceOf } from 'prop-types';

import Layout from '../Common/Layout';
import StudiosAndRooms from './StudiosAndRooms';
import Disciplines from './Disciplines';
import Users from './Users';
import Plans from './Plans';
import SessionTemplates from './SessionTemplates';

export default function Settings({ authenticatedUser }) {
  const [openTab, setOpenTab] = useState('studios-and-rooms');

  return (
    <Layout
      authenticatedUser={authenticatedUser}
      title="Configuración"
      code="settings"
      tabs={[
        { code: 'studios-and-rooms', name: 'Estudios y salas' },
        { code: 'disciplines', name: 'Disciplinas' },
        { code: 'users', name: 'Usuarios' },
        { code: 'plans', name: 'Planes y productos' },
        { code: 'session-templates', name: 'Calendario predeterminado' },
      ]}
      openTab={openTab}
      handleChangeOpenTab={(value) => setOpenTab(value)}
    >
      <>
        {openTab === 'studios-and-rooms' && (
          <StudiosAndRooms authenticatedUser={authenticatedUser} />
        )}

        {openTab === 'disciplines' && <Disciplines authenticatedUser={authenticatedUser} />}

        {openTab === 'users' && <Users authenticatedUser={authenticatedUser} />}

        {openTab === 'plans' && <Plans authenticatedUser={authenticatedUser} />}

        {openTab === 'session-templates' && (
          <SessionTemplates authenticatedUser={authenticatedUser} />
        )}
      </>
    </Layout>
  );
}

Settings.propTypes = {
  authenticatedUser: instanceOf(Object).isRequired,
};
