import React from 'react';
import { Card, Box, CardHeader, Icon, CardActions, Divider, Button } from '@mui/material';
import { instanceOf, func } from 'prop-types';

import { formatCurrency } from '../../../utils/formatCurrency';

export default function Plan({ plan, handleOpenPlan }) {
  return (
    <Card>
      <CardHeader title={plan.name} subheader={formatCurrency(plan.price)} />
      <Divider />
      <CardActions>
        <Button size="small" onClick={handleOpenPlan} variant="outlined">
          <Icon>edit</Icon>
          <Box sx={{ ml: 1 }}>Editar</Box>
        </Button>

        {/* TODO */}
        <Button size="small" variant="outlined" color="error" disabled>
          <Icon>delete</Icon>
          <Box sx={{ ml: 1 }}>Eliminar</Box>
        </Button>
      </CardActions>
    </Card>
  );
}

Plan.propTypes = {
  plan: instanceOf(Object).isRequired,
  handleOpenPlan: func.isRequired,
};
