import React from 'react';
import { Card, Box, CardHeader, Avatar, Icon, CardActions, Divider, Button } from '@mui/material';
import { instanceOf, func } from 'prop-types';

export default function Discipline({ discipline, handleOpenDiscipline }) {
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar src={discipline.icon} sx={{ backgroundColor: 'secondary.main' }}>
            {discipline.name.slice(0, 1).toUpperCase()}
          </Avatar>
        }
        title={discipline.name}
      />
      <Divider />
      <CardActions>
        <Button size="small" onClick={handleOpenDiscipline} variant="outlined">
          <Icon>edit</Icon>
          <Box sx={{ ml: 1 }}>Editar</Box>
        </Button>

        {/* TODO */}
        <Button size="small" variant="outlined" color="error" disabled>
          <Icon>delete</Icon>
          <Box sx={{ ml: 1 }}>Eliminar</Box>
        </Button>
      </CardActions>
    </Card>
  );
}

Discipline.propTypes = {
  discipline: instanceOf(Object).isRequired,
  handleOpenDiscipline: func.isRequired,
};
