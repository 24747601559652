import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Grid,
  Icon,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Link,
  CircularProgress,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';

export default function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [token, setToken] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenInput = params.get('token');
    setToken(tokenInput);
  }, [location.search]);

  const [resetPassword, { loading, error, data, client }] = useMutation(
    gql`
      mutation ResetPassword($resetPasswordToken: String!, $password: String!) {
        resetPassword(resetPasswordToken: $resetPasswordToken, password: $password)
      }
    `
  );

  useEffect(() => {
    if (data && data.resetPassword) {
      window.localStorage.setItem('authToken', data.resetPassword);
      client.resetStore();
      navigate('/');
    }
  }, [data, navigate, client]);

  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            'url(https://images.unsplash.com/photo-1571902943202-507ec2618e8f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1975&q=80)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
          }}
        >
          <Avatar sx={{ mb: 2, bgcolor: 'secondary.main', width: 80, height: 80 }}>
            <Icon fontSize="large">password</Icon>
          </Avatar>
          <Typography component="h1" variant="h5">
            Cambia tu contraseña
          </Typography>

          {error && (
            <Typography variant="body2" color="error" mt={4}>
              Algo salió mal
            </Typography>
          )}

          <Box sx={{ mt: 1, minWidth: 300 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Nueva contraseña"
              type="password"
              autoComplete="new-password"
              value={password || ''}
              onChangeCapture={(e) => setPassword(e.target.value)}
            />
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() =>
                resetPassword({
                  variables: {
                    resetPasswordToken: token,
                    password,
                  },
                })
              }
              disabled={loading || !password}
            >
              {loading ? <CircularProgress size={24} /> : 'Cambiar contraseña'}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/sign-in" variant="body2">
                  ¿Recordaste tu contraseña? Inicia sesión
                </Link>
              </Grid>
            </Grid>
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 5 }}>
              Copyright © StudioM 2022.
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
