import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  Divider,
} from '@mui/material';
import { instanceOf, func } from 'prop-types';
import { TimePicker } from '@mui/lab';
import moment from 'moment-timezone';

import UploadInput from '../Common/UploadInput';

export default function StudioDialog({ studio: studioInput, handleClose, handleUpdate }) {
  const [studio, setStudio] = useState({ ...studioInput });

  const [updateStudio, { loading: updatingStudio }] = useMutation(
    gql`
      mutation UpdateStudio($studioId: ID!, $input: StudioInput!) {
        updateStudio(studioId: $studioId, input: $input)
      }
    `,
    { update: handleUpdate }
  );

  const [createStudio, { loading: creatingStudio }] = useMutation(
    gql`
      mutation CreateStudio($input: StudioInput!) {
        createStudio(input: $input)
      }
    `,
    { update: handleUpdate }
  );

  const saving = updatingStudio || creatingStudio;

  return (
    <Dialog open fullWidth maxWidth="md" onClose={handleClose}>
      <DialogContent>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Nombre"
          value={studio.name || ''}
          onChangeCapture={(e) => setStudio({ ...studio, name: e.target.value })}
        />

        <UploadInput
          label="Logotipo"
          uniqueName="studio-logo"
          value={studio.logo || ''}
          onChange={(url) => setStudio({ ...studio, logo: url })}
        />

        <UploadInput
          label="Foto principal"
          uniqueName="studio-picture"
          value={studio.picture || ''}
          onChange={(url) => setStudio({ ...studio, picture: url })}
        />

        <Typography variant="subtitle2" color="primary" sx={{ mt: 4, mb: 1 }}>
          Dirección
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              margin="dense"
              required
              fullWidth
              label="Calle y número"
              value={studio.address.street || ''}
              onChangeCapture={(e) =>
                setStudio({
                  ...studio,
                  address: { ...studio.address, street: e.target.value },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              margin="dense"
              required
              fullWidth
              label="Colonia"
              value={studio.address.neighborhood || ''}
              onChangeCapture={(e) =>
                setStudio({
                  ...studio,
                  address: { ...studio.address, neighborhood: e.target.value },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              margin="dense"
              required
              fullWidth
              label="Municipio"
              value={studio.address.city || ''}
              onChangeCapture={(e) =>
                setStudio({
                  ...studio,
                  address: { ...studio.address, city: e.target.value },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              margin="dense"
              required
              fullWidth
              label="Estado"
              value={studio.address.state || ''}
              onChangeCapture={(e) =>
                setStudio({
                  ...studio,
                  address: { ...studio.address, state: e.target.value },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              margin="dense"
              required
              fullWidth
              label="Código postal"
              value={studio.address.zipcode || ''}
              onChangeCapture={(e) =>
                setStudio({
                  ...studio,
                  address: { ...studio.address, zipcode: e.target.value },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              margin="dense"
              required
              fullWidth
              label="País"
              value={studio.address.country || ''}
              onChangeCapture={(e) =>
                setStudio({
                  ...studio,
                  address: { ...studio.address, country: e.target.value },
                })
              }
            />
          </Grid>
        </Grid>

        <Typography variant="subtitle2" color="primary" sx={{ mt: 4, mb: 1 }}>
          Horarios
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TimePicker
              // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(props) => <TextField margin="normal" fullWidth {...props} />}
              label="Abre"
              value={moment(studio.opensAt)}
              onChange={(newValue) => {
                setStudio({ ...studio, opensAt: newValue.toDate().toISOString() });
              }}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TimePicker
              // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(props) => <TextField margin="normal" fullWidth {...props} />}
              label="Cierra"
              value={moment(studio.closesAt)}
              onChange={(newValue) => {
                setStudio({ ...studio, closesAt: newValue.toDate().toISOString() });
              }}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" size="small">
          <Icon>cancel</Icon>
          <Box sx={{ ml: 1 }}>Cerrar</Box>
        </Button>
        <Button
          onClick={() => {
            const input = {
              name: studio.name,
              picture: studio.picture,
              logo: studio.logo,
              address: {
                street: studio.address.street,
                neighborhood: studio.address.neighborhood,
                city: studio.address.city,
                state: studio.address.state,
                zipcode: studio.address.zipcode,
                country: studio.address.country,
              },
              opensAt: studio.opensAt,
              closesAt: studio.closesAt,
            };

            if (studio.id) {
              updateStudio({
                variables: {
                  studioId: studio.id,
                  input,
                },
              });
            } else {
              createStudio({
                variables: {
                  input,
                },
              });
            }
          }}
          disabled={
            saving ||
            !studio.name ||
            !studio.logo ||
            !studio.picture ||
            !studio.address.street ||
            !studio.address.neighborhood ||
            !studio.address.city ||
            !studio.address.state ||
            !studio.address.zipcode ||
            !studio.address.country ||
            !studio.opensAt ||
            !studio.closesAt
          }
          variant="contained"
          size="small"
        >
          {saving ? <CircularProgress size={24} /> : <Icon>save</Icon>}
          <Box sx={{ ml: 1 }}>Guardar</Box>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

StudioDialog.propTypes = {
  studio: instanceOf(Object),
  handleClose: func.isRequired,
  handleUpdate: func.isRequired,
};

StudioDialog.defaultProps = {
  studio: null,
};
