import React, { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  Button,
  IconButton,
  Paper,
  Card,
  CardActions,
  Popover,
  Typography,
  Grid,
} from '@mui/material';
import { StaticDatePicker } from '@mui/lab';
import { instanceOf } from 'prop-types';
import moment from 'moment-timezone';
import { formatCurrency } from '../../../utils/formatCurrency';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import Layout from '../Common/Layout';
import PaidBillDialog from './PaidBillDialog';
import BillsTable from './BillsTable';

export default function Bills({ authenticatedUser }) {
  const [date, setDate] = useState(moment());
  const [dateAnchorEl, setDateAnchorEl] = useState(null);
  const [openPaidBillDialog, setOpenPaidBillDialog] = useState(null);
  const [selectedStudio, setSelectedStudio] = useState(null);

  const { loading, data, refetch } = useQuery(
    gql`
      query GetBills($businessId: ID!, $since: String!, $until: String!) {
        studiosByBusiness(businessId: $businessId) {
          id
          name
        }
        plansByBusiness(businessId: $businessId) {
          id
          type
          name
          price
          currency
          requiresMembership
        }
        bills(since: $since, until: $until) {
          id
          studio {
            id
            name
          }
          customer {
            id
            name
          }
          createdBy {
            id
            name
          }
          items {
            id
            name
            quantity
            price
            currency
            exchangeRate
            subtotal
            total
            plan {
              type
            }
          }
          createdAt
          total
          totalPaid
          balance
          paymentStatus
          payments {
            id
            amount
            type
            ref
            proof
          }
          isCanceled
          canceledBy {
            id
            name
          }
          canceledAt
        }
      }
    `,
    {
      variables: {
        businessId: authenticatedUser.business.id,
        since: date.startOf('day').toDate().toISOString(),
        until: date.endOf('day').toDate().toISOString(),
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    refetch();
  }, [date, refetch]);

  useEffect(() => {
    if (!selectedStudio && data && data.studiosByBusiness && data.studiosByBusiness.length) {
      setSelectedStudio(data.studiosByBusiness[0]);
    }
  }, [selectedStudio, data]);

  const bills =
    data && data.bills && selectedStudio
      ? data.bills.filter((bill) => bill.studio.id === selectedStudio.id)
      : [];

  return (
    <Layout
      authenticatedUser={authenticatedUser}
      title="Caja"
      code="bills"
      tabs={
        data && data.studiosByBusiness
          ? data.studiosByBusiness.map((studio) => ({ code: studio.id, name: studio.name }))
          : []
      }
      openTab={selectedStudio ? selectedStudio.id : ''}
      handleChangeOpenTab={(value) =>
        setSelectedStudio(data.studiosByBusiness.find((studio) => studio.id === value))
      }
    >
      <>
        <Card sx={{ mb: 2 }}>
          <CardActions>
            <Button
              onClick={() => setOpenPaidBillDialog(true)}
              variant="contained"
              disabled={!data || !moment(date).isSame(moment(), 'date')}
            >
              <Icon>add</Icon>
              <Box sx={{ ml: 1 }}>Venta</Box>
            </Button>

            <Box sx={{ flex: 1 }} />

            <IconButton onClick={() => refetch()} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : <Icon>refresh</Icon>}
            </IconButton>

            {/* TODO */}
            <IconButton disabled>
              <Icon>help</Icon>
            </IconButton>
          </CardActions>

          {(authenticatedUser.isOwner || authenticatedUser.isAdmin) && (
            <CardActions sx={{ justifyContent: 'space-between' }}>
              <IconButton onClick={() => setDate(moment(date).subtract(1, 'day'))}>
                <Icon>chevron_left</Icon>
              </IconButton>

              <Button onClick={(e) => setDateAnchorEl(e.currentTarget)} color="inherit">
                <Icon>today</Icon>
                <Box sx={{ ml: 0.5 }}>{moment(date).format('YYYY-MM-DD')}</Box>
              </Button>

              <Popover
                open={Boolean(dateAnchorEl)}
                anchorEl={dateAnchorEl}
                onClose={() => setDateAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  value={date || ''}
                  onChange={(value) => setDate(value)}
                  renderInput={() => null}
                />
              </Popover>

              <IconButton onClick={() => setDate(moment(date).add(1, 'day'))}>
                <Icon>chevron_right</Icon>
              </IconButton>
            </CardActions>
          )}
        </Card>

        {(!data || !selectedStudio) && (
          <Paper
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 400 }}
          >
            <CircularProgress size={60} />
          </Paper>
        )}

        {data && data.bills && selectedStudio && (
          <>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              {[
                {
                  name: 'Ventas',
                  value: bills.filter((bill) => !bill.isCanceled).length,
                  type: 'number',
                },
                {
                  name: 'Total',
                  value: bills
                    .filter((bill) => !bill.isCanceled)
                    .reduce((total, bill) => total + bill.total, 0),
                  type: 'currency',
                },
                {
                  name: 'Efectivo',
                  value: bills
                    .filter((bill) => !bill.isCanceled)
                    .reduce(
                      (total, bill) =>
                        total +
                        bill.payments
                          .filter((payment) => payment.type === 'cash')
                          .reduce((subtotal, payment) => subtotal + payment.amount, 0),
                      0
                    ),
                  type: 'currency',
                },
                {
                  name: 'Tarjeta',
                  value: bills
                    .filter((bill) => !bill.isCanceled)
                    .reduce(
                      (total, bill) =>
                        total +
                        bill.payments
                          .filter((payment) => payment.type === 'card')
                          .reduce((subtotal, payment) => subtotal + payment.amount, 0),
                      0
                    ),
                  type: 'currency',
                },
                {
                  name: 'Transferencia',
                  value: bills
                    .filter((bill) => !bill.isCanceled)
                    .reduce(
                      (total, bill) =>
                        total +
                        bill.payments
                          .filter((payment) => payment.type === 'transfer')
                          .reduce((subtotal, payment) => subtotal + payment.amount, 0),
                      0
                    ),
                  type: 'currency',
                },
                {
                  name: 'Membresías',
                  value: bills.filter(
                    (bill) =>
                      !bill.isCanceled &&
                      bill.items.some((item) => item.plan && item.plan.type === 'membership')
                  ).length,
                  type: 'number',
                },
              ].map((stat) => (
                <Grid key={stat.name} item xs={12} sm={6} md={4} lg={2}>
                  <Paper sx={{ p: 1 }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {stat.name}
                    </Typography>
                    <Typography variant="h5" color="primary">
                      {stat.type === 'number' && stat.value}
                      {stat.type === 'currency' && formatCurrency(stat.value)}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            <Paper sx={{ overflow: 'hidden' }}>
              <BillsTable bills={bills} showCustomers size="small" handleUpdate={() => refetch()} />
            </Paper>
          </>
        )}

        {openPaidBillDialog && (
          <PaidBillDialog
            handleClose={() => setOpenPaidBillDialog(false)}
            handleUpdate={() => refetch()}
            plans={data.plansByBusiness}
            studioId={selectedStudio.id}
          />
        )}
      </>
    </Layout>
  );
}

Bills.propTypes = {
  authenticatedUser: instanceOf(Object).isRequired,
};
