import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
  Grid,
  Autocomplete,
  TextField,
} from '@mui/material';
import { instanceOf, func, string } from 'prop-types';
import { useSnackbar } from 'notistack';
import { DatePicker } from '@mui/lab';
import moment from 'moment';

import CustomerForm from './CustomerForm';
import { translateError } from '../../../utils/translateError';

export default function CustomerDialog({
  variant,
  customer: customerInput,
  handleClose,
  handleUpdate,
  plans,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [customer, setCustomer] = useState({ ...customerInput });

  const [updateCustomer, { loading: updatingCustomer, error: updateError }] = useMutation(
    gql`
      mutation UpdateCustomer($customerId: ID!, $input: UserInput!) {
        updateCustomerUser(userId: $customerId, input: $input)
      }
    `,
    { update: handleUpdate }
  );

  const [createCustomer, { loading: creatingCustomer, error: createError }] = useMutation(
    gql`
      mutation CreateCustomer($input: UserInput!) {
        createCustomerUser(input: $input)
      }
    `,
    { update: handleUpdate }
  );

  const [createPrevCustomer, { loading: creatingPrevCustomer, error: createPrevError }] =
    useMutation(
      gql`
        mutation CreatePrevCustomer(
          $input: UserInput!
          $membershipPlan: ID!
          $creditsUntil: String!
        ) {
          createPrevCustomerUser(
            input: $input
            membershipPlan: $membershipPlan
            creditsUntil: $creditsUntil
          )
        }
      `,
      { update: handleUpdate }
    );

  useEffect(() => {
    const error = updateError || createError || createPrevError;
    if (error) {
      enqueueSnackbar(translateError(error.message), { variant: 'error' });
    }
  }, [enqueueSnackbar, updateError, createError, createPrevError]);

  const saving = updatingCustomer || creatingCustomer || creatingPrevCustomer;

  return (
    <Dialog open fullWidth maxWidth="md" onClose={handleClose}>
      <DialogContent>
        <CustomerForm customer={customer} setCustomer={setCustomer} />

        {variant === 'prev' && (
          <>
            <Typography variant="subtitle2" color="primary" sx={{ mt: 4, mb: 1 }}>
              Membresía actual
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  label="Membresía"
                  options={plans}
                  renderInput={(params) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <TextField {...params} fullWidth margin="none" sx={{ minWidth: 150 }} />
                  )}
                  value={customer.membershipPlan}
                  onChange={(e, newValue) => {
                    setCustomer({
                      ...customer,
                      membershipPlan: newValue,
                    });
                  }}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DatePicker
                  label="Otorgar créditos hasta"
                  value={customer.creditsUntil ? moment(customer.creditsUntil) : null}
                  onChange={(newValue) => {
                    setCustomer({
                      ...customer,
                      creditsUntil:
                        newValue && newValue.isValid()
                          ? newValue.startOf('day').toDate().toISOString()
                          : null,
                    });
                  }}
                  renderInput={(params) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <TextField margin="none" fullWidth {...params} sx={{ minWidth: 150 }} />
                  )}
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" size="small">
          <Icon>cancel</Icon>
          <Box sx={{ ml: 1 }}>Cerrar</Box>
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            if (customer.id) {
              updateCustomer({
                variables: {
                  customerId: customer.id,
                  input: {
                    name: customer.name,
                    email: customer.email,
                    phone: customer.phone,
                    picture: customer.picture,
                    address: {
                      street: customer.address.street,
                      neighborhood: customer.address.neighborhood,
                      city: customer.address.city,
                      state: customer.address.state,
                      zipcode: customer.address.zipcode,
                      country: customer.address.country,
                    },
                    birthday: customer.birthday || null,
                  },
                },
              });
            } else if (variant === 'new') {
              createCustomer({
                variables: {
                  input: {
                    name: customer.name,
                    email: customer.email,
                    phone: customer.phone,
                    picture: customer.picture,
                    address: {
                      street: customer.address.street,
                      neighborhood: customer.address.neighborhood,
                      city: customer.address.city,
                      state: customer.address.state,
                      zipcode: customer.address.zipcode,
                      country: customer.address.country,
                    },
                    birthday: customer.birthday || null,
                  },
                },
              });
            } else {
              createPrevCustomer({
                variables: {
                  input: {
                    name: customer.name,
                    email: customer.email,
                    phone: customer.phone,
                    picture: customer.picture,
                    address: {
                      street: customer.address.street,
                      neighborhood: customer.address.neighborhood,
                      city: customer.address.city,
                      state: customer.address.state,
                      zipcode: customer.address.zipcode,
                      country: customer.address.country,
                    },
                    birthday: customer.birthday || null,
                  },
                  membershipPlan: customer.membershipPlan.id,
                  creditsUntil: customer.creditsUntil,
                },
              });
            }
          }}
          disabled={
            saving ||
            !customer.name ||
            (!customer.email && !customer.phone) ||
            (variant === 'prev' && (!customer.membershipPlan || !customer.creditsUntil))
          }
        >
          {saving ? <CircularProgress size={24} /> : <Icon>save</Icon>}
          <Box sx={{ ml: 1 }}>Guardar</Box>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CustomerDialog.propTypes = {
  variant: string,
  customer: instanceOf(Object),
  handleClose: func.isRequired,
  handleUpdate: func.isRequired,
  plans: instanceOf(Array),
};

CustomerDialog.defaultProps = {
  variant: 'new',
  customer: null,
  plans: [],
};
