import React from 'react';
import {
  Box,
  Icon,
  Button,
  Dialog,
  DialogActions,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { instanceOf, func } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import moment from 'moment-timezone';

import { formatCurrency } from '../../../utils/formatCurrency';
import { translatePaymentType } from '../../../utils/translatePaymentType';
import Confirm from '../Common/Confirm';

export default function BillDialog({ bill, handleClose, handleUpdate }) {
  const [cancelBill, { loading: cancelingBill }] = useMutation(
    gql`
      mutation CancelBill($billId: ID!) {
        cancelBill(billId: $billId)
      }
    `,
    {
      update: () => {
        handleUpdate();
        handleClose();
      },
    }
  );

  return (
    <Dialog open fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle>
        <div>Detalle de la venta</div>
        {bill.isCanceled && bill.canceledBy ? (
          <Typography variant="caption" color="error">{`Cancelada por ${
            bill.canceledBy.name
          } el ${moment(bill.canceledAt)
            .tz('America/Monterrey')
            .format('YYYY-MM-DD')} a las ${moment(bill.canceledAt)
            .tz('America/Monterrey')
            .format('hh:mm a')}.`}</Typography>
        ) : null}
      </DialogTitle>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Cantidad</TableCell>
            <TableCell>Concepto</TableCell>
            <TableCell align="right">Precio unitario</TableCell>
            <TableCell align="right">Subtotal</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bill.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell align="right">{formatCurrency(item.price)}</TableCell>
              <TableCell align="right">{formatCurrency(item.total)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell align="right">
              {formatCurrency(bill.items.reduce((total, item) => total + item.total, 0))}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>

      <DialogTitle>Pagos recibidos</DialogTitle>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tipo de pago</TableCell>
            <TableCell align="right">Cantidad</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bill.payments.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                {translatePaymentType(item.type)}

                {Boolean(item.proof) && (
                  <IconButton
                    size="small"
                    component="a"
                    href={item.proof}
                    target="_blank"
                    sx={{ ml: 1 }}
                  >
                    <Icon size="small">receipt_long</Icon>
                  </IconButton>
                )}
              </TableCell>
              <TableCell align="right">{formatCurrency(item.amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell />
            <TableCell align="right">
              {formatCurrency(bill.payments.reduce((total, payment) => total + payment.amount, 0))}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" size="small">
          <Icon>cancel</Icon>
          <Box sx={{ ml: 1 }}>Cerrar</Box>
        </Button>

        <Confirm
          component={Button}
          title="¿Estás seguro de que quieres cancelar esta venta?"
          description="Esto no afectará los créditos que hayan recibido los usuarios. Para revertir los paquetes de créditos otorgados estos deben eliminarse desde el perfil del cliente."
          verb="Cancelar"
          color="error"
          buttonColor="error"
          icon="delete"
          onConfirm={() => cancelBill({ variables: { billId: bill.id } })}
          disabled={bill.isDisabled || cancelingBill}
          variant="contained"
          size="small"
        >
          <>
            <Icon>delete</Icon>
            <Box sx={{ ml: 1 }}>Cancelar</Box>
          </>
        </Confirm>
      </DialogActions>
    </Dialog>
  );
}

BillDialog.propTypes = {
  bill: instanceOf(Object),
  handleClose: func.isRequired,
  handleUpdate: func.isRequired,
};

BillDialog.defaultProps = {
  bill: null,
};
