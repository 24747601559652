import React, { useRef, useState } from 'react';
import {
  Avatar,
  Grid,
  Icon,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Link,
  CircularProgress,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import ReCAPTCHA from 'react-google-recaptcha';

export default function RecoverPassword() {
  const [id, setId] = useState('');
  const recaptchaRef = useRef();

  const [recoverPassword, { loading, error, data }] = useMutation(
    gql`
      mutation RecoverPassword($id: String!, $recaptchaResponse: String!) {
        recoverPassword(id: $id, recaptchaResponse: $recaptchaResponse)
      }
    `
  );

  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            'url(https://images.unsplash.com/photo-1571902943202-507ec2618e8f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1975&q=80)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
          }}
        >
          <Avatar sx={{ mb: 2, bgcolor: 'secondary.main', width: 80, height: 80 }}>
            <Icon fontSize="large">{data ? 'mark_email_read' : 'password'}</Icon>
          </Avatar>

          <Typography component="h1" variant="h5">
            {data ? 'Revisa tus mensajes' : 'Recupera tu contraseña'}
          </Typography>

          {error && (
            <Typography variant="body2" color="error" mt={4}>
              Ocurrió un error inesperado
            </Typography>
          )}

          <Box sx={{ mt: 1, width: 300 }}>
            {data && (
              <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
                Te enviamos un link para que generes una nueva contraseña a tu correo electrónico.
              </Typography>
            )}

            {!data && (
              <>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Teléfono o correo electrónico"
                  autoFocus
                  autoComplete="email"
                  value={id || ''}
                  onChangeCapture={(e) => setId(e.target.value)}
                />
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={process.env.RECAPTCHA_SITE_KEY}
                />
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={async () => {
                    const recaptchaResponse = await recaptchaRef.current.executeAsync();
                    if (recaptchaResponse) {
                      recoverPassword({ variables: { id, recaptchaResponse } });
                      recaptchaRef.current.reset();
                    }
                  }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Recuperar contraseña'}
                </Button>
              </>
            )}
            <Grid container>
              <Grid item xs>
                <Link href="/sign-in" variant="body2">
                  Regresar
                </Link>
              </Grid>
            </Grid>
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 5 }}>
              Copyright © StudioM 2022.
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
