import React from 'react';

import { Box, Icon, Typography } from '@mui/material';
import { instanceOf, string, bool } from 'prop-types';

export default function CalendarEvent({ event, variant, showInstructor }) {
  const { resource: session } = event;

  const settings =
    variant === 'calendar'
      ? {
          iconColor: 'rgba(255, 255, 255, .5)',
          info: ['instructor', 'reservations', 'room', 'discipline'],
          textVariant: 'caption',
          rowMt: 0.7,
        }
      : {
          iconColor: 'rgba(0, 0, 0, .5)',
          info: ['instructor', 'reservations', 'room', 'discipline', 'duration'],
          textVariant: 'body2',
          rowMt: 1,
        };

  return (
    <>
      {settings.info.includes('duration') && (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: settings.rowMt }}>
          <Icon sx={{ fontSize: 15, mr: 0.5, color: settings.iconColor }}>schedule</Icon>
          <Typography
            variant={settings.textVariant}
            sx={{
              lineHeight: 'initial',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {`${session.duration} mins.`}
          </Typography>
        </Box>
      )}

      {settings.info.includes('instructor') && showInstructor && (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: settings.rowMt }}>
          <Icon sx={{ fontSize: 15, mr: 0.5, color: settings.iconColor }}>account_circle</Icon>
          <Typography
            variant={settings.textVariant}
            sx={{
              lineHeight: 'initial',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Box
              sx={
                (!session.instructor && session.actualInstructor) ||
                (session.instructor &&
                  session.actualInstructor &&
                  session.instructor.id !== session.actualInstructor.id)
                  ? { textDecoration: 'line-through' }
                  : {}
              }
            >
              {session.instructor ? session.instructor.name : 'No asignada'}
            </Box>

            {session.actualInstructor &&
            (!session.instructor || session.instructor.id !== session.actualInstructor.id)
              ? session.actualInstructor.name
              : ''}
          </Typography>
        </Box>
      )}

      {settings.info.includes('reservations') && (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: settings.rowMt }}>
          <Icon sx={{ fontSize: 15, mr: 0.5, color: settings.iconColor }}>people</Icon>
          <Typography
            variant={settings.textVariant}
            sx={{
              lineHeight: 'initial',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {`${session.reservationsCount} / ${session.seats}${
              session.status === 'successful' ? ` (asist. ${session.attendanceCount})` : ''
            }`}
          </Typography>
        </Box>
      )}

      {settings.info.includes('room') && (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: settings.rowMt }}>
          <Icon sx={{ fontSize: 15, mr: 0.5, color: settings.iconColor }}>meeting_room</Icon>
          <Typography
            variant={settings.textVariant}
            sx={{
              lineHeight: 'initial',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {session.room.name}
          </Typography>
        </Box>
      )}

      {settings.info.includes('discipline') && (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: settings.rowMt }}>
          <Icon sx={{ fontSize: 15, mr: 0.5, color: settings.iconColor }}>sports_gymnastics</Icon>
          <Typography
            variant={settings.textVariant}
            sx={{
              lineHeight: 'initial',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {session.discipline.name}
          </Typography>
        </Box>
      )}
    </>
  );
}

CalendarEvent.propTypes = {
  event: instanceOf(Object).isRequired,
  variant: string,
  showInstructor: bool,
};

CalendarEvent.defaultProps = {
  variant: 'calendar',
  showInstructor: true,
};
