import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { CircularProgress, Box, Icon, Button, IconButton, Toolbar } from '@mui/material';
import { string, func } from 'prop-types';

import NewReservation from './NewReservation';
import ReservationsList from './ReservationsList';

export default function Reservations({ sessionId, handleUpdate }) {
  const [newReservation, setNewReservation] = useState(false);

  const { loading, data, refetch } = useQuery(
    gql`
      query GetSessionReservations($sessionId: ID!) {
        session(sessionId: $sessionId) {
          id
          startsAt
          duration
          reservationsCount
          seats
          status
          reservations {
            id
            user {
              id
              name
            }
            status
            seat
            createdAt
            session {
              startsAt
              duration
            }
          }
          availableSeats
        }
      }
    `,
    { variables: { sessionId }, notifyOnNetworkStatusChange: true }
  );

  const activeReservations = data
    ? data.session.reservations.filter(
        (reservation) =>
          !['canceled', 'canceled-last-minute', 'canceled-by-business'].includes(reservation.status)
      )
    : [];

  const canceledReservations = data
    ? data.session.reservations.filter((reservation) =>
        ['canceled', 'canceled-last-minute', 'canceled-by-business'].includes(reservation.status)
      )
    : [];

  return (
    <>
      <Toolbar sx={{ px: '16px !important' }}>
        <Button
          onClick={() => setNewReservation(true)}
          variant="contained"
          size="small"
          disabled={!data || !['upcoming', 'successful'].includes(data.session.status)}
        >
          <Icon>add</Icon>
          <Box sx={{ ml: 1 }}>Reservación</Box>
        </Button>
        <Box sx={{ flex: 1 }} />
        <IconButton onClick={() => refetch()} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : <Icon>refresh</Icon>}
        </IconButton>

        {/* TODO */}
        <IconButton size="small" disabled>
          <Icon>help</Icon>
        </IconButton>
      </Toolbar>

      {newReservation && (
        <NewReservation
          sessionId={sessionId}
          availableSeats={data.session.availableSeats}
          handleClose={() => setNewReservation(false)}
          handleUpdate={() => {
            setNewReservation(false);
            refetch();
            handleUpdate();
          }}
        />
      )}

      {!data && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 200 }}
        >
          <CircularProgress size={60} />
        </Box>
      )}

      {data && (
        <>
          <ReservationsList
            title={`Activas (${activeReservations.length}/${data.session.seats})`}
            reservations={activeReservations}
            emptyMessage="No hay reservaciones activas."
            handleUpdate={() => {
              refetch();
              handleUpdate();
            }}
          />

          <ReservationsList
            title={`Canceladas (${canceledReservations.length})`}
            reservations={canceledReservations}
            emptyMessage="No hay reservaciones canceladas."
            handleUpdate={() => {
              refetch();
              handleUpdate();
            }}
          />
        </>
      )}
    </>
  );
}

Reservations.propTypes = {
  sessionId: string.isRequired,
  handleUpdate: func.isRequired,
};
