import React, { useState, useEffect } from 'react';
import { instanceOf, func, string, bool } from 'prop-types';
import { gql, useLazyQuery } from '@apollo/client';
import {
  Autocomplete,
  Avatar,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from '@mui/material';

export default function CustomersAutocomplete({ value, onChange, autoFocus, ...props }) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const [fetchCustomerUsers, { loading, data }] = useLazyQuery(
    gql`
      query GetCustomerUsersForAutocomplete($search: String) {
        customerUsers(search: $search) {
          id
          email
          phone
          name
          picture
          lastValidationPictures
          membership {
            isActive
          }
          packs {
            borrowedCredits
          }
        }
      }
    `,
    { variables: { search: inputValue }, notifyOnNetworkStatusChange: true }
  );

  useEffect(() => {
    if (inputValue && inputValue.length >= 3) {
      fetchCustomerUsers({ variables: { search: inputValue } });
    } else {
      setOptions([]);
    }
  }, [fetchCustomerUsers, inputValue]);

  useEffect(() => {
    if (data && data.customerUsers) {
      setOptions(data.customerUsers);
    }
  }, [data]);

  return (
    <Autocomplete
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={value}
      onChange={(e, newValue) => {
        onChange(newValue);
        setOpen(false);
      }}
      onInputChange={(e) => (e && e.target ? setInputValue(e.target.value) : '')}
      options={options}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          autoFocus={autoFocus}
          label={props.label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(optionProps, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ListItem {...optionProps} dense>
          <ListItemAvatar>
            <Avatar src={option.picture} sx={{ backgroundColor: 'secondary.main' }}>
              {option.name.slice(0, 1).toUpperCase()}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={option.name}
            secondary={[option.phone, option.email].filter((item) => item).join(' · ')}
          />
        </ListItem>
      )}
      filterOptions={(items) => items}
    />
  );
}

CustomersAutocomplete.propTypes = {
  value: instanceOf(Object),
  onChange: func.isRequired,
  label: string,
  autoFocus: bool,
};

CustomersAutocomplete.defaultProps = {
  value: null,
  label: 'Cliente',
  autoFocus: false,
};
