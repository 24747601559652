import React, { useContext, useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import {
  Box,
  Icon,
  Grid,
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Divider,
  Badge,
  AppBar,
  Toolbar,
  InputBase,
  ListSubheader,
  useMediaQuery,
  useTheme,
  Grow,
} from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import { useParams, Link, useNavigate } from 'react-router-dom';

import Contact from './Contact';
import MessageStatusIndicator from './MessageStatusIndicator';
import { MainContainerHeightContext } from '../Common/MainContainerHeightContext';

export default function Inbox() {
  const [search, setSearch] = useState('');
  const [tmpSearch, setTmpSearch] = useState('');
  const { id: openContact } = useParams();
  const mainContainerHeight = useContext(MainContainerHeightContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data, refetch } = useQuery(
    gql`
      query GetContacts($search: String) {
        contacts(search: $search) {
          id
          user {
            id
            picture
          }
          contactInfo {
            type
            value
            disabled
          }
          unreadIncomingMessagesCount
          lastMessage {
            id
            direction
            text
            attachments {
              url
              mimeType
              originalFilename
            }
            status
            createdAt
          }
        }
        customerUsers(search: $search) {
          id
          picture
          name
        }
      }
    `,
    { variables: { search }, notifyOnNetworkStatusChange: true, pollInterval: 20000 }
  );

  const [createContact, { loading: creatingContact }] = useMutation(
    gql`
      mutation CreateContact($input: ContactInput!) {
        createContact(input: $input)
      }
    `,
    {
      onCompleted: (createdContactData) => {
        setSearch('');
        navigate(`/crm/inbox/${createdContactData.createContact}`);
        refetch();
      },
    }
  );

  return (
    <Paper sx={{ height: `${mainContainerHeight}px` }}>
      <Grid container sx={{ height: '100%' }}>
        {(!openContact || !isMobile) && (
          <Grid
            item
            xs={12}
            md={3}
            sx={{ height: '100%', borderRight: '1px dashed rgba(145, 158, 171, 0.24)' }}
          >
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <AppBar
                position="static"
                color="transparent"
                sx={{ position: 'relative', zIndex: 1 }}
                elevation={0}
              >
                <Toolbar sx={{ px: 1 }} disableGutters>
                  <IconButton
                    disabled={!tmpSearch}
                    onClick={() => {
                      setTmpSearch('');
                      setSearch('');
                    }}
                  >
                    <Icon>{tmpSearch ? 'arrow_back' : 'search'}</Icon>
                  </IconButton>
                  <InputBase
                    sx={{ flex: 1 }}
                    placeholder="Busca por nombre, correo o teléfono"
                    value={tmpSearch || ''}
                    onChange={(e) => setTmpSearch(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        setSearch(tmpSearch);
                      }
                    }}
                  />
                </Toolbar>
              </AppBar>
              <Divider />

              <Scrollbars style={{ height: '100%', flex: 1 }}>
                <List>
                  {data &&
                    data.contacts &&
                    data.contacts.map((contact) => {
                      const name = contact.contactInfo.find((item) => item.type === 'name');
                      const email = contact.contactInfo.find((item) => item.type === 'email');
                      const phone = contact.contactInfo.find((item) => item.type === 'phone');

                      let timestamp = null;
                      if (contact.lastMessage) {
                        const diff = Math.abs(
                          moment(contact.lastMessage.createdAt)
                            .startOf('day')
                            .diff(moment().startOf('day'), 'day')
                        );

                        switch (diff) {
                          case 0:
                            timestamp = moment(contact.lastMessage.createdAt).format('HH:mm');
                            break;

                          case 1:
                            timestamp = 'Ayer';
                            break;

                          case 2:
                            timestamp = 'Antier';
                            break;

                          default:
                            timestamp = moment(contact.lastMessage.createdAt).format('DD/MM');
                            break;
                        }
                      }

                      return (
                        <>
                          <ListItem
                            key={contact.id}
                            button
                            component={Link}
                            to={
                              openContact === contact.id ? '/crm/inbox' : `/crm/inbox/${contact.id}`
                            }
                            disablePadding
                            secondaryAction={
                              Boolean(timestamp) && (
                                <Typography variant="caption" color="textSecondary">
                                  {timestamp}
                                </Typography>
                              )
                            }
                            sx={{
                              borderRadius: 0,
                              ...(openContact === contact.id
                                ? { backgroundColor: 'rgba(0,0,0,0.1)' }
                                : {}),
                            }}
                          >
                            <ListItemAvatar>
                              <Badge
                                color="error"
                                badgeContent={contact.unreadIncomingMessagesCount}
                              >
                                <Avatar
                                  src={contact.user ? contact.user.picture : null}
                                  sx={{ backgroundColor: 'secondary.main' }}
                                >
                                  {name ? (
                                    name.value.slice(0, 1).toUpperCase()
                                  ) : (
                                    <Icon fontSize="large">person</Icon>
                                  )}
                                </Avatar>
                              </Badge>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                (name && name.value) ||
                                (email && email.value) ||
                                (phone && phone.value) ||
                                '?'
                              }
                              secondary={
                                <>
                                  {contact.lastMessage &&
                                    contact.lastMessage.direction === 'out' && (
                                      <MessageStatusIndicator
                                        status={contact.lastMessage.status}
                                        position="left"
                                      />
                                    )}

                                  {contact.lastMessage &&
                                    [
                                      contact.lastMessage.attachments.length
                                        ? (contact.lastMessage.attachments[0].mimeType.includes(
                                            'image'
                                          ) &&
                                            '🖼️') ||
                                          (contact.lastMessage.attachments[0].mimeType.includes(
                                            'video'
                                          ) &&
                                            '🎞️') ||
                                          (contact.lastMessage.attachments[0].mimeType.includes(
                                            'audio'
                                          ) &&
                                            '🎤') ||
                                          (contact.lastMessage.attachments[0].mimeType.includes(
                                            'document'
                                          ) &&
                                            '📎')
                                        : null,
                                      contact.lastMessage.text &&
                                      contact.lastMessage.text.length > 20
                                        ? `${contact.lastMessage.text.slice(0, 20).trim()}...`
                                        : contact.lastMessage.text,
                                    ]
                                      .filter((item) => item)
                                      .join(' ')}
                                </>
                              }
                            />
                          </ListItem>
                          <Divider />
                        </>
                      );
                    })}

                  {search && data && data.customerUsers && data.contacts && (
                    <>
                      {data.customerUsers.length > 0 && (
                        <ListSubheader>Otros clientes</ListSubheader>
                      )}
                      {data.customerUsers
                        .filter(
                          (customer) =>
                            !data.contacts.some(
                              (contact) => contact.user && contact.user.id === customer.id
                            )
                        )
                        .map((customer) => (
                          <>
                            <ListItem
                              key={customer.id}
                              button
                              disablePadding
                              disabled={creatingContact}
                              onClick={() =>
                                createContact({
                                  variables: { input: { user: customer.id, contactInfo: [] } },
                                })
                              }
                            >
                              <ListItemAvatar>
                                <Avatar
                                  src={customer.picture}
                                  sx={{ backgroundColor: 'secondary.main' }}
                                >
                                  {customer.name ? (
                                    customer.name.slice(0, 1).toUpperCase()
                                  ) : (
                                    <Icon fontSize="large">person</Icon>
                                  )}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={customer.name || customer.email || customer.phone}
                              />
                            </ListItem>
                            <Divider />
                          </>
                        ))}
                    </>
                  )}
                </List>
              </Scrollbars>
            </Box>
          </Grid>
        )}

        {openContact && (
          <Grow in appear={isMobile}>
            <Grid item xs={12} md={9} sx={{ height: '100%' }}>
              <Contact id={openContact} handleUpdate={() => refetch()} />
            </Grid>
          </Grow>
        )}
      </Grid>
    </Paper>
  );
}
