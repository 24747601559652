import React, { useState } from 'react';
import {
  Card,
  Box,
  CardHeader,
  Avatar,
  CardMedia,
  IconButton,
  Icon,
  List,
  ListItem,
  CardActions,
  ListItemText,
  Divider,
  ListSubheader,
  Button,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
} from '@mui/material';
import { instanceOf, func } from 'prop-types';

import RoomDialog from './RoomDialog';

export default function Studio({ studio, handleOpenStudio, handleUpdate }) {
  const [openRoom, setOpenRoom] = useState(null);
  const [menuRoom, setMenuRoom] = useState(null);
  const [roomAnchorEl, setRoomAnchorEl] = useState(null);

  return (
    <>
      <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <CardHeader
          avatar={<Avatar src={studio.logo} sx={{ backgroundColor: 'secondary.main' }} />}
          title={studio.name}
          subheader={[studio.address.street, studio.address.neighborhood].join(', ')}
        />
        <CardMedia component="img" height="194" image={studio.picture} alt={studio.name} />
        <List
          dense
          subheader={
            <ListSubheader sx={{ display: 'flex', alignItems: 'center', pr: 0.5 }}>
              Salas
              <Box sx={{ flex: 1 }} />
              <Tooltip title="Agregar sala">
                <IconButton
                  onClick={() =>
                    setOpenRoom({
                      id: null,
                      name: '',
                      defaultSeats: null,
                      studio: { id: studio.id },
                    })
                  }
                >
                  <Icon>add</Icon>
                </IconButton>
              </Tooltip>
            </ListSubheader>
          }
          sx={{ flex: 1, maxHeight: 200, overflowY: 'auto' }}
        >
          {studio.rooms.map((room) => (
            <div key={room.id}>
              <Divider />
              <ListItem
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={(e) => {
                      setRoomAnchorEl(e.currentTarget);
                      setMenuRoom(room);
                    }}
                  >
                    <Icon>more_vert</Icon>
                  </IconButton>
                }
              >
                <ListItemText primary={room.name} />
              </ListItem>
            </div>
          ))}
        </List>
        <Divider />
        <CardActions>
          <Button size="small" onClick={handleOpenStudio} variant="outlined">
            <Icon>edit</Icon>
            <Box sx={{ ml: 1 }}>Editar</Box>
          </Button>

          {/* TODO */}
          <Button size="small" variant="outlined" color="error" disabled>
            <Icon>delete</Icon>
            <Box sx={{ ml: 1 }}>Eliminar</Box>
          </Button>
        </CardActions>
      </Card>

      <Menu
        anchorEl={roomAnchorEl}
        open={Boolean(roomAnchorEl)}
        onClose={() => setRoomAnchorEl(null)}
        onClick={() => setRoomAnchorEl(null)}
      >
        <MenuItem onClick={() => setOpenRoom(menuRoom)}>
          <ListItemIcon>
            <Icon>edit</Icon>
          </ListItemIcon>
          Editar sala
        </MenuItem>

        {/* TODO */}
        <MenuItem onClick={() => setOpenRoom(menuRoom)} disabled>
          <ListItemIcon>
            <Icon>delete</Icon>
          </ListItemIcon>
          Eliminar sala
        </MenuItem>
      </Menu>

      {openRoom && (
        <RoomDialog
          room={openRoom}
          handleClose={() => setOpenRoom(null)}
          handleUpdate={() => {
            setOpenRoom(null);
            handleUpdate();
          }}
        />
      )}
    </>
  );
}

Studio.propTypes = {
  studio: instanceOf(Object).isRequired,
  handleOpenStudio: func.isRequired,
  handleUpdate: func.isRequired,
};
