import React from 'react';
import { instanceOf } from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';

import Layout from '../Common/Layout';
import Inbox from './Inbox';

export default function Settings({ authenticatedUser }) {
  const { openTab } = useParams();
  const navigate = useNavigate();

  return (
    <Layout
      authenticatedUser={authenticatedUser}
      title="CRM"
      code="crm"
      tabs={[{ code: 'inbox', name: 'Inbox' }]}
      openTab={openTab}
      handleChangeOpenTab={(value) => navigate(`/crm/${value}`)}
      maxWidth="xl"
      fullWidth={openTab === 'inbox'}
    >
      {openTab === 'inbox' && <Inbox authenticatedUser={authenticatedUser} />}
    </Layout>
  );
}

Settings.propTypes = {
  authenticatedUser: instanceOf(Object).isRequired,
};
