import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Divider,
} from '@mui/material';
import { instanceOf, func } from 'prop-types';

export default function RoomDialog({ room: roomInput, handleClose, handleUpdate }) {
  const [room, setRoom] = useState({ ...roomInput });

  const [updateRoom, { loading: updatingRoom }] = useMutation(
    gql`
      mutation UpdateRoom($roomId: ID!, $input: RoomInput!) {
        updateRoom(roomId: $roomId, input: $input)
      }
    `,
    { update: handleUpdate }
  );

  const [createRoom, { loading: creatingRoom }] = useMutation(
    gql`
      mutation CreateRoom($input: RoomInput!) {
        createRoom(input: $input)
      }
    `,
    { update: handleUpdate }
  );

  const saving = updatingRoom || creatingRoom;

  return (
    <Dialog open fullWidth maxWidth="md" onClose={handleClose}>
      <DialogContent>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Nombre"
          value={room.name || ''}
          onChangeCapture={(e) => setRoom({ ...room, name: e.target.value })}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          label="Capacidad (default)"
          value={room.defaultSeats || ''}
          onChangeCapture={(e) =>
            setRoom({
              ...room,
              defaultSeats: e.target.value ? Number.parseInt(e.target.value, 10) : null,
            })
          }
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" size="small">
          <Icon>cancel</Icon>
          <Box sx={{ ml: 1 }}>Cerrar</Box>
        </Button>
        <Button
          onClick={() => {
            const input = {
              studio: room.studio.id,
              name: room.name,
              defaultSeats: room.defaultSeats,
            };

            if (room.id) {
              updateRoom({
                variables: {
                  roomId: room.id,
                  input,
                },
              });
            } else {
              createRoom({
                variables: {
                  input,
                },
              });
            }
          }}
          disabled={saving || !room.name}
          variant="contained"
          size="small"
        >
          {saving ? <CircularProgress size={24} /> : <Icon>save</Icon>}
          <Box sx={{ ml: 1 }}>Guardar</Box>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RoomDialog.propTypes = {
  room: instanceOf(Object),
  handleClose: func.isRequired,
  handleUpdate: func.isRequired,
};

RoomDialog.defaultProps = {
  room: null,
};
