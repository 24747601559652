import React, { useMemo, useState } from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  ButtonBase,
  Chip,
  Divider,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Skeleton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { instanceOf } from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { translate } from '../../../utils/translate';
import { customersColumns } from '../../../utils/customersColumns';

export default function ContactDetails({ contact }) {
  const [openDetails, setOpenDetails] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { names, emails, phones } = useMemo(() => {
    const result = { names: [], emails: [], phones: [] };

    if (contact && contact.contactInfo) {
      for (const contactInfo of contact.contactInfo) {
        result[`${contactInfo.type}s`] = [...result[`${contactInfo.type}s`], contactInfo.value];
      }
    }

    return result;
  }, [contact]);

  return (
    <>
      <AppBar
        position="static"
        sx={{ position: 'relative', zIndex: 1 }}
        color="transparent"
        elevation={0}
        disabled={!contact}
      >
        <Toolbar
          component={ButtonBase}
          onClick={() => setOpenDetails(true)}
          sx={{ textAlign: 'left', justifyContent: 'flex-start' }}
          disableGutters={isMobile}
        >
          {isMobile && (
            <IconButton component={Link} to="/crm/inbox" onClick={(e) => e.stopPropagation()}>
              <Icon>arrow_back</Icon>
            </IconButton>
          )}
          <Avatar
            src={contact && contact.user ? contact.user.picture : null}
            sx={{ backgroundColor: 'secondary.main' }}
          >
            {names.legth ? (
              names[0].slice(0, 1).toUpperCase()
            ) : (
              <Icon fontSize="large">person</Icon>
            )}
          </Avatar>
          <Box sx={{ ml: 1 }}>
            <Typography>
              {names[0] ||
                emails[0] ||
                phones[0] ||
                (contact ? (
                  'Sin datos de contacto'
                ) : (
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                ))}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Ver más información
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer open={openDetails} anchor="right" onClose={() => setOpenDetails(false)}>
        {contact && (
          <Box sx={{ width: '300px' }}>
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2, mt: 2 }}
            >
              <Avatar
                src={contact && contact.user ? contact.user.picture : null}
                sx={{ backgroundColor: 'secondary.main', width: '180px', height: '180px' }}
              >
                {names.legth ? (
                  names[0].slice(0, 1).toUpperCase()
                ) : (
                  <Icon fontSize="large">person</Icon>
                )}
              </Avatar>
            </Box>
            <List dense subheader={<ListSubheader>Información de contacto</ListSubheader>}>
              {contact.contactInfo.map((contactInfo, idx) => (
                <>
                  {idx !== 0 && <Divider />}
                  <ListItem key={contactInfo.value}>
                    <ListItemText
                      primary={contactInfo.value}
                      secondary={translate(contactInfo.type)}
                    />
                  </ListItem>
                </>
              ))}
            </List>

            <List dense subheader={<ListSubheader>Información de cliente</ListSubheader>}>
              <Box sx={{ px: 2 }}>
                {!contact.user && (
                  <Chip
                    variant="filled"
                    color="error"
                    size="small"
                    label="No es cliente"
                    sx={{ mr: 1, mt: 1 }}
                  />
                )}

                {contact.user && (
                  <>
                    <Chip
                      variant="filled"
                      color={contact.user.isRegistered ? 'success' : 'error'}
                      size="small"
                      label={contact.user.isRegistered ? 'Registrado' : 'No registrado'}
                      sx={{ mr: 1, mt: 1 }}
                    />

                    {contact.user.membership.isActive && (
                      <Chip
                        variant="filled"
                        color="success"
                        size="small"
                        label="Membresía activa"
                        sx={{ mr: 1, mt: 1 }}
                      />
                    )}

                    {!contact.user.membership.isActive && contact.user.membership.isExpired && (
                      <Chip
                        variant="filled"
                        color="warning"
                        size="small"
                        label="Membresía expirada"
                        sx={{ mr: 1, mt: 1 }}
                      />
                    )}

                    {!contact.user.membership.isActive && !contact.user.membership.isExpired && (
                      <Chip
                        variant="filled"
                        color="error"
                        size="small"
                        label="Sin membresía"
                        sx={{ mr: 1, mt: 1 }}
                      />
                    )}
                  </>
                )}
              </Box>

              {contact.user &&
                customersColumns
                  .filter((column) => column.showInContact)
                  .map((column, idx) => {
                    let value = column.path.split('.').reduce((prev, curr) => {
                      return prev ? prev[curr] : null;
                    }, contact.user);

                    if (column.type === 'date' && value) {
                      value = moment(value).format('YYYY-MM-DD');
                    } else if (column.type === 'boolean') {
                      value = value ? 'Si' : 'No';
                    }

                    return (
                      <>
                        {idx !== 0 && <Divider />}
                        <ListItem key={column}>
                          <ListItemText primary={value || 'N/A'} secondary={column.name} />
                        </ListItem>
                      </>
                    );
                  })}

              {contact.user && (
                <Box sx={{ p: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/customers/${contact.user.id}`}
                    fullWidth
                  >
                    Ir al cliente
                  </Button>
                </Box>
              )}
            </List>
          </Box>
        )}
      </Drawer>
    </>
  );
}

ContactDetails.propTypes = {
  contact: instanceOf(Object),
};

ContactDetails.defaultProps = {
  contact: null,
};
