import React, { Fragment, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  Button,
  IconButton,
  Paper,
  TextField,
  CardActions,
  Card,
  Avatar,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Tooltip,
  Menu,
  MenuItem,
  MenuList,
  ListItemText,
  ListItemIcon,
  Link as LinkEl,
  TableSortLabel,
} from '@mui/material';
import { instanceOf } from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';

import Layout from '../Common/Layout';
import CustomerDialog from './CustomerDialog';
import { useLocalStorage } from '../../../utils/useLocalStorage';
import { customersColumns } from '../../../utils/customersColumns';

export default function Customers({ authenticatedUser }) {
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [tmpSearch, setTmpSearch] = useState('');
  const [openCustomer, setOpenCustomer] = useState(null);
  const [selectedColumns, setSelectedColumns] = useLocalStorage('customers-columns', [
    'picture',
    'name',
    'phone',
    'email',
    'createdAt',
    'lastBill.studio',
  ]);
  const [columnsAnchorEl, setColumnsAnchorEl] = useState(null);
  const navigate = useNavigate();

  const pageNumber = Number.parseInt(searchParams.get('page') || '0', 10);
  const pageSize = Number.parseInt(searchParams.get('pageSize') || '20', 10);
  const sortField = searchParams.get('sortField') || '_id';
  const sortDirection = searchParams.get('sortDirection') || 'desc';

  const { loading, data, refetch } = useQuery(
    gql`
      query GetCustomerUsers(
        $businessId: ID!
        $search: String
        $pageNumber: Int
        $pageSize: Int
        $sortField: String
        $sortDirection: String
      ) {
        plansByBusiness(businessId: $businessId) {
          id
          type
          name
        }
        customerUsersCount(search: $search)
        customerUsers(
          search: $search
          pageNumber: $pageNumber
          pageSize: $pageSize
          sortField: $sortField
          sortDirection: $sortDirection
        ) {
          id
          email
          phone
          name
          picture
          address {
            street
            neighborhood
            city
            state
            zipcode
            country
          }
          isOwner
          isAdmin
          isInstructor
          membership {
            isActive
            isExpired
          }
          birthday
          createdAt
          hadBorrowedPack
          lastBill {
            studio {
              name
            }
            createdAt
          }
          lastBillWithPackPlan {
            studio {
              name
            }
            createdAt
          }
          lastSuccessfulReservation {
            session {
              room {
                studio {
                  name
                }
              }
              startsAt
            }
          }
          sort {
            lastBillWithPackPlan {
              plan
              expiresAt
            }
          }
        }
      }
    `,
    {
      variables: {
        search,
        pageSize,
        pageNumber,
        businessId: authenticatedUser.business.id,
        sortField,
        sortDirection,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  return (
    <Layout
      authenticatedUser={authenticatedUser}
      title="Clientes"
      code="customers"
      maxWidth={selectedColumns.length > 6 ? 'xl' : 'lg'}
    >
      <>
        <Card sx={{ mb: 2 }}>
          <CardActions>
            <Button
              onClick={() =>
                setOpenCustomer({
                  id: null,
                  variant: 'new',
                  email: '',
                  phone: '',
                  name: '',
                  picture: '',
                  address: {
                    street: '',
                    neighborhood: '',
                    city: '',
                    state: '',
                    zipcode: '',
                    country: '',
                  },
                  isOwner: false,
                  isAdmin: false,
                  isInstructor: false,
                  birthday: '',
                })
              }
              size="small"
              variant="contained"
            >
              <Icon>add</Icon>
              <Box sx={{ ml: 1 }}>Cliente</Box>
            </Button>

            <Tooltip title="Cliente existente">
              <IconButton
                onClick={() => {
                  const plans = data.plansByBusiness.filter((plan) => plan.type === 'membership');

                  setOpenCustomer({
                    id: null,
                    variant: 'prev',
                    email: '',
                    phone: '',
                    name: '',
                    picture: '',
                    address: {
                      street: '',
                      neighborhood: '',
                      city: '',
                      state: '',
                      zipcode: '',
                      country: '',
                    },
                    isOwner: false,
                    isAdmin: false,
                    isInstructor: false,
                    birthday: '',
                    creditsUntil: null,
                    membershipPlan: plans.length === 1 ? plans[0] : null,
                  });
                }}
                size="small"
                variant="outlined"
              >
                <Icon>history</Icon>
              </IconButton>
            </Tooltip>

            <Box sx={{ flex: 1 }} />

            <IconButton onClick={(e) => setColumnsAnchorEl(e.currentTarget)}>
              <Icon>settings</Icon>
            </IconButton>

            <IconButton onClick={() => refetch()} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : <Icon>refresh</Icon>}
            </IconButton>

            {/* TODO */}
            <IconButton disabled>
              <Icon>help</Icon>
            </IconButton>
          </CardActions>

          <CardActions>
            <TextField
              margin="none"
              label="Buscar..."
              value={tmpSearch || ''}
              onChangeCapture={(e) => setTmpSearch(e.target.value)}
              onBlur={() => {
                setSearch(tmpSearch);
                setSearchParams({
                  page: 0,
                  sortField,
                  sortDirection,
                  pageSize,
                });
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  setSearch(tmpSearch);
                  setSearchParams({
                    page: 0,
                    sortField,
                    sortDirection,
                    pageSize,
                  });
                }
              }}
              fullWidth
            />
          </CardActions>
        </Card>

        {!data && (
          <Paper
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 400 }}
          >
            <CircularProgress size={60} />
          </Paper>
        )}

        {data && data.customerUsers && (
          <Paper sx={{ overflow: 'auto' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {selectedColumns.map((columnCode) => {
                    const column = customersColumns.find((item) => item.code === columnCode);

                    return (
                      <TableCell
                        key={columnCode}
                        sortDirection={sortField === column.sortField ? sortDirection : false}
                      >
                        <TableSortLabel
                          active={sortField === column.sortField}
                          direction={sortField === column.sortField ? sortDirection : 'asc'}
                          onClick={() => {
                            setSearchParams({
                              page: 0,
                              sortField: column.sortField,
                              sortDirection:
                                sortField === column.sortField && sortDirection === 'asc'
                                  ? 'desc'
                                  : 'asc',
                              pageSize,
                            });
                          }}
                        >
                          {column.name}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.customerUsers.map((customer) => (
                  <TableRow
                    hover
                    key={customer.id}
                    onClick={(e) => {
                      if (e.ctrlKey || e.shiftKey) {
                        window.open(`/customers/${customer.id}`, '_blank');
                      } else {
                        navigate(`/customers/${customer.id}`);
                      }
                    }}
                    sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    {selectedColumns.map((columnCode) => {
                      const column = customersColumns.find((item) => item.code === columnCode);

                      const value = column.path.split('.').reduce((prev, curr) => {
                        return prev ? prev[curr] : null;
                      }, customer);

                      return (
                        <TableCell key={columnCode}>
                          {column.type === 'text' && value}

                          {column.type === 'phone' && (
                            <LinkEl
                              href={`https://wa.me/52${value}`}
                              target="_blank"
                              onClick={(e) => e.stopPropagation()}
                            >
                              {value}
                            </LinkEl>
                          )}

                          {Boolean(column.type === 'date' && value) &&
                            moment(value).format('YYYY-MM-DD')}

                          {column.type === 'boolean' && (value ? 'Si' : 'No')}

                          {column.type === 'avatar' && (
                            <Avatar src={value} sx={{ backgroundColor: 'secondary.main' }}>
                              {customer.name.slice(0, 1).toUpperCase()}
                            </Avatar>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100, 500, 1000]}
              component="div"
              count={data.customerUsersCount}
              rowsPerPage={pageSize}
              page={pageNumber}
              onPageChange={(e, newPageNumber) =>
                setSearchParams({
                  page: newPageNumber,
                  sortField,
                  sortDirection,
                  pageSize,
                })
              }
              onRowsPerPageChange={(e) =>
                setSearchParams({
                  page: pageNumber,
                  sortField,
                  sortDirection,
                  pageSize: e.target.value,
                })
              }
              labelDisplayedRows={({ from, to, count }) => `${from} - ${to} de ${count}`}
              labelRowsPerPage=""
            />
          </Paper>
        )}

        {openCustomer && (
          <CustomerDialog
            variant={openCustomer.variant}
            customer={openCustomer}
            plans={data.plansByBusiness.filter((plan) => plan.type === 'membership')}
            handleClose={() => setOpenCustomer(null)}
            handleUpdate={() => {
              setOpenCustomer(null);
              refetch();
            }}
          />
        )}

        <Menu
          open={Boolean(columnsAnchorEl)}
          anchorEl={columnsAnchorEl}
          onClose={() => setColumnsAnchorEl(null)}
        >
          <MenuList>
            {customersColumns.map((column) => (
              <MenuItem
                key={column.code}
                onClick={() =>
                  setSelectedColumns(
                    selectedColumns.includes(column.code)
                      ? selectedColumns.filter((item) => item !== column.code)
                      : [...selectedColumns, column.code]
                  )
                }
              >
                <ListItemIcon>
                  <Icon
                    fontSize="small"
                    color={selectedColumns.includes(column.code) ? 'primary' : 'action'}
                  >
                    {selectedColumns.includes(column.code)
                      ? 'check_box'
                      : 'check_box_outline_blank'}
                  </Icon>
                </ListItemIcon>
                <ListItemText>{column.name}</ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </>
    </Layout>
  );
}

Customers.propTypes = {
  authenticatedUser: instanceOf(Object).isRequired,
};
