import React from 'react';
import { Box, ButtonBase, Link, Paper, Tooltip, Typography } from '@mui/material';
import { instanceOf } from 'prop-types';
import moment from 'moment';
import MessageStatusIndicator from './MessageStatusIndicator';

export default function Message({ message }) {
  const { alignItems } = {
    system: { alignItems: 'center' },
    in: { alignItems: 'flex-start' },
    out: { alignItems: 'flex-end' },
  }[message.direction];

  return (
    <Box key={message.id} sx={{ display: 'flex', flexDirection: 'column', alignItems, mb: 1 }}>
      <Paper
        sx={{
          py: 1,
          px: 2,
          maxWidth: { xs: '80%', md: '60%', xl: '50%' },
          wordBreak: 'break-word',
        }}
      >
        {Boolean(message.text) && (
          <Typography variant="body2">
            {message.text.split('\n').map((line) => (
              <>
                {line}
                <br />
              </>
            ))}
          </Typography>
        )}

        {message.attachments.map((attachment, idx) => {
          let attachmentEl = (
            <Link href={attachment.url} download={attachment.originalFilename} target="_blank">
              {attachment.originalFilename}
            </Link>
          );

          if (attachment.mimeType.includes('image')) {
            attachmentEl = (
              <ButtonBase component="a" target="_blank" href={attachment.url}>
                <Box
                  component="img"
                  src={attachment.url}
                  sx={{ width: '200px', height: '200px', objectFit: 'contain' }}
                />
              </ButtonBase>
            );
          } else if (attachment.mimeType.includes('audio')) {
            attachmentEl = (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <audio controls>
                <source src={attachment.url} type={attachment.mimeType} />
              </audio>
            );
          } else if (attachment.mimeType.includes('video')) {
            attachmentEl = (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <Box component="video" controls sx={{ width: '240px', height: '135px' }}>
                <source src={attachment.url} type={attachment.mimeType} />
              </Box>
            );
          }

          return (
            <Box key={attachment.url} sx={{ mt: idx !== 0 }}>
              {attachmentEl}
            </Box>
          );
        })}
      </Paper>
      <Tooltip
        title={[
          message.from && `Recibido de ${message.from}`,
          message.to || message.sentBy ? 'Enviado' : null,
          message.to ? `a ${message.to}` : null,
          message.sentBy ? `por ${message.sentBy.name}` : null,
        ]
          .filter((item) => item)
          .join(' ')}
      >
        <div>
          <Typography variant="caption" color="textSecondary" sx={{ p: '2px' }}>
            {[
              moment(message.createdAt).format('YYYY/MM/DD hh:mm a'),
              message.channel ? `vía ${message.channel.name}` : null,
            ]
              .filter((item) => item)
              .join(' ')}
          </Typography>

          {message.direction === 'out' && (
            <MessageStatusIndicator status={message.status} position="right" />
          )}
        </div>
      </Tooltip>
    </Box>
  );
}

Message.propTypes = {
  message: instanceOf(Object).isRequired,
};
