import React, { useState, useEffect, useMemo } from 'react';
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Icon,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { useQuery, gql, useMutation } from '@apollo/client';
import { instanceOf, string, func } from 'prop-types';
import axios from 'axios';

export default function NewMessage({ contactId, contactInfo, onSend }) {
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [to, setTo] = useState(null);
  const [text, setText] = useState('');
  const [uploadingAttachments, setUploadingAttachments] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const [send, { loading: sending }] = useMutation(
    gql`
      mutation SendMessage($input: MessageInput!) {
        sendMessage(input: $input) {
          id
          lastMessage {
            id
            direction
            text
            attachments {
              url
              mimeType
              originalFilename
            }
            status
            createdAt
          }
          messages {
            id
            direction
            text
            attachments {
              url
              mimeType
              originalFilename
            }
            createdAt
            status
            channel {
              name
            }
            to
            from
            sentBy {
              name
            }
          }
        }
      }
    `,
    {
      update: () => {
        setText('');
        setAttachments([]);
      },
    }
  );

  const { data: channelsData } = useQuery(
    gql`
      query GetChannels {
        channels {
          id
          name
          platform
        }
      }
    `,
    { notifyOnNetworkStatusChange: true }
  );

  useEffect(() => {
    if (channelsData && channelsData.channels && channelsData.channels.length) {
      setSelectedChannel(channelsData.channels[0].id);
    }
  }, [channelsData]);

  const selectableDestinations = useMemo(
    () =>
      contactInfo.filter((ci) => {
        if (!selectedChannel || ci.disabled) {
          return false;
        }

        const channel = channelsData.channels.find((item) => item.id === selectedChannel);

        if (channel.platform === 'whapi' && ci.type === 'phone') {
          return true;
        }

        if (channel.platform === 'mailgun' && ci.type === 'email') {
          return true;
        }

        return false;
      }),
    [selectedChannel, contactInfo, channelsData]
  );

  useEffect(() => {
    setTo(selectableDestinations.length ? selectableDestinations[0].value : null);
  }, [selectedChannel, selectableDestinations]);

  const handleAttachments = async (files) => {
    setUploadingAttachments(true);

    const newPreviews = [...files].map((file) => ({
      isPreview: true,
      url: null,
      previewUrl: file.type.includes('image') ? URL.createObjectURL(file) : null,
      mimeType: file.type,
      originalFilename: file.name,
    }));

    setAttachments([...attachments, ...newPreviews]);

    const newAttachments = await Promise.all(
      [...files].map(async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        return axios
          .post(`${process.env.API_URL}/upload`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((res) => ({
            ...res.data,
            originalFilename: file.name,
            isPreview: false,
            previewUrl: file.type.includes('image') ? URL.createObjectURL(file) : null,
          }));
      })
    );

    setAttachments([...attachments, ...newAttachments]);
    setUploadingAttachments(false);
  };

  const handleSend = () => {
    onSend();
    send({
      variables: {
        input: {
          contact: contactId,
          text: text.trim(),
          attachments: attachments.map((attachment) => ({
            url: attachment.url,
            originalFilename: attachment.originalFilename,
            mimeType: attachment.mimeType,
          })),
          channel: selectedChannel,
          to,
        },
      },
    });
  };

  const disabledSend =
    sending ||
    !selectedChannel ||
    !to ||
    ((!text || (text && !text.trim())) && attachments.length === 0) ||
    uploadingAttachments;

  const templatesButton = (
    <IconButton sx={{ p: 1, mr: 1 }} disabled>
      <Icon>sticky_note_2</Icon>
    </IconButton>
  );

  const attachmentButton = (
    <>
      <IconButton
        sx={{ p: 1, mr: 1 }}
        component="label"
        htmlFor={`${contactId}-attachment`}
        disabled={uploadingAttachments || sending}
      >
        {uploadingAttachments ? (
          <CircularProgress size={24} />
        ) : (
          <Icon color="action">attachment</Icon>
        )}
      </IconButton>

      <Box
        component="input"
        type="file"
        id={`${contactId}-attachment`}
        sx={{ display: 'none' }}
        multiple
        onChange={(e) => {
          handleAttachments(e.target.files);
          e.target.value = null;
        }}
        accept="*/*"
      />
    </>
  );

  const sendButton = (
    <IconButton color="primary" sx={{ p: 1 }} disabled={disabledSend} onClick={handleSend}>
      {sending ? <CircularProgress size={24} /> : <Icon>send</Icon>}
    </IconButton>
  );

  return (
    <>
      {attachments.length > 0 && (
        <Box sx={{ pt: 0.5, px: 1.5 }}>
          {attachments.map((attachment) => (
            <Chip
              key={attachment.previewUrl}
              avatar={attachment.previewUrl && <Avatar src={attachment.previewUrl} />}
              icon={
                !attachment.previewUrl &&
                ((attachment.mimeType.includes('audio') && <Icon>mic</Icon>) ||
                  (attachment.mimeType.includes('video') && <Icon>videocam</Icon>) || (
                    <Icon>attachment</Icon>
                  ))
              }
              label={attachment.originalFilename}
              variant="outlined"
              sx={{ mx: 0.5, mt: 0.5 }}
              onDelete={() =>
                setAttachments(attachments.filter((item) => item.url !== attachment.url))
              }
              deleteIcon={attachment.isPreview && <CircularProgress size={16} />}
              disabled={attachment.isPreview}
            />
          ))}
        </Box>
      )}

      <Box
        sx={{
          p: 1,
          display: 'flex',
          alignItems: 'flex-end',
          backgroundColor: '#fff',
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            mb: { xs: 1, md: 0 },
            display: 'flex',
            alignItems: 'flex-end',
            flex: { xs: 1, md: 'initial' },
          }}
        >
          {channelsData && channelsData.channels && (
            <ToggleButtonGroup
              value={selectedChannel}
              exclusive
              onChange={(e, newSelectedChannel) => setSelectedChannel(newSelectedChannel)}
              size="small"
              sx={{ mr: 1 }}
            >
              {channelsData.channels.map((channel) => (
                <ToggleButton key={channel.id} value={channel.id}>
                  <Tooltip title={channel.name}>
                    <Icon>
                      {{ whapi: 'message', mailgun: 'email' }[channel.platform] || 'message'}
                    </Icon>
                  </Tooltip>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}

          <FormControl sx={{ mr: 1, minWidth: 120, flex: 1 }} size="small">
            <Select value={to || ''} onChange={(e) => setTo(e.target.value)}>
              {selectableDestinations.map((item) => (
                <MenuItem key={item.value} value={item.value} disabled={item.disabled}>
                  {item.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ display: { xs: 'block', md: 'none' }, mr: -1 }}>
            {templatesButton}

            {attachmentButton}
          </Box>
        </Box>

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            mb: { xs: 1, md: 0 },
            minWidth: {
              xs: '100%',
              md: 'auto',
            },
            alignItems: 'flex-end',
          }}
        >
          <Paper
            sx={{
              flex: 1,
              p: '3px 4px',
              display: 'flex',
              alignItems: 'center',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '5px',
              mr: 1,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Escribe tu mensaje..."
              multiline
              maxRows={5}
              value={text || ''}
              onChange={(e) => setText(e.target.value)}
              disabled={sending}
              inputProps={{
                onKeyPress: (e) => {
                  if (e.key === 'Enter' && (e.shiftKey || e.ctrlKey)) {
                    return;
                  }

                  if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !disabledSend) {
                    handleSend();
                  }
                },
              }}
            />
          </Paper>

          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            {templatesButton}

            {attachmentButton}
          </Box>

          {sendButton}
        </Box>
      </Box>
    </>
  );
}

NewMessage.propTypes = {
  contactId: string.isRequired,
  contactInfo: instanceOf(Array).isRequired,
  onSend: func,
};

NewMessage.defaultProps = {
  onSend: () => {},
};
