import React, { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  Button,
  IconButton,
  Paper,
  useMediaQuery,
  Card,
  CardActions,
  Tabs,
  Tab,
} from '@mui/material';
import { instanceOf } from 'prop-types';
import moment from 'moment-timezone';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import '../Common/Calendar.css';
import SessionsCalendar from '../Sessions/SessionsCalendar';

export default function SessionTemplates({ authenticatedUser }) {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [view, setView] = useState('week');
  const [date, setDate] = useState(moment('2000-01-01').startOf('week'));
  const [selectedStudio, setSelectedStudio] = useState(null);

  useEffect(() => {
    setView(isDesktop ? 'week' : 'day');
  }, [isDesktop]);

  const { loading, data, refetch } = useQuery(
    gql`
      query GetSessions($businessId: ID!, $since: String!, $until: String!) {
        studiosByBusiness(businessId: $businessId) {
          id
          name
          rooms {
            id
            name
            defaultSeats
            studio {
              id
              name
            }
          }
          opensAt
          closesAt
        }
        adminsAndInstructors {
          id
          name
          isInstructor
        }
        disciplinesByBusiness(businessId: $businessId) {
          id
          name
        }
        sessionsByBusiness(businessId: $businessId, since: $since, until: $until) {
          id
          room {
            id
            name
            studio {
              id
              name
            }
          }
          instructor {
            id
            name
          }
          discipline {
            id
            name
          }
          label
          seats
          startsAt
          duration
          status
          startedAt
          endedAt
          reservationsCount
          attendanceCount
        }
      }
    `,
    {
      variables: {
        businessId: authenticatedUser.business.id,
        since: date.startOf(view).toDate().toISOString(),
        until: date.endOf(view).toDate().toISOString(),
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    refetch();
  }, [date, view, refetch]);

  useEffect(() => {
    if (!loading && !selectedStudio && data) {
      setSelectedStudio(data.studiosByBusiness[0]);
    }
  }, [selectedStudio, data, loading]);

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardActions>
          {selectedStudio && (
            <Tabs
              value={selectedStudio.id}
              onChange={(e, newTab) =>
                setSelectedStudio(data.studiosByBusiness.find((item) => item.id === newTab))
              }
              variant="scrollable"
              scrollButtons="auto"
            >
              {(data && data.studiosByBusiness ? data && data.studiosByBusiness : []).map(
                (studio) => (
                  <Tab key={studio.id} value={studio.id} label={studio.name} />
                )
              )}
            </Tabs>
          )}

          <Box sx={{ flex: 1 }} />

          <IconButton onClick={() => refetch()} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : <Icon>refresh</Icon>}
          </IconButton>

          {/* TODO */}
          <IconButton disabled>
            <Icon>help</Icon>
          </IconButton>
        </CardActions>

        {view === 'day' && (
          <CardActions sx={{ justifyContent: 'space-between' }}>
            <IconButton
              disabled={moment(date).day() === 0}
              onClick={() => setDate(moment(date).subtract(1, view))}
            >
              <Icon>chevron_left</Icon>
            </IconButton>

            <Button color="inherit" disabled>
              <Icon>today</Icon>
              <Box sx={{ ml: 0.5 }}>{moment(date).locale('es').format('dddd')}</Box>
            </Button>

            <IconButton
              disabled={moment(date).day() === 6}
              onClick={() => setDate(moment(date).add(1, view))}
            >
              <Icon>chevron_right</Icon>
            </IconButton>
          </CardActions>
        )}
      </Card>

      {!data && (
        <Paper
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 400 }}
        >
          <CircularProgress size={60} />
        </Paper>
      )}

      {data && selectedStudio && (
        <SessionsCalendar
          authenticatedUser={authenticatedUser}
          studio={selectedStudio}
          disciplines={data.disciplinesByBusiness || []}
          view={view}
          date={date}
          sessions={(data.sessionsByBusiness || []).filter(
            (session) =>
              session.room.studio.id === selectedStudio.id && session.status !== 'deleted'
          )}
          instructors={data.adminsAndInstructors || []}
          handleUpdate={() => refetch()}
          variant="templates"
        />
      )}
    </>
  );
}

SessionTemplates.propTypes = {
  authenticatedUser: instanceOf(Object).isRequired,
};
