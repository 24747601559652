import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Divider,
} from '@mui/material';
import { instanceOf, func } from 'prop-types';
import UploadInput from '../Common/UploadInput';

export default function DisciplineDialog({
  discipline: disciplineInput,
  handleClose,
  handleUpdate,
}) {
  const [discipline, setDiscipline] = useState({ ...disciplineInput });

  const [updateDiscipline, { loading: updatingDiscipline }] = useMutation(
    gql`
      mutation UpdateDiscipline($disciplineId: ID!, $input: DisciplineInput!) {
        updateDiscipline(disciplineId: $disciplineId, input: $input)
      }
    `,
    { update: handleUpdate }
  );

  const [createDiscipline, { loading: creatingDiscipline }] = useMutation(
    gql`
      mutation CreateDiscipline($input: DisciplineInput!) {
        createDiscipline(input: $input)
      }
    `,
    { update: handleUpdate }
  );

  const saving = updatingDiscipline || creatingDiscipline;

  return (
    <Dialog open fullWidth maxWidth="md" onClose={handleClose}>
      <DialogContent>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Nombre"
          value={discipline.name || ''}
          onChangeCapture={(e) => setDiscipline({ ...discipline, name: e.target.value })}
        />

        <UploadInput
          label="Ícono"
          uniqueName="discipline-icon"
          value={discipline.icon || ''}
          onChange={(url) => setDiscipline({ ...discipline, icon: url })}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" size="small">
          <Icon>cancel</Icon>
          <Box sx={{ ml: 1 }}>Cerrar</Box>
        </Button>
        <Button
          onClick={() => {
            const input = {
              name: discipline.name,
              icon: discipline.icon,
            };

            if (discipline.id) {
              updateDiscipline({
                variables: {
                  disciplineId: discipline.id,
                  input,
                },
              });
            } else {
              createDiscipline({
                variables: {
                  input,
                },
              });
            }
          }}
          disabled={saving || !discipline.name || !discipline.icon}
          variant="contained"
          size="small"
        >
          {saving ? <CircularProgress size={24} /> : <Icon>save</Icon>}
          <Box sx={{ ml: 1 }}>Guardar</Box>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DisciplineDialog.propTypes = {
  discipline: instanceOf(Object),
  handleClose: func.isRequired,
  handleUpdate: func.isRequired,
};

DisciplineDialog.defaultProps = {
  discipline: null,
};
