import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import { instanceOf, func } from 'prop-types';
import moment from 'moment-timezone';

export default function Pack({ pack, handleOpenPack }) {
  return (
    <TableRow hover onClick={handleOpenPack} sx={{ cursor: 'pointer' }}>
      <TableCell>{pack.startAt ? moment(pack.startAt).format('YYYY-MM-DD HH:mm') : ''}</TableCell>
      <TableCell>{pack.endAt ? moment(pack.endAt).format('YYYY-MM-DD HH:mm') : ''}</TableCell>
      <TableCell align="right">{pack.credits}</TableCell>
      <TableCell align="right">{pack.usedCredits}</TableCell>
      <TableCell align="right">{pack.availableCredits}</TableCell>
    </TableRow>
  );
}

Pack.propTypes = {
  pack: instanceOf(Object).isRequired,
  handleOpenPack: func.isRequired,
};
