import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  Grid,
  Button,
  IconButton,
  CardActions,
  Card,
  Paper,
} from '@mui/material';
import { instanceOf } from 'prop-types';

import Discipline from './Discipline';
import DisciplineDialog from './DisciplineDialog';

export default function Disciplines({ authenticatedUser }) {
  const [openDiscipline, setOpenDiscipline] = useState(null);

  const { loading, data, refetch } = useQuery(
    gql`
      query GetDisciplines($businessId: ID!) {
        disciplinesByBusiness(businessId: $businessId) {
          id
          name
          icon
        }
      }
    `,
    { variables: { businessId: authenticatedUser.business.id }, notifyOnNetworkStatusChange: true }
  );

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardActions>
          <Button
            onClick={() =>
              setOpenDiscipline({
                id: null,
                name: '',
                icon: '',
              })
            }
            variant="contained"
            size="small"
            disabled={!data}
          >
            <Icon>add</Icon>
            <Box sx={{ ml: 1 }}>Disciplina</Box>
          </Button>
          <Box sx={{ flex: 1 }} />
          <IconButton onClick={() => refetch()} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : <Icon>refresh</Icon>}
          </IconButton>

          {/* TODO */}
          <IconButton size="small" disabled>
            <Icon>help</Icon>
          </IconButton>
        </CardActions>
      </Card>

      {!data && (
        <Paper
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 400 }}
        >
          <CircularProgress size={60} />
        </Paper>
      )}

      {data && (
        <Grid container spacing={2}>
          {data.disciplinesByBusiness.map((discipline) => (
            <Grid key={discipline.id} item xs={12} md={6} lg={4}>
              <Discipline
                discipline={discipline}
                handleOpenDiscipline={() => setOpenDiscipline(discipline)}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {openDiscipline && (
        <DisciplineDialog
          discipline={openDiscipline}
          handleClose={() => setOpenDiscipline(null)}
          handleUpdate={() => {
            setOpenDiscipline(null);
            refetch();
          }}
        />
      )}
    </>
  );
}

Disciplines.propTypes = {
  authenticatedUser: instanceOf(Object).isRequired,
};
