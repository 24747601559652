export const customersColumns = [
  {
    code: 'picture',
    name: 'Foto',
    path: 'picture',
    sortable: true,
    sortField: 'picture',
    type: 'avatar',
  },
  {
    code: 'name',
    name: 'Nombre',
    path: 'name',
    sortable: true,
    sortField: 'name',
    type: 'text',
  },
  {
    code: 'phone',
    name: 'Teléfono',
    path: 'phone',
    sortable: true,
    sortField: 'phone',
    type: 'phone',
  },
  {
    code: 'email',
    name: 'Correo electrónico',
    path: 'email',
    sortable: true,
    sortField: 'email',
    type: 'text',
  },
  {
    code: 'createdAt',
    name: 'Cliente desde',
    path: 'createdAt',
    sortable: true,
    sortField: '_id',
    type: 'date',
  },
  {
    code: 'hadBorrowedPack',
    name: 'Adelantó créditos',
    path: 'hadBorrowedPack',
    sortable: true,
    sortField: 'hadBorrowedPack',
    type: 'boolean',
  },
  {
    code: 'lastBill.studio',
    name: 'Última compra: estudio',
    path: 'lastBill.studio.name',
    sortable: true,
    sortField: 'sort.lastBill.studio',
    type: 'text',
    showInContact: true,
  },
  {
    code: 'lastBill.createdAt',
    name: 'Última compra: fecha',
    path: 'lastBill.createdAt',
    sortable: true,
    sortField: 'sort.lastBill.createdAt',
    type: 'date',
    showInContact: true,
  },
  {
    code: 'lastSuccessfulReservation.studio',
    name: 'Última asistencia: estudio',
    path: 'lastSuccessfulReservation.session.room.studio.name',
    sortable: true,
    sortField: 'sort.lastSuccessfulReservation.studio',
    type: 'text',
    showInContact: true,
  },
  {
    code: 'lastSuccessfulReservation.startsAt',
    name: 'Última asistencia: fecha',
    path: 'lastSuccessfulReservation.session.startsAt',
    sortable: true,
    sortField: 'sort.lastSuccessfulReservation.startsAt',
    type: 'date',
    showInContact: true,
  },
  {
    code: 'lastBillWithPackPlan.studio',
    name: 'Última compra (créditos): estudio',
    path: 'lastBillWithPackPlan.studio.name',
    sortable: true,
    sortField: 'sort.lastBillWithPackPlan.studio',
    type: 'text',
    showInContact: true,
  },
  {
    code: 'lastBillWithPackPlan.createdAt',
    name: 'Última compra (créditos): fecha',
    path: 'lastBillWithPackPlan.createdAt',
    sortable: true,
    sortField: 'sort.lastBillWithPackPlan.createdAt',
    type: 'date',
    showInContact: true,
  },
  {
    code: 'lastBillWithPackPlan.plan',
    name: 'Última compra (créditos): plan',
    path: 'sort.lastBillWithPackPlan.plan',
    sortable: true,
    sortField: 'sort.lastBillWithPackPlan.plan',
    type: 'text',
    showInContact: true,
  },
  {
    code: 'lastBillWithPackPlan.expiresAt',
    name: 'Última compra (créditos): expira',
    path: 'sort.lastBillWithPackPlan.expiresAt',
    sortable: true,
    sortField: 'sort.lastBillWithPackPlan.expiresAt',
    type: 'date',
    showInContact: true,
  },
];

export default customersColumns;
