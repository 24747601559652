import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  Grid,
  Button,
  IconButton,
  CardActions,
  Card,
  Paper,
} from '@mui/material';
import { instanceOf } from 'prop-types';

import Plan from './Plan';
import PlanDialog from './PlanDialog';

export default function Plans({ authenticatedUser }) {
  const [openPlan, setOpenPlan] = useState(null);

  const { loading, data, refetch } = useQuery(
    gql`
      query GetPlans($businessId: ID!) {
        plansByBusiness(businessId: $businessId) {
          id
          type
          name
          price
          currency
          credits
          dynamicCredits
          duration
          durationUnit
          isSubscribable
          requiresMembership
        }
      }
    `,
    { variables: { businessId: authenticatedUser.business.id }, notifyOnNetworkStatusChange: true }
  );

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardActions>
          <Button
            onClick={() =>
              setOpenPlan({
                id: null,
                type: null,
                name: '',
                price: null,
                currency: 'mxn',
                credits: null,
                dynamicCredits: false,
                duration: null,
                durationUnit: 'days',
                isSubscribable: false,
                requiresMembership: false,
              })
            }
            variant="contained"
            size="small"
            disabled={!data}
          >
            <Icon>add</Icon>
            <Box sx={{ ml: 1 }}>Plan / Producto</Box>
          </Button>
          <Box sx={{ flex: 1 }} />
          <IconButton onClick={() => refetch()} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : <Icon>refresh</Icon>}
          </IconButton>

          {/* TODO */}
          <IconButton size="small" disabled>
            <Icon>help</Icon>
          </IconButton>
        </CardActions>
      </Card>

      {!data && (
        <Paper
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 400 }}
        >
          <CircularProgress size={60} />
        </Paper>
      )}

      {data && (
        <Grid container spacing={2}>
          {data.plansByBusiness.map((plan) => (
            <Grid key={plan.id} item xs={12} md={6} lg={4}>
              <Plan plan={plan} handleOpenPlan={() => setOpenPlan(plan)} />
            </Grid>
          ))}
        </Grid>
      )}

      {openPlan && (
        <PlanDialog
          plan={openPlan}
          handleClose={() => setOpenPlan(null)}
          handleUpdate={() => {
            setOpenPlan(null);
            refetch();
          }}
        />
      )}
    </>
  );
}

Plans.propTypes = {
  authenticatedUser: instanceOf(Object).isRequired,
};
