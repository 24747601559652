import React, { useEffect, useState } from 'react';
import { instanceOf, func, bool, string } from 'prop-types';
import moment from 'moment';
import {
  Icon,
  Dialog,
  DialogTitle,
  Divider,
  Button,
  Box,
  CircularProgress,
  DialogContent,
  StepLabel,
  Step,
  Stepper,
  StepContent,
  Tab,
  Tabs,
  TableRow,
  TableCell,
  TableHead,
  Table,
  TableBody,
  Autocomplete,
  TextField,
  IconButton,
  TableFooter,
  FormControl,
  Select,
  MenuItem,
  Alert,
  InputAdornment,
  InputLabel,
} from '@mui/material';
import { DatePicker } from '@mui/lab';
import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import CustomerForm from '../Customers/CustomerForm';
import CustomersAutocomplete from '../Common/CustomersAutocomplete';
import { formatCurrency } from '../../../utils/formatCurrency';
import { translateError } from '../../../utils/translateError';
import ConfirmCustomer from '../Common/ConfirmCustomer';
import UploadInput from '../Common/UploadInput';

export default function PaidBillDialog({
  plans,
  handleClose,
  handleUpdate,
  requireNewCurrentCreditsPlan,
  sessionId,
  availableSeats,
  studioId,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [step, setStep] = useState(0);
  const [input, setInput] = useState({
    studio: studioId,
    customer: null,
    newCustomer: {
      email: '',
      phone: '',
      name: '',
      picture: '',
      address: {
        street: '',
        neighborhood: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
      },
      isOwner: false,
      isAdmin: false,
      isInstructor: false,
      birthday: '',
    },
    items: [
      {
        id: Math.random(),
        plan: null,
        startAt: moment().startOf('day').toDate().toISOString(),
        subscribe: false,
      },
    ],
    payments: [
      {
        id: Math.random(),
        type: 'cash',
        amount: null,
        proof: null,
      },
    ],
    newCustomerPicture: null,
    customerValidationPicture: null,
    reservation: {
      session: sessionId,
      seat: availableSeats[0] || null,
      status: 'successful-last-minute',
    },
  });

  const [createPaidBill, { loading: creatingPaidBill, error }] = useMutation(
    gql`
      mutation CreatePaidBill($input: PaidBillInput!) {
        createPaidBill(input: $input)
      }
    `,
    {
      update: () => {
        handleClose();
        handleUpdate();
      },
    }
  );

  useEffect(() => {
    if (error) {
      enqueueSnackbar(translateError(error.message), { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  const saving = creatingPaidBill;

  const missingMembership =
    input.customer &&
    (input.customer === 'new' || !input.customer.membership.isActive) &&
    !input.items.some((item) => item.plan && item.plan.type === 'membership') &&
    input.items.some((item) => item.plan && item.plan.requiresMembership);

  const missingCreditsPlan =
    requireNewCurrentCreditsPlan &&
    !input.items.some(
      (item) =>
        item.plan &&
        item.startAt &&
        item.plan.type === 'pack' &&
        moment().isBetween(
          item.startAt,
          moment(item.startAt).add(item.plan.duration, item.plan.durationUnit).endOf('day')
        )
    );

  return (
    <Dialog open fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle>Nueva venta</DialogTitle>
      <Divider />
      <DialogContent>
        <Stepper activeStep={step} orientation="vertical">
          <Step>
            <StepLabel>Selecciona un cliente</StepLabel>

            <StepContent>
              <Tabs
                value={input.customer === 'new' ? 'new' : 'existing'}
                onChange={(e, tab) =>
                  setInput({ ...input, customer: tab === 'new' ? 'new' : null })
                }
              >
                <Tab value="existing" label="Existente" />
                <Tab value="new" label="Nuevo" />
              </Tabs>

              <Divider />

              {input.customer === 'new' && (
                <CustomerForm
                  customer={input.newCustomer}
                  setCustomer={(value) => setInput({ ...input, newCustomer: value })}
                />
              )}

              {input.customer !== 'new' && (
                <Box sx={{ mt: 2 }}>
                  <CustomersAutocomplete
                    margin="normal"
                    required
                    fullWidth
                    label="Buscar"
                    value={input.customer}
                    onChange={(newValue) => setInput({ ...input, customer: newValue })}
                    autoFocus
                  />
                </Box>
              )}

              <div>
                {input.customer !== 'new' ? (
                  <ConfirmCustomer
                    customer={input.customer}
                    onConfirm={(newCustomerPicture, customerValidationPicture) => {
                      setInput({ ...input, newCustomerPicture, customerValidationPicture });
                      setStep(step + 1);
                    }}
                    component={Button}
                    variant="contained"
                    sx={{ mt: 3, mr: 1 }}
                    disabled={!input.customer}
                  >
                    <span>Siguiente</span>
                  </ConfirmCustomer>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => setStep(step + 1)}
                    sx={{ mt: 3, mr: 1 }}
                    disabled={
                      !input.newCustomer.name ||
                      (!input.newCustomer.email && !input.newCustomer.phone)
                    }
                  >
                    Siguiente
                  </Button>
                )}
              </div>
            </StepContent>
          </Step>

          {sessionId && (
            <Step>
              <StepLabel>Selecciona un lugar en la clase</StepLabel>

              <StepContent>
                <FormControl margin="none" required sx={{ width: 100 }}>
                  <InputLabel>Lugar</InputLabel>
                  <Select
                    value={input.reservation.seat || ''}
                    label="Lugar"
                    onChange={(e) =>
                      setInput({
                        ...input,
                        reservation: { ...input.reservation, seat: e.target.value },
                      })
                    }
                  >
                    {availableSeats.map((seat) => (
                      <MenuItem key={seat} value={seat}>
                        {seat}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div>
                  <Button
                    variant="contained"
                    onClick={() => setStep(step + 1)}
                    sx={{ mt: 3, mr: 1 }}
                    disabled={!input.reservation.seat}
                  >
                    Siguiente
                  </Button>

                  <Button onClick={() => setStep(step - 1)} sx={{ mt: 3, mr: 1 }}>
                    Regresar
                  </Button>
                </div>
              </StepContent>
            </Step>
          )}

          <Step>
            <StepLabel>Agrega planes y productos</StepLabel>
            <StepContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Plan</TableCell>
                    <TableCell>Fecha de inicio</TableCell>
                    <TableCell>Subtotal</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {input.items.map((item, idx) => (
                    <TableRow key={item.id}>
                      <TableCell sx={{ width: '60%' }}>
                        <Autocomplete
                          options={plans}
                          renderInput={(params) => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <TextField {...params} fullWidth margin="none" sx={{ minWidth: 150 }} />
                          )}
                          value={item.plan}
                          onChange={(e, newValue) => {
                            setInput({
                              ...input,
                              items: input.items.map((i, idx2) =>
                                idx === idx2 ? { ...i, plan: newValue } : i
                              ),
                            });
                          }}
                          getOptionLabel={(option) =>
                            `${option.name} (${formatCurrency(option.price)})`
                          }
                          fullWidth
                        />
                      </TableCell>
                      <TableCell sx={{ width: '40%' }}>
                        <DatePicker
                          value={item.startAt ? moment(item.startAt) : null}
                          onChange={(newValue) => {
                            setInput({
                              ...input,
                              items: input.items.map((i, idx2) =>
                                idx === idx2
                                  ? {
                                      ...i,
                                      startAt:
                                        newValue && newValue.isValid()
                                          ? newValue.startOf('day').toDate().toISOString()
                                          : null,
                                    }
                                  : i
                              ),
                            });
                          }}
                          renderInput={(params) => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <TextField margin="none" fullWidth {...params} sx={{ minWidth: 150 }} />
                          )}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>{item.plan ? formatCurrency(item.plan.price) : '-'}</TableCell>
                      <TableCell>
                        <IconButton
                          color="error"
                          onClick={() =>
                            setInput({
                              ...input,
                              items: input.items.filter((i, idx2) => idx !== idx2),
                            })
                          }
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell>
                      {formatCurrency(
                        input.items.reduce(
                          (total, item) => total + (item.plan ? item.plan.price : 0),
                          0
                        )
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          setInput({
                            ...input,
                            items: [
                              ...input.items,
                              {
                                id: Math.random(),
                                plan: null,
                                startAt: moment().startOf('day').toDate().toISOString(),
                                subscribe: false,
                              },
                            ],
                          })
                        }
                      >
                        <Icon>add</Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>

              {missingMembership && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  Algunos de los planes seleccionados requiren membresía. Asegúrate de agregar una
                  membresía a la cuenta.
                </Alert>
              )}

              {missingCreditsPlan && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  Debes agregar al menos un plan de créditos con vigencia actual para continuar.
                </Alert>
              )}

              <div>
                <Button
                  variant="contained"
                  onClick={() => {
                    setStep(step + 1);

                    if (input.payments.length === 1) {
                      setInput({
                        ...input,
                        payments: [
                          {
                            ...input.payments[0],
                            amount: input.items.reduce(
                              (total, item) => total + (item.plan ? item.plan.price : 0),
                              0
                            ),
                          },
                        ],
                      });
                    }
                  }}
                  sx={{ mt: 3, mr: 1 }}
                  disabled={Boolean(
                    input.items.length === 0 ||
                      input.items.find((item) => !item.plan || !item.startAt) ||
                      missingMembership
                  )}
                >
                  Siguiente
                </Button>

                <Button onClick={() => setStep(step - 1)} sx={{ mt: 3, mr: 1 }}>
                  Regresar
                </Button>
              </div>
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Registra el pago</StepLabel>
            <StepContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tipo de pago</TableCell>
                    <TableCell>Monto</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {input.payments.map((payment, idx) => (
                    <TableRow key={payment.id}>
                      <TableCell sx={{ width: '60%' }}>
                        <FormControl fullWidth margin="none" sx={{ minWidth: 150 }}>
                          <Select
                            value={payment.type || ''}
                            onChange={(e) =>
                              setInput({
                                ...input,
                                payments: input.payments.map((i, idx2) =>
                                  idx === idx2
                                    ? {
                                        ...i,
                                        type: e.target.value,
                                      }
                                    : i
                                ),
                              })
                            }
                          >
                            <MenuItem value="cash">Efectivo</MenuItem>
                            <MenuItem value="transfer">Transferencia</MenuItem>
                            <MenuItem value="card">Tarjeta</MenuItem>
                          </Select>
                        </FormControl>

                        {['transfer', 'card'].includes(payment.type) && (
                          <UploadInput
                            label="Comprobante"
                            value={payment.proof}
                            onChange={(url) =>
                              setInput({
                                ...input,
                                payments: input.payments.map((i, idx2) =>
                                  idx === idx2 ? { ...i, proof: url } : i
                                ),
                              })
                            }
                            noMargin
                            uniqueName={`payment-proof-${idx}`}
                          />
                        )}
                      </TableCell>
                      <TableCell sx={{ width: '40%' }}>
                        <TextField
                          margin="none"
                          fullWidth
                          value={payment.amount || payment.amount === 0 ? payment.amount : ''}
                          onChangeCapture={(e) =>
                            setInput({
                              ...input,
                              payments: input.payments.map((i, idx2) =>
                                idx === idx2
                                  ? {
                                      ...i,
                                      amount: e.target.value ? Number(e.target.value) : null,
                                    }
                                  : i
                              ),
                            })
                          }
                          type="number"
                          sx={{ minWidth: 150 }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="error"
                          onClick={() =>
                            setInput({
                              ...input,
                              payments: input.payments.filter((i, idx2) => idx !== idx2),
                            })
                          }
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell />
                    <TableCell>
                      {formatCurrency(
                        input.payments.reduce((total, payment) => total + payment.amount, 0)
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          setInput({
                            ...input,
                            payments: [
                              ...input.payments,
                              {
                                id: Math.random(),
                                type: null,
                                amount: null,
                                proof: null,
                              },
                            ],
                          })
                        }
                      >
                        <Icon>add</Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>

              <div>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => {
                    createPaidBill({
                      variables: {
                        input: {
                          studio: input.studio,
                          customer: input.customer === 'new' ? 'new' : input.customer.id,
                          newCustomer: {
                            name: input.newCustomer.name,
                            email: input.newCustomer.email,
                            phone: input.newCustomer.phone,
                            picture: input.newCustomer.picture,
                            address: {
                              street: input.newCustomer.address.street,
                              neighborhood: input.newCustomer.address.neighborhood,
                              city: input.newCustomer.address.city,
                              state: input.newCustomer.address.state,
                              zipcode: input.newCustomer.address.zipcode,
                              country: input.newCustomer.address.country,
                            },
                            birthday: input.newCustomer.birthday || null,
                          },
                          items: input.items.map((item) => ({
                            plan: item.plan.id,
                            startAt: item.startAt,
                            subscribe: item.subscribe,
                          })),
                          payments: input.payments.map((payment) => ({
                            amount: payment.amount,
                            type: payment.type,
                            proof: payment.proof,
                          })),
                          newCustomerPicture: input.newCustomerPicture,
                          customerValidationPicture: input.customerValidationPicture,
                          reservation: {
                            session: input.reservation.session,
                            status: input.reservation.status,
                            seat: input.reservation.seat,
                          },
                        },
                      },
                    });
                  }}
                  sx={{ mt: 3, mr: 1 }}
                  disabled={Boolean(
                    input.payments.length === 0 ||
                      input.payments.find(
                        (payment) => !payment.type || (!payment.amount && payment.amount !== 0)
                      ) ||
                      input.items.reduce(
                        (total, item) => total + (item.plan ? item.plan.price : 0),
                        0
                      ) !== input.payments.reduce((total, payment) => total + payment.amount, 0)
                  )}
                >
                  {saving ? <CircularProgress size={24} /> : <Icon>price_check</Icon>}
                  <Box sx={{ ml: 1 }}>Registrar venta</Box>
                </Button>

                <Button onClick={() => setStep(step - 1)} sx={{ mt: 3, mr: 1 }}>
                  Regresar
                </Button>
              </div>
            </StepContent>
          </Step>
        </Stepper>
      </DialogContent>
    </Dialog>
  );
}

PaidBillDialog.propTypes = {
  handleClose: func.isRequired,
  handleUpdate: func.isRequired,
  plans: instanceOf(Array).isRequired,
  requireNewCurrentCreditsPlan: bool,
  sessionId: string,
  availableSeats: instanceOf(Array),
  studioId: string.isRequired,
};

PaidBillDialog.defaultProps = {
  requireNewCurrentCreditsPlan: false,
  sessionId: null,
  availableSeats: [],
};
