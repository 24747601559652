import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  IconButton,
  Card,
  CardActions,
  Paper,
  Button,
  Popover,
  Stack,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { instanceOf } from 'prop-types';
import moment from 'moment-timezone';
import { StaticDatePicker } from '@mui/lab';

import Layout from '../Common/Layout';
import { formatCurrency } from '../../../utils/formatCurrency';

export default function Reports({ authenticatedUser }) {
  const [since, setSince] = useState(moment().subtract(1, 'month').startOf('day'));
  const [until, setUntil] = useState(moment().endOf('day'));
  const [dateAnchorEl, setDateAnchorEl] = useState(null);
  const [selectedStudio, setSelectedStudio] = useState({ id: 'all' });

  const { loading, data, refetch } = useQuery(
    gql`
      query GetReports($businessId: ID!, $since: String!, $until: String!) {
        studiosByBusiness(businessId: $businessId) {
          id
          name
        }
        customerUsersCountByStudio {
          studio {
            id
          }
          currentValue
          previousValue
        }
        newCusomterUsersCountByStudio: customerUsersCountByStudio(since: $since, until: $until) {
          studio {
            id
          }
          currentValue
          previousValue
        }
        totalPaidAmountByStudio(since: $since, until: $until) {
          studio {
            id
          }
          currentValue
          previousValue
        }
        totalPaidByCashAmountByStudio: totalPaidAmountByStudio(
          type: "cash"
          since: $since
          until: $until
        ) {
          studio {
            id
          }
          currentValue
          previousValue
        }
        totalPaidByTransferAmountByStudio: totalPaidAmountByStudio(
          type: "transfer"
          since: $since
          until: $until
        ) {
          studio {
            id
          }
          currentValue
          previousValue
        }
        totalPaidByCardAmountByStudio: totalPaidAmountByStudio(
          type: "card"
          since: $since
          until: $until
        ) {
          studio {
            id
          }
          currentValue
          previousValue
        }
      }
    `,
    {
      variables: {
        businessId: authenticatedUser.business.id,
        since: moment(since).toDate().toISOString(),
        until: moment(until).toDate().toISOString(),
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    refetch();
  }, [since, until, refetch]);

  const {
    customerUsersCount,
    newCusomterUsersCount,
    totalPaidAmount,
    totalPaidByCashAmount,
    totalPaidByTransferAmount,
    totalPaidByCardAmount,
  } = useMemo(() => {
    if (
      !data ||
      !data.customerUsersCountByStudio ||
      !data.newCusomterUsersCountByStudio ||
      !data.totalPaidAmountByStudio ||
      !data.totalPaidByCashAmountByStudio ||
      !data.totalPaidByTransferAmountByStudio ||
      !data.totalPaidByCardAmountByStudio
    ) {
      return {
        customerUsersCount: 0,
        newCusomterUsersCount: 0,
        totalPaidAmount: 0,
        totalPaidByCashAmount: 0,
        totalPaidByTransferAmount: 0,
        totalPaidByCardAmount: 0,
      };
    }

    return {
      customerUsersCount:
        selectedStudio.id === 'all'
          ? data.customerUsersCountByStudio.reduce((sum, el) => sum + el.currentValue, 0)
          : data.customerUsersCountByStudio.find((el) => el.studio.id === selectedStudio.id)
              .currentValue,
      newCusomterUsersCount:
        selectedStudio.id === 'all'
          ? data.newCusomterUsersCountByStudio.reduce((sum, el) => sum + el.currentValue, 0)
          : data.newCusomterUsersCountByStudio.find((el) => el.studio.id === selectedStudio.id)
              .currentValue,
      totalPaidAmount:
        selectedStudio.id === 'all'
          ? data.totalPaidAmountByStudio.reduce((sum, el) => sum + el.currentValue, 0)
          : data.totalPaidAmountByStudio.find((el) => el.studio.id === selectedStudio.id)
              .currentValue,
      totalPaidByCashAmount:
        selectedStudio.id === 'all'
          ? data.totalPaidByCashAmountByStudio.reduce((sum, el) => sum + el.currentValue, 0)
          : data.totalPaidByCashAmountByStudio.find((el) => el.studio.id === selectedStudio.id)
              .currentValue,
      totalPaidByTransferAmount:
        selectedStudio.id === 'all'
          ? data.totalPaidByTransferAmountByStudio.reduce((sum, el) => sum + el.currentValue, 0)
          : data.totalPaidByTransferAmountByStudio.find((el) => el.studio.id === selectedStudio.id)
              .currentValue,
      totalPaidByCardAmount:
        selectedStudio.id === 'all'
          ? data.totalPaidByCardAmountByStudio.reduce((sum, el) => sum + el.currentValue, 0)
          : data.totalPaidByCardAmountByStudio.find((el) => el.studio.id === selectedStudio.id)
              .currentValue,
    };
  }, [selectedStudio, data]);

  return (
    <Layout
      authenticatedUser={authenticatedUser}
      title="Reportes"
      code="reports"
      tabs={[
        { code: 'all', name: authenticatedUser.business.name },
        ...(data
          ? data.studiosByBusiness.map((studio) => ({ code: studio.id, name: studio.name }))
          : []),
      ]}
      openTab={selectedStudio ? selectedStudio.id : ''}
      handleChangeOpenTab={(value) =>
        setSelectedStudio(
          value === 'all'
            ? { id: 'all' }
            : data.studiosByBusiness.find((studio) => studio.id === value)
        )
      }
    >
      <>
        <Card sx={{ mb: 2 }}>
          <CardActions>
            <Box sx={{ flex: 1 }} />

            <IconButton onClick={() => refetch()} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : <Icon>refresh</Icon>}
            </IconButton>

            {/* TODO */}
            <IconButton disabled>
              <Icon>help</Icon>
            </IconButton>
          </CardActions>

          {(authenticatedUser.isOwner || authenticatedUser.isAdmin) && (
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button onClick={(e) => setDateAnchorEl(e.currentTarget)} color="inherit">
                <Icon>today</Icon>
                <Box sx={{ ml: 0.5 }}>
                  {`${moment(since).format('YYYY-MM-DD')} a ${moment(until).format('YYYY-MM-DD')}`}
                </Box>
              </Button>

              <Popover
                open={Boolean(dateAnchorEl)}
                anchorEl={dateAnchorEl}
                onClose={() => setDateAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="caption" color="textSecondary">
                      Desde
                    </Typography>
                    <StaticDatePicker
                      displayStaticWrapperAs="desktop"
                      value={since}
                      onChange={(value) => setSince(value)}
                      renderInput={() => null}
                    />
                  </Box>

                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="caption" color="textSecondary">
                      Hasta
                    </Typography>
                    <StaticDatePicker
                      displayStaticWrapperAs="desktop"
                      value={until}
                      onChange={(value) => setUntil(value)}
                      renderInput={() => null}
                    />
                  </Box>
                </Stack>
              </Popover>
            </CardActions>
          )}
        </Card>

        {!data && (
          <Paper
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 400 }}
          >
            <CircularProgress size={60} />
          </Paper>
        )}

        {data && (
          <>
            <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
              Clientes
            </Typography>
            <Grid container spacing={2}>
              {/* customerUsersCount */}
              <Grid item xs={12} md={4} lg={3}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="body1" color="GrayText">
                    Total de clientes
                  </Typography>
                  <Typography variant="h4" color="secondary">
                    {customerUsersCount}
                  </Typography>
                </Paper>
              </Grid>

              {/* newCusomterUsersCount */}
              <Grid item xs={12} md={4} lg={3}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="body1" color="GrayText">
                    Nuevos clientes
                  </Typography>
                  <Typography variant="h4" color="secondary">
                    {newCusomterUsersCount}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <Typography variant="h6" color="primary" sx={{ mt: 4, mb: 2 }}>
              Pagos recibidos
            </Typography>
            <Grid container spacing={2}>
              {/* totalPaidAmount */}
              <Grid item xs={12} md={4} lg={3}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="body1" color="GrayText">
                    Total
                  </Typography>
                  <Typography variant="h4" color="secondary">
                    {formatCurrency(totalPaidAmount)}
                  </Typography>
                </Paper>
              </Grid>

              {/* totalPaidByCashAmount */}
              <Grid item xs={12} md={4} lg={3}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="body1" color="GrayText">
                    En efectivo
                  </Typography>
                  <Typography variant="h4" color="secondary">
                    {formatCurrency(totalPaidByCashAmount)}
                  </Typography>
                </Paper>
              </Grid>

              {/* totalPaidByTransferAmount */}
              <Grid item xs={12} md={4} lg={3}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="body1" color="GrayText">
                    Por transferencia
                  </Typography>
                  <Typography variant="h4" color="secondary">
                    {formatCurrency(totalPaidByTransferAmount)}
                  </Typography>
                </Paper>
              </Grid>

              {/* totalPaidByCardAmount */}
              <Grid item xs={12} md={4} lg={3}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="body1" color="GrayText">
                    Con tarjeta
                  </Typography>
                  <Typography variant="h4" color="secondary">
                    {formatCurrency(totalPaidByCardAmount)}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
      </>
    </Layout>
  );
}

Reports.propTypes = {
  authenticatedUser: instanceOf(Object).isRequired,
};
