import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Hidden,
  Divider,
} from '@mui/material';
import { instanceOf, func, string } from 'prop-types';
import { useSnackbar } from 'notistack';

import CustomersAutocomplete from '../Common/CustomersAutocomplete';
import { translateError } from '../../../utils/translateError';
import ConfirmCustomer from '../Common/ConfirmCustomer';

export default function NewReservationDialog({
  sessionId,
  availableSeats,
  handleClose,
  handleUpdate,
  status,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [reservation, setReservation] = useState({
    user: null,
    session: { id: sessionId },
    status,
    seat: availableSeats[0],
  });

  const [createReservation, { loading: creatingReservation, error }] = useMutation(
    gql`
      mutation CreateReservation($input: ReservationInput!) {
        createReservation(input: $input)
      }
    `,
    { update: handleUpdate }
  );

  useEffect(() => {
    if (error) {
      enqueueSnackbar(translateError(error.message), { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  const saving = creatingReservation;

  return (
    <>
      <Hidden mdUp>
        <Divider sx={{ mb: 2 }} />
      </Hidden>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: 2,
          pb: 1,
          flexWrap: { xs: 'wrap', md: 'nowrap' },
        }}
      >
        <CustomersAutocomplete
          margin="none"
          required
          fullWidth
          label="Cliente"
          value={reservation.user}
          onChange={(newValue) => setReservation({ ...reservation, user: newValue })}
          autoFocus
        />

        <Box sx={{ pr: { xs: 0, md: 1 }, mt: { xs: 2, md: 0 }, width: { xs: '100%', md: 0 } }} />

        <FormControl
          margin="none"
          required
          sx={{ width: { xs: 'calc(100% - 34px - 34px - 8px)', md: 100 } }}
        >
          <InputLabel>Lugar</InputLabel>
          <Select
            value={reservation.seat || ''}
            label="Lugar"
            onChange={(e) => setReservation({ ...reservation, seat: e.target.value })}
          >
            {availableSeats.map((seat) => (
              <MenuItem key={seat} value={seat}>
                {seat}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ pr: 1 }} />

        <IconButton onClick={handleClose} variant="outlined" color="error" size="small">
          <Icon>cancel</Icon>
        </IconButton>

        <ConfirmCustomer
          component={IconButton}
          customer={reservation.user}
          onConfirm={(newCustomerPicture, customerValidationPicture) => {
            createReservation({
              variables: {
                input: {
                  user: reservation.user.id,
                  session: reservation.session.id,
                  status: reservation.status,
                  seat: reservation.seat,
                  newCustomerPicture,
                  customerValidationPicture,
                },
              },
            });
          }}
          confirming={saving}
          disabled={saving || !reservation.user || !reservation.seat}
          variant="contained"
          size="small"
          color="success"
        >
          {saving ? <CircularProgress size={24} /> : <Icon>event_available</Icon>}
        </ConfirmCustomer>
      </Box>
    </>
  );
}

NewReservationDialog.propTypes = {
  sessionId: string.isRequired,
  availableSeats: instanceOf(Array).isRequired,
  handleClose: func.isRequired,
  handleUpdate: func.isRequired,
  status: string,
};

NewReservationDialog.defaultProps = {
  status: 'upcoming',
};
