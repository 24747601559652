import React, { useState } from 'react';
import { string, bool, func } from 'prop-types';
import {
  IconButton,
  Icon,
  Tooltip,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  DialogActions,
  Divider,
  Button,
  Box,
  Radio,
  CircularProgress,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';

export default function CancelReservationDialog({ disabled, handleUpdate, reservationId }) {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('canceled-by-business');

  const [cancelReservation, { loading: cancelingReservation }] = useMutation(
    gql`
      mutation CancelReservation($reservationId: ID!, $input: ReservationInput!) {
        updateReservation(reservationId: $reservationId, input: $input)
      }
    `,
    {
      update: () => {
        setOpen(false);
        handleUpdate();
      },
    }
  );

  const saving = cancelingReservation;

  return (
    <>
      <Tooltip title="Cancelar reservcación">
        <IconButton onClick={() => setOpen(true)} disabled={disabled}>
          <Icon>cancel</Icon>
        </IconButton>
      </Tooltip>

      <Dialog open={open} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
        <DialogTitle>Selecciona el tipo de cancelación</DialogTitle>
        <Divider />
        <List dense>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setStatus('canceled-by-business')}>
              <ListItemIcon>
                <Radio
                  edge="start"
                  checked={status === 'canceled-by-business'}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText
                primary="Cancelada por el negocio"
                secondary="Se le bonificará un crédito al cliente."
              />
            </ListItemButton>
          </ListItem>
          <Divider />

          <ListItem disablePadding>
            <ListItemButton onClick={() => setStatus('canceled')}>
              <ListItemIcon>
                <Radio edge="start" checked={status === 'canceled'} tabIndex={-1} disableRipple />
              </ListItemIcon>
              <ListItemText
                primary="Cancelada por el cliente"
                secondary="Se le bonificará un crédito al cliente dependiendo del tiempo que falte para el inicio de la clase."
              />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            <Icon>cancel</Icon>
            <Box sx={{ ml: 1 }}>Cerrar</Box>
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={saving}
            onClick={() => cancelReservation({ variables: { reservationId, input: { status } } })}
          >
            {saving ? <CircularProgress size={24} /> : <Icon>cancel</Icon>}
            <Box sx={{ ml: 1 }}>Cancelar</Box>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

CancelReservationDialog.propTypes = {
  reservationId: string.isRequired,
  disabled: bool,
  handleUpdate: func.isRequired,
};

CancelReservationDialog.defaultProps = {
  disabled: false,
};
