import React, { useEffect, useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  IconButton,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Paper,
  Button,
  Popover,
  Typography,
  CardActionArea,
  LinearProgress,
} from '@mui/material';
import { instanceOf } from 'prop-types';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { StaticDatePicker } from '@mui/lab';

import Layout from '../Common/Layout';
import CalendarEvent from '../Common/CalendarEvent';
import Confirm from '../Common/Confirm';

export default function Sessions({ authenticatedUser }) {
  const navigate = useNavigate();
  const [date, setDate] = useState(moment());
  const [dateAnchorEl, setDateAnchorEl] = useState(null);
  const [selectedStudio, setSelectedStudio] = useState(null);

  const { loading, data, refetch } = useQuery(
    gql`
      query GetDaySessions($businessId: ID!, $since: String!, $until: String!) {
        studiosByBusiness(businessId: $businessId) {
          id
          name
        }
        adminsAndInstructors {
          id
          name
          isInstructor
        }
        sessionsByBusiness(businessId: $businessId, since: $since, until: $until) {
          id
          room {
            id
            name
            studio {
              id
              name
            }
          }
          instructor {
            id
            name
          }
          discipline {
            id
            name
          }
          startsAt
          duration
          status
          seats
          reservationsCount
          attendanceCount
          actualInstructor {
            id
            name
          }
          startedAt
          endedAt
        }
      }
    `,
    {
      variables: {
        businessId: authenticatedUser.business.id,
        since: moment(date).startOf('day').toDate().toISOString(),
        until: moment(date).endOf('day').toDate().toISOString(),
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const [startSession] = useMutation(
    gql`
      mutation StartSession($sessionId: ID!, $actualInstructorId: ID!) {
        startSession(sessionId: $sessionId, actualInstructorId: $actualInstructorId)
      }
    `
  );

  useEffect(() => {
    refetch();
  }, [date, refetch]);

  useEffect(() => {
    if (!selectedStudio && data && data.studiosByBusiness && data.studiosByBusiness.length) {
      setSelectedStudio(data.studiosByBusiness[0]);
    }
  }, [selectedStudio, data]);

  return (
    <Layout
      authenticatedUser={authenticatedUser}
      title="Check-in"
      code="check-in"
      tabs={
        data ? data.studiosByBusiness.map((studio) => ({ code: studio.id, name: studio.name })) : []
      }
      openTab={selectedStudio ? selectedStudio.id : ''}
      handleChangeOpenTab={(value) =>
        setSelectedStudio(data.studiosByBusiness.find((studio) => studio.id === value))
      }
    >
      <>
        <Card sx={{ mb: 2 }}>
          <CardActions>
            <Box sx={{ flex: 1 }} />

            <IconButton onClick={() => refetch()} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : <Icon>refresh</Icon>}
            </IconButton>

            {/* TODO */}
            <IconButton disabled>
              <Icon>help</Icon>
            </IconButton>
          </CardActions>

          {(authenticatedUser.isOwner || authenticatedUser.isAdmin) && (
            <CardActions sx={{ justifyContent: 'space-between' }}>
              <IconButton onClick={() => setDate(moment(date).subtract(1, 'day'))}>
                <Icon>chevron_left</Icon>
              </IconButton>

              <Button onClick={(e) => setDateAnchorEl(e.currentTarget)} color="inherit">
                <Icon>today</Icon>
                <Box sx={{ ml: 0.5 }}>{moment(date).format('YYYY-MM-DD')}</Box>
              </Button>

              <Popover
                open={Boolean(dateAnchorEl)}
                anchorEl={dateAnchorEl}
                onClose={() => setDateAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  value={date}
                  onChange={(value) => setDate(value)}
                  renderInput={() => null}
                />
              </Popover>

              <IconButton onClick={() => setDate(moment(date).add(1, 'day'))}>
                <Icon>chevron_right</Icon>
              </IconButton>
            </CardActions>
          )}
        </Card>

        {!data && (
          <Paper
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 400 }}
          >
            <CircularProgress size={60} />
          </Paper>
        )}

        {data && data.sessionsByBusiness && selectedStudio && data.sessionsByBusiness.length === 0 && (
          <Card>
            <CardContent>
              <Typography variant="caption" color="textSecondary">
                No hay clases programadas para este día.
              </Typography>
            </CardContent>
          </Card>
        )}

        {data && data.sessionsByBusiness && selectedStudio && (
          <Grid container spacing={2}>
            {[...data.sessionsByBusiness]
              .filter((session) => session.room.studio.id === selectedStudio.id)
              .sort((a, b) => new Date(a.startsAt) - new Date(b.startsAt))
              .map((session) => {
                const status =
                  session.status === 'upcoming' &&
                  moment(session.startsAt).add(session.duration, 'minutes').toDate() < new Date()
                    ? 'delayed'
                    : session.status;

                return (
                  <Grid key={session.id} item xs={12} md={4} lg={3}>
                    <Card>
                      <Confirm
                        component={CardActionArea}
                        disabled={status === 'canceled'}
                        onConfirm={(actualInstructor) => {
                          if (session.status === 'upcoming') {
                            startSession({
                              variables: {
                                sessionId: session.id,
                                actualInstructorId: actualInstructor
                                  ? actualInstructor.id
                                  : authenticatedUser.id,
                              },
                            });
                          }

                          navigate(`/check-in/${session.id}`);
                        }}
                        alreadyConfirmed={status === 'successful'}
                        title="¿Quieres iniciar esta clase?"
                        description={
                          authenticatedUser.isOwner || authenticatedUser.isAdmin
                            ? 'Selecciona el instructor que imparte la clase:'
                            : null
                        }
                        icon="event_available"
                        verb="iniciar"
                        options={
                          authenticatedUser.isOwner || authenticatedUser.isAdmin
                            ? data.adminsAndInstructors.filter(
                                (instructor) => instructor.isInstructor
                              )
                            : null
                        }
                      >
                        <>
                          {session.status === 'successful' &&
                            session.startedAt &&
                            !session.endedAt && (
                              <LinearProgress
                                variant="indeterminate"
                                sx={{
                                  position: 'absolute',
                                  top: 0,
                                  right: 0,
                                  bottom: 0,
                                  left: 0,
                                  height: 'auto',
                                  opacity: 0.1,
                                }}
                                color="success"
                              />
                            )}
                          <CardHeader
                            title={moment(session.startsAt).format('hh:mm a')}
                            sx={(theme) => ({
                              textAlign: 'center',
                              color: '#fff',
                              ...(status === 'upcoming' && {
                                backgroundColor: theme.palette.primary.main,
                              }),
                              ...(status === 'successful' && {
                                backgroundColor: theme.palette.success.main,
                              }),
                              ...(status === 'canceled' && {
                                backgroundColor: theme.palette.error.main,
                              }),
                              ...(status === 'delayed' && {
                                backgroundColor: '#f9a825',
                              }),
                            })}
                          />
                          <Divider />
                          <CardContent>
                            <CalendarEvent
                              event={{ resource: session }}
                              variant="check-in"
                              showInstructor={
                                authenticatedUser.isOwner ||
                                authenticatedUser.isAdmin ||
                                (session.instructor &&
                                  session.instructor.id === authenticatedUser.id)
                              }
                              textVariant="body2"
                            />
                          </CardContent>
                        </>
                      </Confirm>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        )}
      </>
    </Layout>
  );
}

Sessions.propTypes = {
  authenticatedUser: instanceOf(Object).isRequired,
};
