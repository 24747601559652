import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Icon,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  InputAdornment,
} from '@mui/material';
import { instanceOf, func } from 'prop-types';
import { useSnackbar } from 'notistack';
import { DatePicker } from '@mui/lab';
import moment from 'moment';

import UploadInput from '../Common/UploadInput';
import { translateError } from '../../../utils/translateError';
import { PhoneNumberInput } from '../Common/PhoneNumberInput';

export default function UserDialog({
  authenticatedUser,
  user: userInput,
  handleClose,
  handleUpdate,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState({ ...userInput });

  const [updateUser, { loading: updatingUser, error: updateError }] = useMutation(
    gql`
      mutation UpdateUser($userId: ID!, $input: UserInput!) {
        updateUser(userId: $userId, input: $input)
      }
    `,
    { update: handleUpdate }
  );

  const [createUser, { loading: creatingUser, error: createError }] = useMutation(
    gql`
      mutation InviteUser($input: UserInput!) {
        inviteUser(input: $input)
      }
    `,
    { update: handleUpdate }
  );

  useEffect(() => {
    const error = updateError || createError;
    if (error) {
      enqueueSnackbar(translateError(error.message), { variant: 'error' });
    }
  }, [enqueueSnackbar, updateError, createError]);

  const saving = updatingUser || creatingUser;

  return (
    <Dialog open fullWidth maxWidth="md" onClose={handleClose}>
      <DialogContent>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Correo electrónico"
          value={user.email || ''}
          onChangeCapture={(e) => setUser({ ...user, email: e.target.value })}
          disabled={Boolean(user.id)}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          label="Teléfono"
          value={user.phone || ''}
          onChange={(e) => setUser({ ...user, phone: e.target.value })}
          InputProps={{
            inputComponent: PhoneNumberInput,
            startAdornment: <InputAdornment position="start">+52</InputAdornment>,
          }}
          disabled={Boolean(user.id)}
        />

        {user.id && (
          <>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Nombre"
              value={user.name || ''}
              onChangeCapture={(e) => setUser({ ...user, name: e.target.value })}
            />

            <DatePicker
              label="Fecha de nacimiento"
              value={user.birthday ? moment(user.birthday) : null}
              onChange={(newValue) => {
                setUser({
                  ...user,
                  birthday:
                    newValue && newValue.isValid()
                      ? newValue.startOf('day').toDate().toISOString()
                      : null,
                });
              }}
              renderInput={(params) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <TextField margin="normal" fullWidth {...params} />
              )}
              fullWidth
            />

            <UploadInput
              label="Foto de perfil"
              uniqueName="user-picture"
              value={user.picture || ''}
              onChange={(url) => setUser({ ...user, picture: url })}
            />

            <Typography variant="subtitle2" color="primary" sx={{ mt: 4, mb: 1 }}>
              Dirección
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  label="Calle y número"
                  value={user.address.street || ''}
                  onChangeCapture={(e) =>
                    setUser({
                      ...user,
                      address: { ...user.address, street: e.target.value },
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  label="Colonia"
                  value={user.address.neighborhood || ''}
                  onChangeCapture={(e) =>
                    setUser({
                      ...user,
                      address: { ...user.address, neighborhood: e.target.value },
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  label="Municipio"
                  value={user.address.city || ''}
                  onChangeCapture={(e) =>
                    setUser({
                      ...user,
                      address: { ...user.address, city: e.target.value },
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  label="Estado"
                  value={user.address.state || ''}
                  onChangeCapture={(e) =>
                    setUser({
                      ...user,
                      address: { ...user.address, state: e.target.value },
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  label="Código postal"
                  value={user.address.zipcode || ''}
                  onChangeCapture={(e) =>
                    setUser({
                      ...user,
                      address: { ...user.address, zipcode: e.target.value },
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  label="País"
                  value={user.address.country || ''}
                  onChangeCapture={(e) =>
                    setUser({
                      ...user,
                      address: { ...user.address, country: e.target.value },
                    })
                  }
                />
              </Grid>
            </Grid>
          </>
        )}

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={user.isOwner}
                onChange={() => setUser({ ...user, isOwner: !user.isOwner })}
              />
            }
            label="Es dueño"
            disabled={!authenticatedUser.isOwner}
          />
        </FormGroup>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={user.isAdmin}
                onChange={() => setUser({ ...user, isAdmin: !user.isAdmin })}
              />
            }
            label="Es administrador"
          />
        </FormGroup>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={user.isInstructor}
                onChange={() => setUser({ ...user, isInstructor: !user.isInstructor })}
              />
            }
            label="Es instructor"
          />
        </FormGroup>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" size="small">
          <Icon>cancel</Icon>
          <Box sx={{ ml: 1 }}>Cerrar</Box>
        </Button>
        <Button
          onClick={() => {
            if (user.id) {
              updateUser({
                variables: {
                  userId: user.id,
                  input: {
                    name: user.name,
                    picture: user.picture,
                    address: {
                      street: user.address.street,
                      neighborhood: user.address.neighborhood,
                      city: user.address.city,
                      state: user.address.state,
                      zipcode: user.address.zipcode,
                      country: user.address.country,
                    },
                    isOwner: user.isOwner,
                    isAdmin: user.isAdmin,
                    isInstructor: user.isInstructor,
                    birthday: user.birthday || null,
                  },
                },
              });
            } else {
              createUser({
                variables: {
                  input: {
                    email: user.email,
                    phone: user.phone,
                    isOwner: user.isOwner,
                    isAdmin: user.isAdmin,
                    isInstructor: user.isInstructor,
                  },
                },
              });
            }
          }}
          disabled={Boolean(
            saving ||
              (user.id && (!user.name || (!user.isOwner && !user.isAdmin && !user.isInstructor))) ||
              (!user.id &&
                (!user.email ||
                  !user.phone ||
                  (!user.isOwner && !user.isAdmin && !user.isInstructor)))
          )}
          variant="contained"
          size="small"
        >
          {saving ? <CircularProgress size={24} /> : <Icon>save</Icon>}
          <Box sx={{ ml: 1 }}>Guardar</Box>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UserDialog.propTypes = {
  user: instanceOf(Object),
  handleClose: func.isRequired,
  handleUpdate: func.isRequired,
  authenticatedUser: instanceOf(Object).isRequired,
};

UserDialog.defaultProps = {
  user: null,
};
